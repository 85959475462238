<div class="c-acc-panel c-acc-panel--edit">
  <p>{{'MY-ACCOUNT/MY-PRIVACY.DownloadV2.DownloadMyDataBody1' | translate}}</p>
  <p>{{'MY-ACCOUNT/MY-PRIVACY.DownloadV2.DownloadMyDataBody2' | translate}}</p>
  <p>{{'MY-ACCOUNT/MY-PRIVACY.DownloadV2.DownloadMyDataBody3' | translate}}</p>
  <p>
    <strong>{{email}}</strong>
  </p>
  <p>{{'MY-ACCOUNT/MY-PRIVACY.DownloadV2.DownloadMyDataBody4' | translate}}</p>
  <div class="c-form-buttons  [ o-layout o-layout--auto ]">
    <div class="o-layout__item">
      <button [disabled]="myAccountDataService.dataIsDownloaded | async" class="c-btn c-btn--primary" (click)="save(); myAccountDataService.dataIsDownloaded.next(true)">{{ 'MY-ACCOUNT/MY-PRIVACY.DownloadMyDataLink' | translate}}</button>
    </div>
    <div class="o-layout__item">
      <a class="c-btn c-btn--tertiary" (click)="cancel()">{{ 'MY-ACCOUNT/MY-PRIVACY.Cancel' | translate}}</a>
    </div>
  </div>
</div>
