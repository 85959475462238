import { Component, Inject, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BaseComponent } from 'src/app/components/base/component/base-component';
import { WindowService } from '../../../services/window/window.service';

@Component({
  selector: 'mpp-base-notice',
  template: '',
})
export abstract class BaseNoticeComponent extends BaseComponent {
  constructor(@Inject(DOCUMENT) protected document: Document, protected window: WindowService) {
    super();
  }
  windowScrolled: boolean;

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    if (
      this.window.pageYOffset ||
      this.document.documentElement.scrollTop ||
      document.body.scrollTop > 240
    ) {
      this.windowScrolled = true;
    } else if (
      (this.windowScrolled && this.window.pageYOffset) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 10
    ) {
      this.windowScrolled = false;
    }
  }
  scrollToTop(): void {
    (function smoothscroll(doc: Document, wnd: WindowService): void {
      const currentScroll = doc.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        wnd.requestAnimationFrame(() => smoothscroll(doc, wnd));
        wnd.scrollTo(0, currentScroll - currentScroll / 8);
      }
    })(this.document, this.window);
  }
}
