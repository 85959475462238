import { Component, Input } from '@angular/core';
import { MyAccountDataService } from 'src/app/modules/my-account/services/my-account-data.service';
import { BaseViewComponent } from '../../../base-view-edit-components/base-view/base-view.component';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';

@Component({
  selector: 'mpp-download-my-data-view',
  templateUrl: './download-my-data-view.component.html',
  styleUrls: ['./download-my-data-view.component.scss'],
})
export class DownloadMyDataViewComponent extends BaseViewComponent {
  @Input() email: string;

  constructor(
    public myAccountDataService: MyAccountDataService,
    public analyticsService: AnalyticsService
    ) {
    super();
  }
}
