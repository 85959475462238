export const genderIds = ['female', 'male', 'preferNotToSay'];

export const genderNames = [
  'DATA/GENDERS.Female',
  'DATA/GENDERS.Male',
  'DATA/GENDERS.PreferNotToSay',
];

export type GenderId = typeof genderIds[number];
export type GenderName = typeof genderNames[number];

export const genders: Record<GenderId, GenderName> = {
  female: 'DATA/GENDERS.Female',
  male: 'DATA/GENDERS.Male',
  preferNotToSay: 'DATA/GENDERS.PreferNotToSay',
};
