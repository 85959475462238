import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DeviceNumberHintComponent } from './device-number-hint/device-number-hint.component';
import { SerialNumberHintComponent } from './serial-number-hint/serial-number-hint.component';

@Component({
  selector: 'mpp-add-device',
  templateUrl: './add-device.component.html',
  styleUrls: ['./add-device.component.scss'],
})
export class AddDeviceComponent {
  @Input() deviceFormGroup: UntypedFormGroup;
  @Input() showDeviceNumber = true;
  constructor(private dialog: MatDialog) {}

  public openSerialNumberModal(): void {
    this.dialog.open(SerialNumberHintComponent);
  }

  public openDeviceNumberModal(): void {
    this.dialog.open(DeviceNumberHintComponent);
  }
}
