import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'mpp-device-number-hint',
  templateUrl: './device-number-hint.component.html',
  styleUrls: ['./device-number-hint.component.scss'],
})
export class DeviceNumberHintComponent {
  constructor(private dialogRef: MatDialogRef<DeviceNumberHintComponent>) {}

  close(): void {
    this.dialogRef.close();
  }
}
