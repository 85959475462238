import { Component, Input } from '@angular/core';
import { BaseViewComponent } from '../../../base-view-edit-components/base-view/base-view.component';
import { MyPrivacySettings } from '../my-privacy.component';

@Component({
  selector: 'mpp-receive-additional-information-view',
  templateUrl: './receive-additional-information-view.component.html',
  styleUrls: ['./receive-additional-information-view.component.scss'],
})
export class ReceiveAdditionalInformationViewComponent extends BaseViewComponent {
  @Input() privacySettings: MyPrivacySettings;
}
