import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { CountryService } from 'src/app/services/country/country.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { MetadataService } from 'src/app/services/metadata/metadata.service';

@Component({
  selector: 'mpp-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.scss'],
})
export class GetStartedComponent implements OnInit {

  public isPreTherapyDiagnosticCountry: boolean = false;

  constructor(
    public analyticsService: AnalyticsService,
    public router: Router,
    public countryService: CountryService,
    public languageService: LanguageService,
    private metadataService: MetadataService
  ) {}

  ngOnInit(): void {
    this.metadataService.getMetadataFromApi().subscribe(metadata => {
      this.isPreTherapyDiagnosticCountry = metadata.countries.find(country => country.code === this.metadataService.getSelectedCountryId())?.supportedPatientJourneys?.includes('preTherapyDiagnostic') ?? false;
    });
  }
  goToAccountRegistration(): void {
    this.analyticsService.logClickCreateAccountEvent();
    this.router.navigate(['/device-triage']);
  }

  playStoreLinkClicked(): void {
    this.analyticsService.logPlayStoreRedirect(this.countryService.getSelectedCountryId(), this.analyticsService.redirectLocationVideoCarousel);
  }

  appStoreLinkClicked(): void {
    this.analyticsService.logAppStoreRedirect(this.countryService.getSelectedCountryId(), this.analyticsService.redirectLocationVideoCarousel);
  }
}
