import { Component } from '@angular/core';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'mpp-pta-close-modal',
  templateUrl: './pta-close-modal.component.html',
  styleUrls: ['./pta-close-modal.component.scss']
})
export class PtaCloseModalComponent {
  constructor(private dialogRef: MatLegacyDialogRef<PtaCloseModalComponent>) {}

  closeModal(): void {
    this.dialogRef.close();
  }
}
