import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[mppFocusOnInvalidControl]',
})
/**
 * Directive that focuses on invalid fields after form is submitted
 */
export class FocusOnInvalidControlDirective {
  constructor(private el: ElementRef) {}

  @HostListener('submit')
  onInvalidFormSubmit(): void {
    const invalidControl = this.el.nativeElement.querySelector('.ng-invalid');
    invalidControl?.scrollIntoView();
  }
}
