import { Component, Input } from '@angular/core';
import { genders } from 'src/app/modules/registration/data/gender.type';
import { TimeZoneData } from 'src/app/modules/registration/data/time-zone.type';
import { AboutMeDetails } from '../types/about-me.type';
import { BaseViewComponent } from '../../../base-view-edit-components/base-view/base-view.component';

@Component({
  selector: 'mpp-about-me-view',
  templateUrl: './about-me-view.component.html',
  styleUrls: ['./about-me-view.component.scss'],
})
export class AboutMeViewComponent extends BaseViewComponent {

  @Input() aboutMeDetails: AboutMeDetails;
  @Input() timeZone: TimeZoneData;
  @Input() timeZoneId: string;
  @Input() isJapan: boolean;

  public getGenderTranslation(): string {
    return genders[this.aboutMeDetails.gender];
  }

}
