<form [formGroup]="maskFormGroup">
  <div class="c-form--has-validation  u-7/12@desktop">
    <div class="c-form--has-validation">
      @if (maskFormGroup.errors?.unsupportedMask) {
        <div class="c-alert c-alert--error c-alert--small js-toggle-data-state" role="alert">
          <div class="c-alert__body">{{ 'ERRORS/DEVICE-REGISTRATION.DeviceNotSupported' | translate }}</div>
        </div>
      }
    </div>

    <div id="divTypes" class="c-form-field">
      <label class="c-form-field__label"><span>{{'MY-ACCOUNT/MY-EQUIPMENT/ADD-MASK.TypeOfMask' | translate }}</span></label>

      <div class="c-input--validation" data-form-field="signup-MaskType" [attr.data-form-field-status]="getDataFormFieldStatus('maskType')">

        <div class="o-layout o-layout--auto o-layout--tiny">

          @for (maskType of getMaskTypeIds(); track maskType) {
            <div class="o-layout__item  u-1/3">
              <div class="c-form-img-picker c-form-img-picker--mask-type">
              <a [class]="this.maskFormGroup.get('maskType').value === maskType ?
              'c-form-img-picker__item c-form-img-picker__item--round is-active'
              : 'c-form-img-picker__item c-form-img-picker__item--round'">
                  <input [id]="getMaskTypeTranslation(maskType) | translate" formControlName="maskType" type="image" class="c-form-img-picker__img" [src]="getMaskTypeImageSource(maskType)"
                    [alt]="getMaskTypeTranslation(maskType) | translate"
                    name = "maskType"
                    [value]="maskType" (click)="onMaskTypeSelected(maskType)"/>
                </a>
                <label class="c-form-img-picker__label" [for]="getMaskTypeTranslation(maskType) | translate"><span>{{getMaskTypeTranslation(maskType) | translate }}</span></label>
              </div>
            </div>
          }
        </div>

      </div>

      @if (this.maskFormGroup.get('maskType').invalid
        && this.maskFormGroup.get('maskType').touched) {
        <span
        class="c-form-field__error" role="alert" aria-live="assertive">{{"ERRORS.Required" | translate}}</span>
      }
    </div>
  </div>


  <div class="c-form--has-validation  [ u-11/12@tablet u-7/12@desktop ]">

    <div id="divManufacturers" class="c-form-field" style="display: block;">
      <label class="c-form-field__label"><span>{{'MY-ACCOUNT/MY-EQUIPMENT/ADD-MASK.MadeBy' | translate }}</span></label>

      <div class="c-input--validation" data-form-field="signup-Manufacturers"
        [attr.data-form-field-status]="getDataFormFieldStatus('maskManufacturer')">
        <div class="c-input c-input--select">
          <select formControlName="maskManufacturer" (change)="removeMaskQuestions()">
            <option value="" >{{'MY-ACCOUNT/MY-EQUIPMENT/ADD-MASK.ChooseAManufacturer' | translate }}</option>
            @for (manufacturer of getManufacturerNames(); track manufacturer) {
              <option [value]="manufacturer"> {{ manufacturer }} </option>
            }
          </select>
        </div>
      </div>

      @if (this.maskFormGroup.get('maskManufacturer').invalid
        && this.maskFormGroup.get('maskManufacturer').touched) {
        <span
          class="c-form-field__error" role="alert" aria-live="assertive"
        >{{"ERRORS.Required" | translate}}</span>
      }
    </div>

  </div>


  @if (this.maskFormGroup.get('maskManufacturer').valid && this.maskFormGroup.get('maskType').valid) {
    <div  class="c-form--has-validation">
      <div class="c-form-field" style="display: block;">
        <label class="c-form-field__label"><span>{{'MY-ACCOUNT/MY-EQUIPMENT/ADD-MASK.Model' | translate }}</span></label>
        <div class="c-input--validation" data-form-field="signup-MaskModel" [attr.data-form-field-status]="getDataFormFieldStatus('maskModel')">
          @if (ToggleMaskModelsDisplay()) {
            <ngx-slick-carousel #slickCarousel="slick-carousel" id="maskSlider"
              class="o-carousel o-carousel--thumbs" [config]="slickOptions">
                <div class="o-carousel__item" ngxSlickItem *ngFor="let maskInfo of maskModelsList">
                  <div class="c-form-img-picker c-form-img-picker--masks">
                    <button (click)="onMaskModelSelected(maskInfo.maskCode)"
                      [class]="this.maskFormGroup.get('maskModel').value === maskInfo.maskCode ?
                              'c-form-img-picker__item is-active'
                              : 'c-form-img-picker__item'">
                      <img [src]="maskInfo.imagePath"
                        [alt]="maskInfo.name" class="c-form-img-picker__img">
                      <div class="u-margin-top-tiny"><span class="c-input--dummy-radio">&nbsp;</span><span
                    class="c-form-img-picker__label">{{maskInfo.name}}</span></div>
                  </button>
                </div>
              </div>
          </ngx-slick-carousel>
        }
        @if (!ToggleMaskModelsDisplay()) {
          <div class="o-layout o-layout--auto@tablet o-layout--tiny [ u-11/12@tablet u-7/12@desktop ]">
            @for (maskInfo of maskModelsList; track maskInfo) {
              <div class="o-layout__item  u-1/3@tablet">
                <div class="c-input c-input--radio align-top">
                  <input type="radio" (click)="onMaskModelSelected(maskInfo.maskCode)" formControlName="maskModel" [id]="maskInfo.maskCode" [value]="maskInfo.maskCode" /><label
                [for]="maskInfo.maskCode">{{ maskInfo.name }}</label>
              </div>
            </div>
          }
        </div>
      }
      @if (this.maskFormGroup.get('maskModel').invalid && this.maskFormGroup.get('maskModel').touched) {
        <span
        class="c-form-field__error" role="alert" aria-live="assertive">{{"ERRORS.Required" | translate}}</span>
      }
    </div>
  </div>
</div>
}
@if (maskService.maskQuestions) {
  <div class="c-form [ u-11/12@tablet u-7/12@desktop ]">
    @for (maskQuestion of maskService.maskQuestions; track maskQuestion; let i = $index) {
      <label class="c-form-field__label"><span>{{maskQuestion.localizedQuestion}}</span></label>
      <div class="c-input c-input--select">
        <select [formControlName]="'maskQuestion' + i">
          <option value="">{{"MY-ACCOUNT/MY-HEALTH-PROFILE.SelectAnOption" | translate }}</option>
          @for (answer of maskQuestion.answers; track answer) {
            <option [value]="answer.answerId"> {{answer.localizedAnswer}} </option>
          }
        </select>
      </div>
    }
  </div>
}
@if (maskService.hasPillowOptimization) {
  <div class="[ u-11/12@tablet u-7/12@desktop ]">
    <h4>{{ "MY-ACCOUNT/MY-EQUIPMENT/ADD-MASK.MaskSetting" | translate }}</h4>
    <div class="mask-setting-text">{{ "MY-ACCOUNT/MY-EQUIPMENT/ADD-MASK.MaskSettingInitialText" | translate }}</div>
    <div class="mask-setting-text" [innerHTML]="'MY-ACCOUNT/MY-EQUIPMENT/ADD-MASK.MaskSettingTextWithBold' | translate"></div>
    <span class="video mask-setting-text" (click)="openMaskSettingMp4()">{{ "MY-ACCOUNT/MY-EQUIPMENT/ADD-MASK.ViewVideo" | translate }}</span>
  </div>
}
</form>
