import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseResult } from 'src/app/services/showtime-api/response.structure';
import { Observable } from 'rxjs';
import { ShowTimeApiService } from 'src/app/services/showtime-api/showtime-api.service';
import { AlertService } from 'src/app/modules/alert/components/services/alert.service';
import { MetadataService } from '../metadata/metadata.service';

@Injectable({
  providedIn: 'root',
})
export class CredentialsService extends ShowTimeApiService {
  constructor(protected http: HttpClient, protected messageService: AlertService, private metadata: MetadataService) {
    super(messageService);
  }

  forgotPassword(email: string): Observable<ResponseResult> {
    return this.http
      .post<ResponseResult>(`${this.metadata.instanceInfo.restUrl}/V1/Patient/ForgotPassword`, {
        email,
      })
      .pipe(this.showError());
  }

  deleteAccount(password: string, feedback: string): Observable<ResponseResult> {
    return this.http
      .post<ResponseResult>(`${this.metadata.instanceInfo.restUrl}/V2/Patient/DeleteAccount`, {
        password,
        feedback,
      })
      .pipe(this.showError());
  }
}
