import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FieldStatus } from 'src/app/modules/registration/data/field-status.enum';
import { BaseComponent } from './base-component';

@Component({
  selector: 'mpp-base-form-component',
  template: '',
})
export abstract class BaseFormComponent extends BaseComponent {
  public getFieldStatus(dataFieldName: string, form: UntypedFormGroup): string {
    const formValidStatus: string =
      form.get(dataFieldName).touched && form.get(dataFieldName).valid
        ? FieldStatus.completed
        : FieldStatus.incompleted;
    return form.get(dataFieldName).invalid && form.get(dataFieldName).touched
      ? FieldStatus.error
      : formValidStatus;
  }
}
