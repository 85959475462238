export const monthIds = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
] as const;
export const monthNames = [
  'DATA/MONTHS.January',
  'DATA/MONTHS.February',
  'DATA/MONTHS.March',
  'DATA/MONTHS.April',
  'DATA/MONTHS.May',
  'DATA/MONTHS.June',
  'DATA/MONTHS.July',
  'DATA/MONTHS.August',
  'DATA/MONTHS.September',
  'DATA/MONTHS.October',
  'DATA/MONTHS.November',
  'DATA/MONTHS.December',
] as const;
export type MonthId = typeof monthIds[number];
export type MonthName = typeof monthNames[number];

export const months: Record<MonthId, MonthName> = {
  Jan: 'DATA/MONTHS.January',
  Feb: 'DATA/MONTHS.February',
  Mar: 'DATA/MONTHS.March',
  Apr: 'DATA/MONTHS.April',
  May: 'DATA/MONTHS.May',
  Jun: 'DATA/MONTHS.June',
  Jul: 'DATA/MONTHS.July',
  Aug: 'DATA/MONTHS.August',
  Sep: 'DATA/MONTHS.September',
  Oct: 'DATA/MONTHS.October',
  Nov: 'DATA/MONTHS.November',
  Dec: 'DATA/MONTHS.December',
} as const;
