import { Component, Input } from '@angular/core';
import {
  SleepTestId,
  sleepTestTranslated,
} from 'src/app/modules/registration/data/sleep-test.type';
import {
  StartTherapyGroupId,
  startTherapyGroups,
} from 'src/app/modules/registration/data/start-therapy-group.type';
import { HealthProfile } from './types/health-profile.type';

@Component({
  selector: 'mpp-my-health-profile',
  templateUrl: './my-health-profile.component.html',
  styleUrls: ['./my-health-profile.component.scss'],
})
export class MyHealthProfileComponent {
  @Input() healthProfile: HealthProfile;

  public getSleepTestTypeName(sleepTestId: SleepTestId): string {
    return sleepTestTranslated[sleepTestId];
  }

  public getStartTherapyGroupName(startTherapyGroupId: StartTherapyGroupId): string {
    return startTherapyGroups[startTherapyGroupId];
  }
}
