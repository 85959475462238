import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '@auth/auth.service';
import { from } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertService } from 'src/app/modules/alert/components/services/alert.service';
import { AccountUpdateService } from 'src/app/modules/my-account/services/account-update.service';
import { RegistrationValidators } from 'src/app/modules/registration/validators/registration.validators';
import { ErrorCodes } from 'src/app/services/showtime-api/response.structure';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { BaseEditComponent } from '../../../base-view-edit-components/base-edit/base-edit.component';
import { ComponentState } from '../../../base-view-edit-components/component-state.enum';

@Component({
  selector: 'mpp-password-edit',
  templateUrl: './password-edit.component.html',
  styleUrls: ['./password-edit.component.scss'],
})
export class PasswordEditComponent extends BaseEditComponent implements OnInit {
  @Input() email: string;
  @Output() passwordChanged = new EventEmitter<string>();
  form: UntypedFormGroup;
  showPassword = false;
  showNextStepMessage = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private spinnerService: SpinnerService,
    private accountUpdateService: AccountUpdateService,
    private authService: AuthService,
    private alertService: AlertService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      oldPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required, RegistrationValidators.passwordsConditions]],
    });
  }

  save(): void {
    const spinner = this.spinnerService.show();
    this.accountUpdateService
      .changePassword(this.form.value.oldPassword, this.form.value.newPassword)
      .subscribe(
        async (response) => {
          if (!response.result.success) {
            spinner.hide();
            if (response.result.errorCode === ErrorCodes.doesNotMeetPasswordRequirements) {
              this.form.get('newPassword').setErrors({ doesNotMeetPasswordRequirements: true });
            }
            if (response.result.errorCode === ErrorCodes.invalidAccountPassword) {
              this.form.get('oldPassword').setErrors({ invalidAccountPassword: true });
            }
            return;
          }
          from(this.authService.reLoginAfterPasswordChange(this.email, this.form.value.newPassword))
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              () => {
                this.showNextStepMessage = true;
                spinner.hide();
              },
              () => {
                spinner.hide();
                this.alertService.info();
                this.authService.logout();
              },
            );
        },
        () => {
          spinner.hide();
          this.alertService.info('ERRORS.FailedUnexpectedly.PleaseTryAgain');
        },
      );
  }

  nextStepAccepted(): void {
    this.passwordChanged.emit();
    this.stateChanged.next(ComponentState.View);
  }
}
