<div class="c-acc-panel c-acc-panel--header">
  <div class="c-acc-panel__item">
    <div class="c-acc-panel__heading">
      <h2 id="mynotifications" class="c-heading-acc">{{ 'MY-ACCOUNT/MY-NOTIFICATIONS.MyNotifications' | translate}}</h2>
    </div>
  </div>
</div>

<div class="c-acc-panel c-acc-panel--edit">
  <div class="c-form-field">
    <div class="c-form-field__error" role="alert" aria-live="assertive">
      <span>{{"ERRORS.FailedUnexpectedly.PleaseTryAgain" | translate}}
        <a [routerLink]="[ '/support']">{{ "ERRORS.FailedUnexpectedly.Support" | translate}}</a>
        {{"ERRORS.FailedUnexpectedly.ForHelp" | translate}}
      </span>
    </div>
  </div>

  <div class="c-form-buttons  [ o-layout o-layout--auto ]">
    <div class="o-layout__item">
      <a class="c-btn c-btn--primary" (click)="cancel()">{{ 'MY-ACCOUNT/MY-NOTIFICATIONS.TryAgain' | translate}}</a>
    </div>
    <div class="o-layout__item">
      <a class="c-btn c-btn--tertiary" (click)="cancel()">{{ 'MY-ACCOUNT/MY-NOTIFICATIONS.Cancel' | translate}}</a>
    </div>
  </div>
</div>
