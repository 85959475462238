import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { LanguageService } from 'src/app/services/language/language.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { PolicyHttpService } from '../services/policy-http.service';
import { WindowService } from '../../../services/window/window.service';
import { BaseNoticeComponent } from '../common/base-notice';
import DOMPurify from 'dompurify';

@Component({
  selector: 'mpp-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss'],
})
export class TermsOfUseComponent extends BaseNoticeComponent implements OnInit {
  sanitizedHtml: any;
  data: SafeHtml;
  
  constructor(
    @Inject(DOCUMENT) protected document: Document,
    protected window: WindowService,
    private service: PolicyHttpService,
    private sanitizer: DomSanitizer,
    private location: Location,
    private languageService: LanguageService,
    private spinnerService: SpinnerService,
  ) {
    super(document, window);
  }
  ngOnInit(): void {
    this.languageService.selectedLanguageId$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.loadTermsOfUse();
    });
  }

  loadTermsOfUse(): void {
    const spinner = this.spinnerService.show();
    this.service.getTermsOfUseHtml().subscribe((result) => {
      const path = this.location.path();
      // We need this to prepend angular route to existing anchor links
      const replacedResult = result.replace(/href="#/gim, `href="${path}#`);
      this.sanitizedHtml = this.getSafeContent(replacedResult);
      this.data = this.sanitizer.bypassSecurityTrustHtml(this.sanitizedHtml);
      spinner.hide();
    });
  }

  getSafeContent(content: string): string {
    return DOMPurify.sanitize(content, {USE_PROFILES: {html: true}});
  }
}
