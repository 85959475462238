<form [formGroup]="deviceFormGroup">
  <div class="c-form--has-validation  [ u-11/12@tablet u-7/12@desktop ]">

    <div>
      <div class="c-form--has-validation ">
        @if (deviceFormGroup.errors?.invalidSerialNumber) {
          <div class="c-alert c-alert--error c-alert--small js-toggle-data-state" role="alert">
            <div class="c-alert__body">{{ 'ERRORS/DEVICE-REGISTRATION.InvalidSerialNumber' | translate }}</div>
          </div>
        }

        @if (deviceFormGroup.errors?.invalidSerialNumberOrDeviceNumber) {
          <div class="c-alert c-alert--error c-alert--small js-toggle-data-state" role="alert">
            <div class="c-alert__body">{{ 'ERRORS/DEVICE-REGISTRATION.InvalidSerialNumberOrDeviceNumber' | translate }}</div>
          </div>
        }

        @if (deviceFormGroup.errors?.serialNumberInUse || deviceFormGroup.errors?.deviceInUse) {
          <div class="c-alert c-alert--error c-alert--small js-toggle-data-state" role="alert">
            <div class="c-alert__body">{{ 'ERRORS/DEVICE-REGISTRATION.deviceInUse' | translate }}</div>
          </div>
        }

        @if (deviceFormGroup.errors?.invalidDeviceNumber) {
          <div class="c-alert c-alert--error c-alert--small js-toggle-data-state" role="alert">
            <div class="c-alert__body">{{ 'ERRORS/DEVICE-REGISTRATION.InvalidDeviceNumber' | translate }}</div>
          </div>
        }

        @if (deviceFormGroup.errors?.deviceNotSupported) {
          <div class="c-alert c-alert--error c-alert--small js-toggle-data-state" role="alert">
            <div class="c-alert__body">{{ 'ERRORS/DEVICE-REGISTRATION.DeviceNotSupported' | translate }}</div>
          </div>
        }

        @if (deviceFormGroup.errors?.patientDoesNotExistInAirView) {
          <div class="c-alert c-alert--error c-alert--small js-toggle-data-state" role="alert">
            <div class="c-alert__body">{{ 'ERRORS/DEVICE-REGISTRATION.PatientDoesNotExistInAirView' | translate }}</div>
          </div>
        }


        @if (deviceFormGroup.errors?.patientHMEDisallowed) {
          <div class="c-alert c-alert--error c-alert--small js-toggle-data-state" role="alert">
            <div class="c-alert__body">{{ 'ERRORS/DEVICE-REGISTRATION.PatientHMEDisallowed' | translate }}</div>
          </div>
        }

        @if (deviceFormGroup.errors?.camNotSupported) {
          <div class="c-alert c-alert--error c-alert--small js-toggle-data-state" role="alert">
            <div class="c-alert__body">{{ 'ERRORS/DEVICE-REGISTRATION.CamNotSupported' | translate }}</div>
          </div>
        }

        @if (deviceFormGroup.errors?.deviceNotInThisRegion) {
          <div class="c-alert c-alert--error c-alert--small js-toggle-data-state" role="alert">
            <div class="c-alert__body">{{ 'ERRORS/DEVICE-REGISTRATION.deviceNotInThisRegion' | translate }}</div>
          </div>
        }

        @if (deviceFormGroup.errors?.tooManyRequests) {
          <div class="c-alert c-alert--error c-alert--small js-toggle-data-state" role="alert">
            <div class="c-alert__body">{{ 'REGISTRATION-FORM.onboarding_device_validation_maximum_attempts' | translate }}</div>
          </div>
        }
      </div>

      <div class="c-form-field">
        <label class="c-form-field__label" for="device-serial-number"><span>{{ 'MY-ACCOUNT/MY-EQUIPMENT.SerialNumber' | translate }}</span></label>

        <div class="o-media o-media--tiny  c-input--validation" data-form-field="signup-SerialNumber"
        [attr.data-form-field-status]="deviceFormGroup.get('deviceSerialNumber').valid
        && deviceFormGroup.get('deviceSerialNumber').touched ? 'completed' : 'incompleted'">
          <div class="o-media__img">
            <img src="/assets/images/device/SerialNumber.png" alt="SN">
          </div>
          <div class="o-media__body">
            <input id="device-serial-number" formControlName="deviceSerialNumber" mppOnlyNumbers='integer' type="text" placeholder="###########" maxlength="11"
              class="c-input c-input--text">

            @if (deviceFormGroup.get('deviceSerialNumber').invalid
              && (deviceFormGroup.get('deviceSerialNumber').dirty || deviceFormGroup.get('deviceSerialNumber').touched)) {
              <div>
                @if (deviceFormGroup.get('deviceSerialNumber').errors?.minlength && !deviceFormGroup.get('deviceSerialNumber').errors?.required) {
                  <span
                  class="c-form-field__error" role="alert" aria-live="assertive">{{ 'ERRORS.InvalidSerialNumber' | translate}}</span>
                }
                @if (deviceFormGroup.get('deviceSerialNumber').errors?.required) {
                  <span
                  class="c-form-field__error" role="alert" aria-live="assertive">{{ 'ERRORS.Required' | translate}}</span>
                }
              </div>
            }

            <div class="c-form-field__hint"><a (click)="openSerialNumberModal()" data-reveal-id="mySerialNumberDeviceReveal"
              tabindex="-1"><span>{{ 'MY-ACCOUNT/MY-EQUIPMENT.ElevenDigitsLong' | translate }}</span></a></div>
            </div>
          </div>
        </div>

        @if (showDeviceNumber) {
          <div class="c-form-field">
            <label class="c-form-field__label" for="device-number"><span>{{ 'MY-ACCOUNT/MY-EQUIPMENT.DeviceNumber' | translate }}</span></label>
            <div class="o-media o-media--tiny  c-input--validation" data-form-field="signup-DeviceNumber"
          [attr.data-form-field-status]="deviceFormGroup.get('deviceNumber').valid && (deviceFormGroup.get('deviceNumber').dirty
                                        || deviceFormGroup.get('deviceNumber').touched) ? 'completed' : 'incompleted'">
              <div class="o-media__img">
                <img src="/assets/images/device/DeviceNumber.png" alt="DN">
              </div>
              <div class="o-media__body">
                <input id="device-number" formControlName="deviceNumber" type="text" mppOnlyNumbers='integer' placeholder="###"
                  maxlength="3"
                  class="c-input c-input--text">
                @if (deviceFormGroup.get('deviceNumber').invalid
                  && (deviceFormGroup.get('deviceNumber').dirty || deviceFormGroup.get('deviceNumber').touched)) {
                  <div>
                    @if (deviceFormGroup.get('deviceNumber').errors?.minlength && !deviceFormGroup.get('deviceNumber').errors?.required) {
                      <span
                      class="c-form-field__error" role="alert" aria-live="assertive">{{ 'ERRORS.InvalidDeviceNumber' | translate }}</span>
                    }
                    @if (deviceFormGroup.get('deviceNumber').errors?.required) {
                      <span
                      class="c-form-field__error" role="alert" aria-live="assertive">{{ 'ERRORS.Required' | translate }}</span>
                    }
                  </div>
                }
                <div class="c-form-field__hint"><a (click)="openDeviceNumberModal()" data-reveal-id="myDeviceNumberDeviceReveal"
                  tabindex="-1"><span>{{ 'MY-ACCOUNT/MY-EQUIPMENT.ThreeDigitsLong' | translate }}</span></a></div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </form>
