import { Component, Input } from '@angular/core';
import { BaseViewComponent } from 'src/app/modules/my-account/components/base-view-edit-components/base-view/base-view.component';

@Component({
  selector: 'mpp-email-view',
  templateUrl: './email-view.component.html',
  styleUrls: ['./email-view.component.scss'],
})
export class EmailViewComponent extends BaseViewComponent {
  @Input() email: string;
}
