import { NgModule } from '@angular/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
  declarations: [],
  exports: [MatDialogModule, BrowserAnimationsModule, LayoutModule, MatRadioModule],
})
export class MaterialModule {}
