import { Component, Input } from '@angular/core';
import { BaseViewComponent } from '../../../base-view-edit-components/base-view/base-view.component';
import { NotificationPreferences } from '../types/notification-preferences.type';

@Component({
  selector: 'mpp-notifications-view',
  templateUrl: './notifications-view.component.html',
  styleUrls: ['./notifications-view.component.scss'],
})
export class NotificationsViewComponent extends BaseViewComponent {
  @Input() notificationPreferences: NotificationPreferences;
}
