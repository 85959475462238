import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageId } from 'src/app/types/language.type';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CountryService } from 'src/app/services/country/country.service';
import { CountryData } from 'src/app/types/country.type';
import { LanguageService } from '../../../services/language/language.service';

@Component({
  selector: 'mpp-language-menu',
  templateUrl: './language-menu.component.html',
  styleUrls: ['./language-menu.component.scss'],
})
export class LanguageMenuComponent implements OnInit, OnDestroy {
  selectedLanguageId: LanguageId;
  selectedCountry: CountryData;
  isLangMenuOpen = false;
  private unsubscribe$ = new Subject<void>();
  constructor(
    public translate: TranslateService,
    private languageService: LanguageService,
    private countryService: CountryService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.languageService.selectedLanguageId$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((languageId) => {
        this.selectedLanguageId = languageId;
      });

    this.countryService.selectedCountryId$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.selectedCountry = this.countryService.getSelectedCountry();
    });
  }

  getLanguageIds(): LanguageId[] {
    // if no country is selected we should navigate to country-selection page
    // and provide no languages as country is not selected
    if (!this.selectedCountry) {
      this.selectCountry();
      return [];
    }
    return [...this.selectedCountry.languages].filter((li) => li !== this.selectedLanguageId);
  }

  selectLanguage(languageId: LanguageId): void {
    this.languageService.selectLanguage(languageId);
    this.isLangMenuOpen = !this.isLangMenuOpen;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectCountry(): void {
    this.router.navigate(['country-selection']);
  }

  toggleMenu(): void {
    this.isLangMenuOpen = !this.isLangMenuOpen;
  }
}
