import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { PatientService } from 'src/app/services/patient/patient.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { BaseEditComponent } from '../../../base-view-edit-components/base-edit/base-edit.component';
import { ComponentState } from '../../../base-view-edit-components/component-state.enum';
import { MyPrivacySettings } from '../my-privacy.component';
import { PinpointService } from 'src/app/services/pinpoint/pinpoint.service';

@Component({
  selector: 'mpp-allow-personalized-coaching-edit',
  templateUrl: './allow-personalized-coaching-edit.component.html',
  styleUrls: ['./allow-personalized-coaching-edit.component.scss']
})
export class AllowPersonalizedCoachingEditComponent extends BaseEditComponent implements OnInit  {
  @Input() privacySettings: MyPrivacySettings;
  @Output() smartCoachingChanged = new EventEmitter<boolean>();

  form = this.fb.group({
    allowPersonalizedCoaching: [false],
  });

  constructor(
    private patientService: PatientService,
    private fb: UntypedFormBuilder,
    private spinner: SpinnerService,
    private analyticsService: AnalyticsService,
    private pinpointService: PinpointService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.form.controls.allowPersonalizedCoaching.setValue(this.privacySettings.allowPersonalizedCoaching);
  }

  save(): void {
    const spinner = this.spinner.show();
    const smartCoachingEnabled = this.form.get('allowPersonalizedCoaching').value;

    this.patientService.setPrivacySettings({ smartCoachingEnabled }).pipe(
      take(1), 
      takeUntil(this.unsubscribe$),
      this.pinpointService.showError({ disableAlert: true }),
      finalize(() => spinner.hide())
      )
      .subscribe( async _ => {
        try {
          this.analyticsService.logSmartCoachingEnabled(smartCoachingEnabled ? this.analyticsService.smartCoachingOn : this.analyticsService.smartCoachingOff);
          // Trigger session start or stop based on smartCoachingEnabled
          if (smartCoachingEnabled && this.pinpointService.pinpointFlag) {
            // console.log("attempting to call putEvents.sessionStart");
            // console.log("smartCoaching is now Enabled", smartCoachingEnabled);
            // console.log("pinpoint service's user_id:", this.pinpointService.user_id);
            this.pinpointService.putEvents('_session.start', new Date().toISOString()).catch(error => {
              // console.error("putEvents error:", error);
              this.pinpointService.showErrorAsync(error, { disableAlert: true });
            })
          } else if (this.pinpointService.pinpointFlag) {
            // console.log("attempting to call putEvents.sessionStop");
            // console.log("smartCoaching is now Disabled");
            this.pinpointService.putEvents('_session.stop', new Date().toISOString()).catch(error => {
              // console.error(error);
              this.pinpointService.showErrorAsync(error, { disableAlert: true });
            })
          }
          this.stateChanged.next(ComponentState.View);
          this.smartCoachingChanged.next(smartCoachingEnabled);
        } catch (error) {
          // console.error("Error updating analytics privacy settings", error);
          this.pinpointService.showErrorAsync(error, { disableAlert: true });
        } finally {
            spinner.hide();
        }
      });
  }
}
