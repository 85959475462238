/* eslint-disable @typescript-eslint/object-curly-spacing */
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import {
  SleepTestId,
  sleepTestTranslated,
} from 'src/app/modules/registration/data/sleep-test.type';
import { PatientService } from 'src/app/services/patient/patient.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { Router } from '@angular/router';
import { RegistrationValidators } from 'src/app/modules/registration/validators/registration.validators';
import { MyAccountDataService } from 'src/app/modules/my-account/services/my-account-data.service';
import { BaseComponent } from 'src/app/components/base/component/base-component';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'mpp-health-profile-edit',
  templateUrl: './health-profile-edit.component.html',
  styleUrls: ['./health-profile-edit.component.scss'],
})
export class HealthProfileEditComponent extends BaseComponent implements OnInit {
  public healthProfileForm: UntypedFormGroup;
  // the value of unknown test type
  private notKnownSleepTestTypeKey = (Object.keys(sleepTestTranslated) as Array<SleepTestId>).find(
    (key) => sleepTestTranslated[key] === '',
  );
  constructor(
    private formBuilder: UntypedFormBuilder,
    private patientService: PatientService,
    private spinnerService: SpinnerService,
    private router: Router,
    private myAccountDataService: MyAccountDataService,
  ) {
    super();
  }
  ngOnInit(): void {
    this.healthProfileForm = this.formBuilder.group({
      AHIevents: [
        null,
        [
          // eslint-disable-next-line
          Validators.pattern(/^((0|([1-9](\d)?)|1\d{2})(\.\d)?|2[0]{2}(.0)?)$/),
          Validators.maxLength(5),
          Validators.max(200),
          Validators.min(0),
          RegistrationValidators.restrictFirstNumberAsZero,
        ],
      ],
      startTherapyGroup: [null],
      // if no sleep test type was selected the test type is unknown
      sleepTestType: [this.notKnownSleepTestTypeKey],
      // implementation postponed due to MWEB-714
      // baselineSleepiness: [null],
    });
    this.myAccountDataService.healthProfileData
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (!data) return;
        this.healthProfileForm.setValue({
          AHIevents: data.userEnteredAhi,
          startTherapyGroup: data.startTherapyGroup,
          sleepTestType: data.sleepTestType,
        });
      });
  }

  async updateHealthProfile(): Promise<void> {
    const spinner = this.spinnerService.show();
    const healthProfile = {
      startTherapyGroup: this.healthProfileForm.get('startTherapyGroup').value,
      sleepTestType: this.healthProfileForm.get('sleepTestType').value,
      userEnteredAhi: this.healthProfileForm.get('AHIevents').value || null,
      // implementation postponed due to MWEB-714
      // baselineSleepiness: this.healthProfileForm.get('baselineSleepiness').value || null,
    };
    const [error] = await this.patientService.updateHealthProfile(healthProfile).toPromise();
    spinner.hide();
    if (!error) {
      this.router.navigate(['my-account']);
    }
  }
}
