<div class="u-margin-bottom-large">
  <div>
    <div>
      <div class="js-acc-panel is-active" style="display: block; visibility: visible;">
        @if (state === componentState.View) {
          <mpp-notifications-view
          [notificationPreferences]="notificationPreferences" (stateChanged)="state = $event"></mpp-notifications-view>
        }
        @if (state === componentState.Edit) {
          <mpp-notifications-edit
            [notificationPreferences]="notificationPreferences" (stateChanged)="state = $event"
          (notificationsChanged)="notificationsChanged($event)"></mpp-notifications-edit>
        }
        @if (state === componentState.Error) {
          <mpp-notifications-error (stateChanged)="state = $event"></mpp-notifications-error>
        }
      </div>
    </div>
  </div>
</div>
