import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable, map} from 'rxjs';
import { MetadataService } from '../metadata/metadata.service';

@Injectable({
 providedIn: 'root'
})
export class LocalMetadataGuard {
  constructor(private metadataService: MetadataService) { }

  canActivateChild(
    route: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(route, state);
  }
  canLoad(
    route: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(route, state);
  }
  canActivate(
    route: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // Perform the check to ensure API metadata is loaded
    return this.metadataService.checkIfMetadataLocalLoadNecessary().pipe(
      map((data) => true)
    );
  }

}