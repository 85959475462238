import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { MutationOptions, QueryOptions } from '@apollo/client/core';
import { EmptyObject } from 'apollo-angular/types';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { GraphQLError, GraphQLPayload } from './graphql-payload.type';

@Injectable({
  providedIn: 'root',
})
export class ApolloService {
  constructor(private apollo: Apollo) {}

  query<T>(options: QueryOptions<EmptyObject>): Observable<[T, GraphQLError]> {
    return this.apollo.query<T>(options).pipe(
      map((result) => {
        if (result.errors) {
          this.apollo.client.cache.reset();
          const [error] = ((result as unknown) as GraphQLPayload).errors;
          return [undefined, error];
        }
        return [result.data as T, undefined];
      }),
    );
  }

  mutate<T>(options: MutationOptions<unknown, EmptyObject>): Observable<[GraphQLError, T]> {
    return this.apollo.mutate(options).pipe(
      map((result) => {
        if (result.errors) {
          this.apollo.client.cache.reset();
          const [error] = ((result as unknown) as GraphQLPayload).errors;
          return [error, undefined];
        }
        return [undefined, result.data as T];
      }),
    );
  }
}
