/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type UpdatePatientInput = {
  firstName?: string | null,
  lastName?: string | null,
  dateOfBirth?: string | null,
  gender?: Gender | null,
  sleepTestType?: SleepTestType | null,
  startTherapyGroup?: StartTherapyGroup | null,
  userEnteredAhi?: number | null,
  phoneNumber?: string | null,
  pushToken?: string | null,
  achievementsPushEnabled?: boolean | null,
  coachingPushEnabled?: boolean | null,
  coachingSmsEnabled?: boolean | null,
  coachingEmailEnabled?: boolean | null,
  cleaningPushEnabled?: boolean | null,
  cleaningSmsEnabled?: boolean | null,
  cleaningEmailEnabled?: boolean | null,
  smartCoachingEnabled?: boolean | null,
  stepCountSharingEnabled?: boolean | null,
  healthSharingEnabled?: boolean | null,
  healthSharingLastPromptedVersion?: string | null,
  shareDetailsWithProviderOptIn?: boolean | null,
  analyticsMode?: AnalyticsMode | null,
  timezoneId?: string | null,
  furiganaFamilyName?: string | null,
  furiganaGivenName?: string | null,
  marketingOptIn?: boolean | null,
  processHealthDataOptIn?: boolean | null,
};

export enum Gender {
  preferNotToSay = "preferNotToSay",
  female = "female",
  male = "male",
}


export enum SleepTestType {
  notKnown = "notKnown",
  home = "home",
  lab = "lab",
  both = "both",
}


export enum StartTherapyGroup {
  justStarting = "justStarting",
  threeToTwelveMonthsAgo = "threeToTwelveMonthsAgo",
  moreThanOneYearAgo = "moreThanOneYearAgo",
  moreThanFiveYearsAgo = "moreThanFiveYearsAgo",
}


export enum AnalyticsMode {
  identified = "identified",
  anonymous = "anonymous",
  untracked = "untracked",
}


export type Patient = {
  __typename: "Patient",
  id: string,
  email: string,
  countryId: string,
  firstName: string,
  lastName: string,
  dateOfBirth: string,
  gender: Gender,
  sleepTestType?: SleepTestType | null,
  startTherapyGroup?: StartTherapyGroup | null,
  userEnteredAhi?: number | null,
  phoneNumber?: string | null,
  aboutMeProgress?: number | null,
  myEquipmentProgress?: number | null,
  myHealthProgress?: number | null,
  overallProgress?: number | null,
  achievementsPushEnabled?: boolean | null,
  coachingPushEnabled?: boolean | null,
  coachingSmsEnabled?: boolean | null,
  coachingEmailEnabled?: boolean | null,
  cleaningPushEnabled?: boolean | null,
  cleaningSmsEnabled?: boolean | null,
  cleaningEmailEnabled?: boolean | null,
  smartCoachingEnabled?: boolean | null,
  stepCountSharingEnabled?: boolean | null,
  healthSharingEnabled?: boolean | null,
  healthSharingLastPromptedVersion?: string | null,
  shareDetailsWithProviderOptIn?: boolean | null,
  analyticsMode?: AnalyticsMode | null,
  analyticsId?: string | null,
  pushToken?: string | null,
  timezoneId?: string | null,
  furiganaFamilyName?: string | null,
  furiganaGivenName?: string | null,
  marketingOptIn?: boolean | null,
  achievements:  Array<Achievement >,
  journeys: Array< PatientJourney >,
  firstTherapySessionDate?: string | null,
  lifetimeUsageHours?: number | null,
  processHealthDataOptIn?: boolean | null,
};

export type Achievement = {
  __typename: "Achievement",
  id: string,
  name: string,
  description?: string | null,
  imageUrl?: string | null,
  earnedDate?: string | null,
};

export enum PatientJourney {
  preTherapyDiagnostic = "preTherapyDiagnostic",
  therapyPAP = "therapyPAP",
}


export type AddFgDeviceInput = {
  serialNumber: string,
  deviceNumber: string,
  deviceType: FgDeviceType,
};

export enum FgDeviceType {
  AS10 = "AS10",
  AS11 = "AS11",
}


export type FgDevice = {
  __typename: "FgDevice",
  serialNumber: string,
  deviceType: FgDeviceType,
  deviceSeries?: FgSeries | null,
  deviceFamily?: FgFamily | null,
  lastSleepDataReportTime?: string | null,
  localizedName: string,
  imagePath: string,
  imageUrl: string,
  fgDeviceManufacturerName: string,
  fgDevicePatientId: string,
  deviceSubtype: FgDeviceSubtype,
};

export enum FgSeries {
  air10 = "air10",
  air11 = "air11",
}


export enum FgFamily {
  airCurve = "airCurve",
  airSense = "airSense",
}


export enum FgDeviceSubtype {
  PAP = "PAP",
  RC = "RC",
}


export type AddFlowGeneratorInput = {
  serialNumber: string,
  deviceNumber?: string | null,
  isBtConnected?: boolean | null,
  deviceType: FgDeviceType,
};

export type AddFlowGeneratorInputV2 = {
  serialNumber: string,
  deviceNumber?: string | null,
  isBtConnected?: boolean | null,
};

export type AddMaskInput = {
  maskManufacturerName: string,
  maskCode: string,
};

export type Mask = {
  __typename: "Mask",
  maskManufacturerName: string,
  maskCode: string,
  maskType: string,
  localizedName: string,
  imagePath: string,
  imageUrl: string,
  maskPatientId: string,
};

export type RemoveFgDeviceInput = {
  serialNumber: string,
};

export type RemoveMaskInput = {
  maskManufacturerName: string,
  maskCode: string,
};

export type ClearNotificationInput = {
  id: string,
  action: NotificationAction,
  confirmed: boolean,
  response?: string | null,
  requestor?: string | null,
};

export enum NotificationAction {
  survey = "survey",
  virtualCoach = "virtualCoach",
  achievementEarned = "achievementEarned",
  preTherapyWelcome = "preTherapyWelcome",
}


export type Notification = {
  __typename: "Notification",
  id: string,
  action: NotificationAction,
  title: string,
  description?: string | null,
  positiveButtonTitle: string,
  negativeButtonTitle: string,
  url?: string | null,
  negativeActionId?: string | null,
  negativeActionContent?: NotificationPostActionContent | null,
  achievement?: Achievement | null,
};

export type NotificationPostActionContent = {
  __typename: "NotificationPostActionContent",
  title?: string | null,
  description: string,
  positiveButtonTitle: string,
  negativeButtonTitle?: string | null,
};

export type CCIDataInput = {
  id: string,
  confirmed: boolean,
  response?: string | null,
  requestor?: string | null,
};

export type HealthMetricDataInput = {
  data: Array< HealthMetricInput >,
};

export type HealthMetricInput = {
  date: string,
  metricValue: number,
  metricType: HealthMetricTypes,
};

export enum HealthMetricTypes {
  stepCount = "stepCount",
  activeCaloriesBurned = "activeCaloriesBurned",
  weightKilograms = "weightKilograms",
  bloodOxygenPercent = "bloodOxygenPercent",
  bodyMassIndex = "bodyMassIndex",
  heartRateVariabilityMilliseconds = "heartRateVariabilityMilliseconds",
  restingHeartRate = "restingHeartRate",
  timeInBedMinutes = "timeInBedMinutes",
  totalTimeAsleepMinutes = "totalTimeAsleepMinutes",
  exerciseMinutes = "exerciseMinutes",
}


export type HealthMetricData = {
  __typename: "HealthMetricData",
  date: string,
  metricValue: number,
  metricType: HealthMetricTypes,
};

export type HealthMetricSampleInput = {
  sleepSamples?: Array< SleepMetricSampleInput | null > | null,
};

export type SleepMetricSampleInput = {
  startTime: string,
  endTime: string,
  sampleId: string,
  stage: SleepStage,
  shouldCountAsInBed: boolean,
  recordedManually?: boolean | null,
  source?: MetricSampleSourceInput | null,
};

export enum SleepStage {
  awake = "awake",
  asleepLight = "asleepLight",
  asleepDeep = "asleepDeep",
  asleepRem = "asleepRem",
  asleepUnspecified = "asleepUnspecified",
  unspecified = "unspecified",
}


export type MetricSampleSourceInput = {
  identifier: string,
  productType?: string | null,
};

export type HealthMetricSampleData = SleepMetricSample


export type SleepMetricSample = {
  __typename: "SleepMetricSample",
  startTime: string,
  endTime: string,
  sampleId: string,
  stage: SleepStage,
  shouldCountAsInBed: boolean,
  recordedManually?: boolean | null,
  source?: MetricSampleSource | null,
};

export type HealthMetricSample = {
  __typename: "HealthMetricSample",
  startTime: string,
  endTime: string,
  sampleId: string,
  source?: MetricSampleSource | null,
};

export type MetricSampleSource = {
  __typename: "MetricSampleSource",
  identifier: string,
  productType?: string | null,
};

export type DismissCardInput = {
  dismissId: string,
  actionId?: string | null,
};

export type DeleteHealthSamplesInput = {
  sampleIds: Array< string >,
};

export type MaskQuestionsInput = {
  data: Array< MaskQuestionInput >,
};

export type MaskQuestionInput = {
  questionId: string,
  answerId: string,
};

export type PatientWrapper = {
  __typename: "PatientWrapper",
  patient: Patient,
  fgDevices:  Array<FgDevice >,
  masks:  Array<Mask >,
  coachingHistory?: PaginatedCoachingHistory | null,
  sleepLibrary:  Array<SleepLibrary >,
  sleepRecords?: PaginatedSleepRecords | null,
  notifications:  Array<Notification >,
  dashboard: Dashboard,
  healthTrends: HealthTrends,
  achievements:  Array<Achievement >,
  goalTrackingDates?: GoalTrackingDates | null,
  sleepTests:  Array<SleepTest >,
  cciTherapyResponse?: CCITherapyResponse | null,
  sleepSummary: SleepSummary,
  consents:  Array<Consent >,
};

export type PaginatedCoachingHistory = {
  __typename: "PaginatedCoachingHistory",
  items:  Array<CoachingHistory >,
  lastEvaluatedKey?: string | null,
};

export type CoachingHistory = {
  __typename: "CoachingHistory",
  date: string,
  sleepLibraryId: string,
};

export type SleepLibrary = {
  __typename: "SleepLibrary",
  title: string,
  thumbnail: string,
  description: string,
  videoOrHtmlUrl: string,
  contentId: string,
  language: string,
  sleepLibraryId: string,
  section: string,
  type: string,
  sleepLibraryPatientId: string,
  analyticsScreenName: string,
  videoId?: string | null,
};

export type PaginatedSleepRecords = {
  __typename: "PaginatedSleepRecords",
  items:  Array<SleepRecord >,
  lastEvaluatedKey?: string | null,
};

export type SleepRecord = {
  __typename: "SleepRecord",
  startDate: string,
  totalUsage: number,
  sleepScore: number,
  usageScore: number,
  ahiScore: number,
  maskScore: number,
  leakScore: number,
  ahi?: number | null,
  maskPairCount: number,
  leakPercentile?: number | null,
  sleepRecordPatientId?: string | null,
};

export type Dashboard = {
  __typename: "Dashboard",
  sectionsV1:  Array<SectionV1 | null >,
  sectionsV2:  Array<SectionV2 | null >,
  sectionsV3:  Array<SectionV3 >,
  sectionsV4:  Array<SectionV4 >,
};

export type SectionV1 = {
  __typename: "SectionV1",
  sectionType: SectionType,
  cards:  Array<CardV1 | null >,
};

export enum SectionType {
  grid = "grid",
  horizontalFlow = "horizontalFlow",
  verticalFlow = "verticalFlow",
}


export type CardV1 = {
  __typename: "CardV1",
  cardType: CardType,
  header?: string | null,
  subheader?: string | null,
  body?: string | null,
  action?: Action | null,
  mediaAsset?: MediaAsset | null,
  button?: Button | null,
  additionalInfo?: string | null,
};

export enum CardType {
  contentOverGradient = "contentOverGradient",
  contentOverMedia = "contentOverMedia",
  goal = "goal",
  mediaWithContent = "mediaWithContent",
  score = "score",
  textWithLeftIcon = "textWithLeftIcon",
  textWithRightIcon = "textWithRightIcon",
}


export type Action = {
  __typename: "Action",
  actionType: ActionType,
  value?: string | null,
  screenName?: ScreenName | null,
  analyticsScreenName?: string | null,
  transition?: Transition | null,
  additionalInfo?: string | null,
};

export enum ActionType {
  classicCoaching = "classicCoaching",
  dialog = "dialog",
  dismiss = "dismiss",
  file = "file",
  local = "local",
  remote = "remote",
  webview = "webview",
}


export enum ScreenName {
  scoreDetails = "scoreDetails",
  myAchievements = "myAchievements",
  goalDetails = "goalDetails",
  coaching = "coaching",
}


export enum Transition {
  push = "push",
  modal = "modal",
  expand = "expand",
}


export type MediaAsset = {
  __typename: "MediaAsset",
  assetType: MediaAssetType,
  sourceType: MediaAssetSourceType,
  content: string,
};

export enum MediaAssetType {
  image = "image",
  video = "video",
}


export enum MediaAssetSourceType {
  local = "local",
  remote = "remote",
}


export type Button = {
  __typename: "Button",
  buttonType: ButtonType,
  backgroundHexColor?: string | null,
  icon?: string | null,
  text: string,
  textHexColor?: string | null,
  action: Action,
};

export enum ButtonType {
  round = "round",
  text = "text",
}


export type SectionV2 = {
  __typename: "SectionV2",
  sectionType: SectionTypeV2,
  cards:  Array<CardV2 | null >,
};

export enum SectionTypeV2 {
  grid = "grid",
  horizontalFlow = "horizontalFlow",
  verticalFlow = "verticalFlow",
}


export type CardV2 = {
  __typename: "CardV2",
  cardType: CardTypeV2,
  header?: string | null,
  subheader?: string | null,
  body?: string | null,
  action?: ActionV2 | null,
  mediaAsset?: MediaAssetV2 | null,
  button?: ButtonV2 | null,
  additionalInfo?: string | null,
};

export enum CardTypeV2 {
  contentOverGradient = "contentOverGradient",
  contentOverMedia = "contentOverMedia",
  goal = "goal",
  mediaWithContent = "mediaWithContent",
  score = "score",
  textWithLeftIcon = "textWithLeftIcon",
  textWithRightIcon = "textWithRightIcon",
}


export type ActionV2 = {
  __typename: "ActionV2",
  actionType: ActionTypeV2,
  value?: string | null,
  screenName?: ScreenNameV2 | null,
  analyticsScreenName?: string | null,
  transition?: TransitionV2 | null,
  additionalInfo?: string | null,
};

export enum ActionTypeV2 {
  dialog = "dialog",
  dismiss = "dismiss",
  file = "file",
  local = "local",
  remote = "remote",
  webview = "webview",
  brightcoveVideo = "brightcoveVideo",
}


export enum ScreenNameV2 {
  scoreDetails = "scoreDetails",
  myAchievements = "myAchievements",
  goalDetails = "goalDetails",
  coaching = "coaching",
  maskMagnetInformation = "maskMagnetInformation",
  goalCalendar = "goalCalendar",
  nightowlSetup = "nightowlSetup",
  therapyDataDetails = "therapyDataDetails",
  preTherapyToTherapy = "preTherapyToTherapy",
  sleepStages = "sleepStages",
}


export enum TransitionV2 {
  push = "push",
  modal = "modal",
  expand = "expand",
  fullScreenModal = "fullScreenModal",
}


export type MediaAssetV2 = {
  __typename: "MediaAssetV2",
  assetType: MediaAssetTypeV2,
  sourceType: MediaAssetSourceTypeV2,
  content: string,
};

export enum MediaAssetTypeV2 {
  image = "image",
  video = "video",
}


export enum MediaAssetSourceTypeV2 {
  local = "local",
  remote = "remote",
}


export type ButtonV2 = {
  __typename: "ButtonV2",
  buttonType: ButtonTypeV2,
  backgroundHexColor?: string | null,
  icon?: string | null,
  text: string,
  textHexColor?: string | null,
  action: ActionV2,
};

export enum ButtonTypeV2 {
  round = "round",
  text = "text",
}


export type SectionV3 = {
  __typename: "SectionV3",
  sectionType: SectionTypeV2,
  cards:  Array<CardV3 >,
};

export type CardV3 = {
  __typename: "CardV3",
  analyticsId: string,
  feature: Feature,
  sortPriority: number,
  smartCoachingId?: string | null,
};

export type ScoreCard = {
  __typename: "ScoreCard",
  analyticsId: string,
  feature: Feature,
  sortPriority: number,
  smartCoachingId?: string | null,
};

export enum Feature {
  classicCoaching = "classicCoaching",
  goalTracking = "goalTracking",
  healthSharingPrompt = "healthSharingPrompt",
  patientMessaging = "patientMessaging",
  score = "score",
  smartCoaching = "smartCoaching",
  regulatory = "regulatory",
  sleepTest = "sleepTest",
  careCheckIn = "careCheckIn",
  luxe = "luxe",
  sleepStages = "sleepStages",
  myAirDirect = "myAirDirect",
}


export type GoalCard = {
  __typename: "GoalCard",
  analyticsId: string,
  feature: Feature,
  sortPriority: number,
  hasSleepData: boolean,
  requiredHoursPerNight: number,
  numberOfNightsGoal: number,
  nightsAchieved: number,
  goalPeriodStartDate: string,
  goalPeriodEndDate: string,
  action: ActionV3,
  smartCoachingId?: string | null,
};

export type ActionV3 = {
  __typename: "ActionV3",
  analyticsId: string,
};

export type DismissAction = {
  __typename: "DismissAction",
  analyticsId: string,
  dismissId: string,
  alert?: Alert | null,
};

export type Alert = {
  __typename: "Alert",
  header?: string | null,
  body: string,
};

export type LaunchScreenAction = {
  __typename: "LaunchScreenAction",
  analyticsId: string,
  screenName: ScreenNameV2,
  transition: TransitionV2,
};

export type LaunchSplashScreenAction = {
  __typename: "LaunchSplashScreenAction",
  analyticsId: string,
  transition: TransitionV2,
  feature: SplashScreenFeature,
};

export enum SplashScreenFeature {
  myAirDirect = "myAirDirect",
}


export type WebviewAction = {
  __typename: "WebviewAction",
  analyticsId: string,
  analyticsScreenName: string,
  url: string,
  transition: TransitionV2,
  title?: string | null,
};

export type BrightcoveVideoAction = {
  __typename: "BrightcoveVideoAction",
  analyticsId: string,
  analyticsScreenName: string,
  videoId: string,
  transition: TransitionV2,
};

export type SwitchTabAction = {
  __typename: "SwitchTabAction",
  analyticsId: string,
  tabName: TabName,
};

export enum TabName {
  dashboard = "dashboard",
  history = "history",
  coaching = "coaching",
  profile = "profile",
}


export type CCIWebviewAction = {
  __typename: "CCIWebviewAction",
  analyticsId: string,
  analyticsScreenName: string,
  transition: TransitionV2,
  url: string,
  surveyId: string,
  dismissId?: string | null,
};

export type IconCard = {
  __typename: "IconCard",
  analyticsId: string,
  feature: Feature,
  sortPriority: number,
  header: string,
  body?: string | null,
  icon: ImageAsset,
  action?: ActionV3 | null,
  bottomButtons?:  Array<TextButton > | null,
  topRightButton?: IconButton | null,
  smartCoachingId?: string | null,
};

export type ImageAsset = RemoteImageAsset | LocalImageAsset


export type RemoteImageAsset = {
  __typename: "RemoteImageAsset",
  url: string,
};

export type RemoteMediaAsset = {
  __typename: "RemoteMediaAsset",
  url: string,
};

export type LocalImageAsset = {
  __typename: "LocalImageAsset",
  name: string,
};

export type LocalMediaAsset = {
  __typename: "LocalMediaAsset",
  name: string,
};

export type TextButton = {
  __typename: "TextButton",
  action: ActionV3,
  text: string,
  smartCoachingButtonType?: string | null,
};

export type ButtonV3 = {
  __typename: "ButtonV3",
  action: ActionV3,
  smartCoachingButtonType?: string | null,
};

export type RoundButton = {
  __typename: "RoundButton",
  action: ActionV3,
  text: string,
  smartCoachingButtonType?: string | null,
};

export type IconButton = {
  __typename: "IconButton",
  action: ActionV3,
  icon: ImageAsset,
  accessibilityText: string,
  smartCoachingButtonType?: string | null,
};

export type ImageWithContentCard = {
  __typename: "ImageWithContentCard",
  analyticsId: string,
  feature: Feature,
  sortPriority: number,
  header: string,
  subheader?: string | null,
  body?: string | null,
  imageAsset: ImageAsset,
  primaryButton: RoundButton,
  secondaryButton?: TextButton | null,
  smartCoachingId?: string | null,
};

export type TitleCard = {
  __typename: "TitleCard",
  analyticsId: string,
  feature: Feature,
  sortPriority: number,
  title: string,
  subheader?: string | null,
  button: RoundButton,
  body?: string | null,
  smartCoachingId?: string | null,
};

export type ProgressCard = {
  __typename: "ProgressCard",
  analyticsId: string,
  feature: Feature,
  sortPriority: number,
  title: string,
  header: string,
  subheader?: string | null,
  secondarySubheader?: string | null,
  progressBar: ProgressBar,
  body?: string | null,
  icon?: ImageAsset | null,
  smartCoachingId?: string | null,
  action?: ActionV3 | null,
};

export type ProgressBar = FractionalProgressBar | DiscreteProgressBar


export type FractionalProgressBar = {
  __typename: "FractionalProgressBar",
  intervals: Array< string >,
  completed: number,
  backgroundHexColor?: string | null,
  foregroundHexColor?: string | null,
};

export type DiscreteProgressBar = {
  __typename: "DiscreteProgressBar",
  total: number,
  segments:  Array<Segment >,
  defaultHexColor?: string | null,
};

export type Segment = {
  __typename: "Segment",
  segmentCode: string,
  hexColor?: string | null,
};

export type GradientCard = {
  __typename: "GradientCard",
  analyticsId: string,
  feature: Feature,
  sortPriority: number,
  smartCoachingId?: string | null,
  header: string,
  body: string,
  primaryButton: RoundButton,
  secondaryButton?: TextButton | null,
  topRightButton?: IconButton | null,
};

export type TherapyRCCard = {
  __typename: "TherapyRCCard",
  analyticsId: string,
  feature: Feature,
  sortPriority: number,
  smartCoachingId?: string | null,
};

export type TherapyRCDataCard = {
  __typename: "TherapyRCDataCard",
  analyticsId: string,
  feature: Feature,
  sortPriority: number,
  startDate: string,
  imageAsset: ImageAsset,
  totalUsage?: number | null,
  leakPercentile?: number | null,
  ahi?: number | null,
  maskOnOffCount?: number | null,
  button: RoundButton,
  smartCoachingId?: string | null,
};

export type TherapyPAPDataCard = {
  __typename: "TherapyPAPDataCard",
  analyticsId: string,
  feature: Feature,
  sortPriority: number,
  startDate: string,
  totalUsage?: number | null,
  usageScore: number,
  leakPercentile?: number | null,
  leakScore: number,
  ahi?: number | null,
  ahiScore: number,
  maskOnOffCount?: number | null,
  maskScore: number,
  sleepScore: number,
  smartCoachingId?: string | null,
};

export type SectionV4 = {
  __typename: "SectionV4",
  sectionType: SectionTypeV2,
  cards:  Array<CardV3 >,
};

export type HealthTrends = {
  __typename: "HealthTrends",
  usage: HealthTrendItem,
  stepCount?: HealthTrendItem | null,
  weightKilograms?: HealthTrendItem | null,
  activeCaloriesBurned?: HealthTrendItem | null,
  bloodOxygenPercent?: HealthTrendItem | null,
  bodyMassIndex?: HealthTrendItem | null,
  heartRateVariabilityMilliseconds?: HealthTrendItem | null,
  restingHeartRate?: HealthTrendItem | null,
  timeInBedMinutes?: HealthTrendItem | null,
  totalTimeAsleepMinutes?: HealthTrendItem | null,
  exerciseMinutes?: HealthTrendItem | null,
  maskSeal: HealthTrendItem,
  ahi: HealthTrendItem,
  maskOnOffCount: HealthTrendItem,
  sleepScore: HealthTrendItem,
};

export type HealthTrendItem = {
  __typename: "HealthTrendItem",
  previousAverage?: number | null,
  currentAverage?: number | null,
  trend?: number | null,
};

export type GoalTrackingDates = {
  __typename: "GoalTrackingDates",
  goalPeriodStartDate: string,
  goalPeriodEndDate: string,
};

export type SleepTest = {
  __typename: "SleepTest",
  id: string,
  isActive: boolean,
};

export type CCITherapyResponse = {
  __typename: "CCITherapyResponse",
  cciSurveys:  Array<CCISurvey >,
};

export type CCISurvey = {
  __typename: "CCISurvey",
  dateTime?: string | null,
  items:  Array<CCISurveyContent >,
};

export type CCISurveyContent = {
  __typename: "CCISurveyContent",
  headerText: string,
  bodyContent:  Array<CCITextWithAction >,
};

export type CCITextWithAction = {
  __typename: "CCITextWithAction",
  localizedText: string,
  actionType: CCIActionType,
  contentArg?: string | null,
};

export enum CCIActionType {
  LocalAction = "LocalAction",
  CCIWebview = "CCIWebview",
  NoAction = "NoAction",
}


export type SleepSummary = {
  __typename: "SleepSummary",
  date: string,
  totalTimeAsleepMinutes?: number | null,
  totalTimeInBedMinutes?: number | null,
  totalUsageHours?: number | null,
  sleepSessions?:  Array<SleepSession > | null,
};

export type SleepSession = {
  __typename: "SleepSession",
  startTime: string,
  endTime: string,
  timeAsleepSeconds: number,
  durationSeconds: number,
  sleepSamples:  Array<SleepMetricSample >,
};

export type Consent = {
  __typename: "Consent",
  consentType: ConsentType,
  consentStatus: ConsentStatus,
  consentCreationTime?: string | null,
  consentExpirationTime?: string | null,
  consentUrl?: string | null,
};

export enum ConsentType {
  nightowlHipaa = "nightowlHipaa",
}


export enum ConsentStatus {
  consented = "consented",
  expired = "expired",
  notApplicable = "notApplicable",
  revoked = "revoked",
  withheld = "withheld",
}


export type MaskQuestion = {
  __typename: "MaskQuestion",
  questionId: string,
  localizedQuestion: string,
  answers:  Array<MaskAnswer >,
};

export type MaskAnswer = {
  __typename: "MaskAnswer",
  answerId: string,
  localizedAnswer: string,
};

export type CountryMetadata = {
  __typename: "CountryMetadata",
  countryFeatures: CountryFeatures,
  supportedFgDevices: FgDeviceSeriesFamilies,
};

export type CountryFeatures = {
  __typename: "CountryFeatures",
  allowedAnalyticsModes: Array< AnalyticsMode >,
  isSmartCoachingSupported: boolean,
  marketingConsentDefault?: boolean | null,
  minimumAgeInYears: number,
  optInAnalyticsMode?: AnalyticsMode | null,
  optOutAnalyticsMode?: AnalyticsMode | null,
  requireAnalyticsMode: boolean,
  requireProcessHealthDataOptIn: boolean,
  showAdaLabelInSupport: boolean,
  supportedPatientJourneys: Array< PatientJourney >,
  supportsAchievements: boolean,
};

export type FgDeviceSeriesFamilies = {
  __typename: "FgDeviceSeriesFamilies",
  air10?: FgDeviceSeries | null,
  air11?: FgDeviceSeries | null,
};

export type FgDeviceSeries = {
  __typename: "FgDeviceSeries",
  imagePath: string,
  families:  Array<FgDeviceFamily >,
};

export type FgDeviceFamily = {
  __typename: "FgDeviceFamily",
  name: FgFamily,
  imagePath: string,
  myAirDirect: boolean,
};

export type UpdatePatientMutationVariables = {
  input: UpdatePatientInput,
};

export type UpdatePatientMutation = {
  updatePatient?:  {
    __typename: "Patient",
    id: string,
    email: string,
    countryId: string,
    firstName: string,
    lastName: string,
    dateOfBirth: string,
    gender: Gender,
    sleepTestType?: SleepTestType | null,
    startTherapyGroup?: StartTherapyGroup | null,
    userEnteredAhi?: number | null,
    phoneNumber?: string | null,
    aboutMeProgress?: number | null,
    myEquipmentProgress?: number | null,
    myHealthProgress?: number | null,
    overallProgress?: number | null,
    achievementsPushEnabled?: boolean | null,
    coachingPushEnabled?: boolean | null,
    coachingSmsEnabled?: boolean | null,
    coachingEmailEnabled?: boolean | null,
    cleaningPushEnabled?: boolean | null,
    cleaningSmsEnabled?: boolean | null,
    cleaningEmailEnabled?: boolean | null,
    smartCoachingEnabled?: boolean | null,
    stepCountSharingEnabled?: boolean | null,
    healthSharingEnabled?: boolean | null,
    healthSharingLastPromptedVersion?: string | null,
    shareDetailsWithProviderOptIn?: boolean | null,
    analyticsMode?: AnalyticsMode | null,
    analyticsId?: string | null,
    pushToken?: string | null,
    timezoneId?: string | null,
    furiganaFamilyName?: string | null,
    furiganaGivenName?: string | null,
    marketingOptIn?: boolean | null,
    achievements:  Array< {
      __typename: "Achievement",
      id: string,
      name: string,
      description?: string | null,
      imageUrl?: string | null,
      earnedDate?: string | null,
    } >,
    journeys: Array< PatientJourney >,
    firstTherapySessionDate?: string | null,
    lifetimeUsageHours?: number | null,
    processHealthDataOptIn?: boolean | null,
  } | null,
};

export type AddFgDeviceMutationVariables = {
  input: AddFgDeviceInput,
};

export type AddFgDeviceMutation = {
  addFgDevice:  Array< {
    __typename: "FgDevice",
    serialNumber: string,
    deviceType: FgDeviceType,
    deviceSeries?: FgSeries | null,
    deviceFamily?: FgFamily | null,
    lastSleepDataReportTime?: string | null,
    localizedName: string,
    imagePath: string,
    imageUrl: string,
    fgDeviceManufacturerName: string,
    fgDevicePatientId: string,
    deviceSubtype: FgDeviceSubtype,
  } >,
};

export type AddFlowGeneratorMutationVariables = {
  input: AddFlowGeneratorInput,
};

export type AddFlowGeneratorMutation = {
  addFlowGenerator:  Array< {
    __typename: "FgDevice",
    serialNumber: string,
    deviceType: FgDeviceType,
    deviceSeries?: FgSeries | null,
    deviceFamily?: FgFamily | null,
    lastSleepDataReportTime?: string | null,
    localizedName: string,
    imagePath: string,
    imageUrl: string,
    fgDeviceManufacturerName: string,
    fgDevicePatientId: string,
    deviceSubtype: FgDeviceSubtype,
  } >,
};

export type AddFlowGeneratorV2MutationVariables = {
  input: AddFlowGeneratorInputV2,
};

export type AddFlowGeneratorV2Mutation = {
  addFlowGeneratorV2:  Array< {
    __typename: "FgDevice",
    serialNumber: string,
    deviceType: FgDeviceType,
    deviceSeries?: FgSeries | null,
    deviceFamily?: FgFamily | null,
    lastSleepDataReportTime?: string | null,
    localizedName: string,
    imagePath: string,
    imageUrl: string,
    fgDeviceManufacturerName: string,
    fgDevicePatientId: string,
    deviceSubtype: FgDeviceSubtype,
  } >,
};

export type AddMaskMutationVariables = {
  input: AddMaskInput,
};

export type AddMaskMutation = {
  addMask:  Array< {
    __typename: "Mask",
    maskManufacturerName: string,
    maskCode: string,
    maskType: string,
    localizedName: string,
    imagePath: string,
    imageUrl: string,
    maskPatientId: string,
  } >,
};

export type RemoveFgDeviceMutationVariables = {
  input: RemoveFgDeviceInput,
};

export type RemoveFgDeviceMutation = {
  removeFgDevice:  Array< {
    __typename: "FgDevice",
    serialNumber: string,
    deviceType: FgDeviceType,
    deviceSeries?: FgSeries | null,
    deviceFamily?: FgFamily | null,
    lastSleepDataReportTime?: string | null,
    localizedName: string,
    imagePath: string,
    imageUrl: string,
    fgDeviceManufacturerName: string,
    fgDevicePatientId: string,
    deviceSubtype: FgDeviceSubtype,
  } >,
};

export type RemoveMaskMutationVariables = {
  input: RemoveMaskInput,
};

export type RemoveMaskMutation = {
  removeMask:  Array< {
    __typename: "Mask",
    maskManufacturerName: string,
    maskCode: string,
    maskType: string,
    localizedName: string,
    imagePath: string,
    imageUrl: string,
    maskPatientId: string,
  } >,
};

export type ClearNotificationsMutationVariables = {
  input: Array< ClearNotificationInput >,
};

export type ClearNotificationsMutation = {
  clearNotifications:  Array< {
    __typename: "Notification",
    id: string,
    action: NotificationAction,
    title: string,
    description?: string | null,
    positiveButtonTitle: string,
    negativeButtonTitle: string,
    url?: string | null,
    negativeActionId?: string | null,
    negativeActionContent?:  {
      __typename: "NotificationPostActionContent",
      title?: string | null,
      description: string,
      positiveButtonTitle: string,
      negativeButtonTitle?: string | null,
    } | null,
    achievement?:  {
      __typename: "Achievement",
      id: string,
      name: string,
      description?: string | null,
      imageUrl?: string | null,
      earnedDate?: string | null,
    } | null,
  } >,
};

export type SubmitCCIDataMutationVariables = {
  input?: CCIDataInput | null,
};

export type SubmitCCIDataMutation = {
  submitCCIData: boolean,
};

export type AddHealthMetricMutationVariables = {
  input: HealthMetricDataInput,
};

export type AddHealthMetricMutation = {
  addHealthMetric:  Array< {
    __typename: "HealthMetricData",
    date: string,
    metricValue: number,
    metricType: HealthMetricTypes,
  } >,
};

export type AddHealthMetricSamplesMutationVariables = {
  input: HealthMetricSampleInput,
};

export type AddHealthMetricSamplesMutation = {
  addHealthMetricSamples:  Array<( {
      __typename: "SleepMetricSample",
      startTime: string,
      endTime: string,
      sampleId: string,
      stage: SleepStage,
      shouldCountAsInBed: boolean,
      recordedManually?: boolean | null,
      source?:  {
        __typename: "MetricSampleSource",
        identifier: string,
        productType?: string | null,
      } | null,
    }
  ) >,
};

export type DismissCardMutationVariables = {
  input: DismissCardInput,
};

export type DismissCardMutation = {
  dismissCard?: string | null,
};

export type DeleteHealthSamplesMutationVariables = {
  input: DeleteHealthSamplesInput,
};

export type DeleteHealthSamplesMutation = {
  deleteHealthSamples: Array< string >,
};

export type SubmitMaskAnswersMutationVariables = {
  input: MaskQuestionsInput,
};

export type SubmitMaskAnswersMutation = {
  submitMaskAnswers: boolean,
};

export type GetPatientWrapperQuery = {
  getPatientWrapper?:  {
    __typename: "PatientWrapper",
    patient:  {
      __typename: "Patient",
      id: string,
      email: string,
      countryId: string,
      firstName: string,
      lastName: string,
      dateOfBirth: string,
      gender: Gender,
      sleepTestType?: SleepTestType | null,
      startTherapyGroup?: StartTherapyGroup | null,
      userEnteredAhi?: number | null,
      phoneNumber?: string | null,
      aboutMeProgress?: number | null,
      myEquipmentProgress?: number | null,
      myHealthProgress?: number | null,
      overallProgress?: number | null,
      achievementsPushEnabled?: boolean | null,
      coachingPushEnabled?: boolean | null,
      coachingSmsEnabled?: boolean | null,
      coachingEmailEnabled?: boolean | null,
      cleaningPushEnabled?: boolean | null,
      cleaningSmsEnabled?: boolean | null,
      cleaningEmailEnabled?: boolean | null,
      smartCoachingEnabled?: boolean | null,
      stepCountSharingEnabled?: boolean | null,
      healthSharingEnabled?: boolean | null,
      healthSharingLastPromptedVersion?: string | null,
      shareDetailsWithProviderOptIn?: boolean | null,
      analyticsMode?: AnalyticsMode | null,
      analyticsId?: string | null,
      pushToken?: string | null,
      timezoneId?: string | null,
      furiganaFamilyName?: string | null,
      furiganaGivenName?: string | null,
      marketingOptIn?: boolean | null,
      achievements:  Array< {
        __typename: "Achievement",
        id: string,
        name: string,
        description?: string | null,
        imageUrl?: string | null,
        earnedDate?: string | null,
      } >,
      journeys: Array< PatientJourney >,
      firstTherapySessionDate?: string | null,
      lifetimeUsageHours?: number | null,
      processHealthDataOptIn?: boolean | null,
    },
    fgDevices:  Array< {
      __typename: "FgDevice",
      serialNumber: string,
      deviceType: FgDeviceType,
      deviceSeries?: FgSeries | null,
      deviceFamily?: FgFamily | null,
      lastSleepDataReportTime?: string | null,
      localizedName: string,
      imagePath: string,
      imageUrl: string,
      fgDeviceManufacturerName: string,
      fgDevicePatientId: string,
      deviceSubtype: FgDeviceSubtype,
    } >,
    masks:  Array< {
      __typename: "Mask",
      maskManufacturerName: string,
      maskCode: string,
      maskType: string,
      localizedName: string,
      imagePath: string,
      imageUrl: string,
      maskPatientId: string,
    } >,
    coachingHistory?:  {
      __typename: "PaginatedCoachingHistory",
      items:  Array< {
        __typename: "CoachingHistory",
        date: string,
        sleepLibraryId: string,
      } >,
      lastEvaluatedKey?: string | null,
    } | null,
    sleepLibrary:  Array< {
      __typename: "SleepLibrary",
      title: string,
      thumbnail: string,
      description: string,
      videoOrHtmlUrl: string,
      contentId: string,
      language: string,
      sleepLibraryId: string,
      section: string,
      type: string,
      sleepLibraryPatientId: string,
      analyticsScreenName: string,
      videoId?: string | null,
    } >,
    sleepRecords?:  {
      __typename: "PaginatedSleepRecords",
      items:  Array< {
        __typename: "SleepRecord",
        startDate: string,
        totalUsage: number,
        sleepScore: number,
        usageScore: number,
        ahiScore: number,
        maskScore: number,
        leakScore: number,
        ahi?: number | null,
        maskPairCount: number,
        leakPercentile?: number | null,
        sleepRecordPatientId?: string | null,
      } >,
      lastEvaluatedKey?: string | null,
    } | null,
    notifications:  Array< {
      __typename: "Notification",
      id: string,
      action: NotificationAction,
      title: string,
      description?: string | null,
      positiveButtonTitle: string,
      negativeButtonTitle: string,
      url?: string | null,
      negativeActionId?: string | null,
      negativeActionContent?:  {
        __typename: "NotificationPostActionContent",
        title?: string | null,
        description: string,
        positiveButtonTitle: string,
        negativeButtonTitle?: string | null,
      } | null,
      achievement?:  {
        __typename: "Achievement",
        id: string,
        name: string,
        description?: string | null,
        imageUrl?: string | null,
        earnedDate?: string | null,
      } | null,
    } >,
    dashboard:  {
      __typename: "Dashboard",
      sectionsV1:  Array< {
        __typename: "SectionV1",
        sectionType: SectionType,
      } | null >,
      sectionsV2:  Array< {
        __typename: "SectionV2",
        sectionType: SectionTypeV2,
      } | null >,
      sectionsV3:  Array< {
        __typename: "SectionV3",
        sectionType: SectionTypeV2,
      } >,
      sectionsV4:  Array< {
        __typename: "SectionV4",
        sectionType: SectionTypeV2,
      } >,
    },
    healthTrends:  {
      __typename: "HealthTrends",
      usage:  {
        __typename: "HealthTrendItem",
        previousAverage?: number | null,
        currentAverage?: number | null,
        trend?: number | null,
      },
      stepCount?:  {
        __typename: "HealthTrendItem",
        previousAverage?: number | null,
        currentAverage?: number | null,
        trend?: number | null,
      } | null,
      weightKilograms?:  {
        __typename: "HealthTrendItem",
        previousAverage?: number | null,
        currentAverage?: number | null,
        trend?: number | null,
      } | null,
      activeCaloriesBurned?:  {
        __typename: "HealthTrendItem",
        previousAverage?: number | null,
        currentAverage?: number | null,
        trend?: number | null,
      } | null,
      bloodOxygenPercent?:  {
        __typename: "HealthTrendItem",
        previousAverage?: number | null,
        currentAverage?: number | null,
        trend?: number | null,
      } | null,
      bodyMassIndex?:  {
        __typename: "HealthTrendItem",
        previousAverage?: number | null,
        currentAverage?: number | null,
        trend?: number | null,
      } | null,
      heartRateVariabilityMilliseconds?:  {
        __typename: "HealthTrendItem",
        previousAverage?: number | null,
        currentAverage?: number | null,
        trend?: number | null,
      } | null,
      restingHeartRate?:  {
        __typename: "HealthTrendItem",
        previousAverage?: number | null,
        currentAverage?: number | null,
        trend?: number | null,
      } | null,
      timeInBedMinutes?:  {
        __typename: "HealthTrendItem",
        previousAverage?: number | null,
        currentAverage?: number | null,
        trend?: number | null,
      } | null,
      totalTimeAsleepMinutes?:  {
        __typename: "HealthTrendItem",
        previousAverage?: number | null,
        currentAverage?: number | null,
        trend?: number | null,
      } | null,
      exerciseMinutes?:  {
        __typename: "HealthTrendItem",
        previousAverage?: number | null,
        currentAverage?: number | null,
        trend?: number | null,
      } | null,
      maskSeal:  {
        __typename: "HealthTrendItem",
        previousAverage?: number | null,
        currentAverage?: number | null,
        trend?: number | null,
      },
      ahi:  {
        __typename: "HealthTrendItem",
        previousAverage?: number | null,
        currentAverage?: number | null,
        trend?: number | null,
      },
      maskOnOffCount:  {
        __typename: "HealthTrendItem",
        previousAverage?: number | null,
        currentAverage?: number | null,
        trend?: number | null,
      },
      sleepScore:  {
        __typename: "HealthTrendItem",
        previousAverage?: number | null,
        currentAverage?: number | null,
        trend?: number | null,
      },
    },
    achievements:  Array< {
      __typename: "Achievement",
      id: string,
      name: string,
      description?: string | null,
      imageUrl?: string | null,
      earnedDate?: string | null,
    } >,
    goalTrackingDates?:  {
      __typename: "GoalTrackingDates",
      goalPeriodStartDate: string,
      goalPeriodEndDate: string,
    } | null,
    sleepTests:  Array< {
      __typename: "SleepTest",
      id: string,
      isActive: boolean,
    } >,
    cciTherapyResponse?:  {
      __typename: "CCITherapyResponse",
      cciSurveys:  Array< {
        __typename: "CCISurvey",
        dateTime?: string | null,
      } >,
    } | null,
    sleepSummary:  {
      __typename: "SleepSummary",
      date: string,
      totalTimeAsleepMinutes?: number | null,
      totalTimeInBedMinutes?: number | null,
      totalUsageHours?: number | null,
      sleepSessions?:  Array< {
        __typename: "SleepSession",
        startTime: string,
        endTime: string,
        timeAsleepSeconds: number,
        durationSeconds: number,
      } > | null,
    },
    consents:  Array< {
      __typename: "Consent",
      consentType: ConsentType,
      consentStatus: ConsentStatus,
      consentCreationTime?: string | null,
      consentExpirationTime?: string | null,
      consentUrl?: string | null,
    } >,
  } | null,
};

export type GetMaskQuestionsQueryVariables = {
  maskCode: string,
  locale: string,
};

export type GetMaskQuestionsQuery = {
  getMaskQuestions?:  Array< {
    __typename: "MaskQuestion",
    questionId: string,
    localizedQuestion: string,
    answers:  Array< {
      __typename: "MaskAnswer",
      answerId: string,
      localizedAnswer: string,
    } >,
  } > | null,
};

export type GetCountryMetadataQueryVariables = {
  countryId: string,
};

export type GetCountryMetadataQuery = {
  getCountryMetadata:  {
    __typename: "CountryMetadata",
    countryFeatures:  {
      __typename: "CountryFeatures",
      allowedAnalyticsModes: Array< AnalyticsMode >,
      isSmartCoachingSupported: boolean,
      marketingConsentDefault?: boolean | null,
      minimumAgeInYears: number,
      optInAnalyticsMode?: AnalyticsMode | null,
      optOutAnalyticsMode?: AnalyticsMode | null,
      requireAnalyticsMode: boolean,
      requireProcessHealthDataOptIn: boolean,
      showAdaLabelInSupport: boolean,
      supportedPatientJourneys: Array< PatientJourney >,
      supportsAchievements: boolean,
    },
    supportedFgDevices:  {
      __typename: "FgDeviceSeriesFamilies",
      air10?:  {
        __typename: "FgDeviceSeries",
        imagePath: string,
      } | null,
      air11?:  {
        __typename: "FgDeviceSeries",
        imagePath: string,
      } | null,
    },
  },
};
