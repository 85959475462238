import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { CountryService } from 'src/app/services/country/country.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { MetadataService } from 'src/app/services/metadata/metadata.service';

@Component({
  selector: 'mpp-device-triage',
  templateUrl: './device-triage.component.html',
  styleUrl: './device-triage.component.scss'
})
export class DeviceTriageComponent implements OnInit {
  optionsForm: FormGroup;
  public isPreTherapyDiagnosticCountry: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private analyticsService: AnalyticsService,
    public countryService: CountryService,
    public languageService: LanguageService,
    private metadataService: MetadataService
  ) {
    this.optionsForm = this.fb.group({
      option0: [false],
      option1: [false],
    });
  }

  ngOnInit(): void {
    this.metadataService.getMetadataFromApi().subscribe(metadata => {
      this.isPreTherapyDiagnosticCountry = metadata?.countries.find(country => country.code === this.metadataService.getSelectedCountryId())?.supportedPatientJourneys?.includes('preTherapyDiagnostic') ?? false;

      if (this.isPreTherapyDiagnosticCountry) {
        this.optionsForm.addControl('option2', this.fb.control(false));
      } else {
        this.optionsForm.removeControl('option2');
      }
    });
  }

  setValue(option: string): void {
    Object.keys(this.optionsForm.controls).forEach(key => {
      this.optionsForm.get(key)?.setValue(key === option);
    });
  }

  isSubmitDisabled(): boolean {
    const formValues = this.optionsForm.value;
    return !formValues.option0 && !formValues.option1 || formValues.option2;
  }

  onSubmit(): void {
    if (!this.isSubmitDisabled()) {
      if (this.optionsForm.value.option0) {
        this.router.navigate(['machine-verification']);
      } else if (this.optionsForm.value.option1) {
        this.router.navigate(['/account-registration']);
      }
    }
  }

  playStoreLinkClicked(): void {
    this.analyticsService.logPlayStoreRedirect(this.countryService.getSelectedCountryId(), this.analyticsService.redirectLocationVideoCarousel);
  }

  appStoreLinkClicked(): void {
    this.analyticsService.logAppStoreRedirect(this.countryService.getSelectedCountryId(), this.analyticsService.redirectLocationVideoCarousel);
  }

}
