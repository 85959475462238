import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { getMaskQuestions } from 'src/graphql/queries';
import { GetMaskQuestionsQuery, MaskQuestion, MaskQuestionsInput, SubmitMaskAnswersMutation } from 'src/graphql/types/api.types';
import { gql } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { ApolloService } from 'src/app/services/apollo/apollo.service';
import { GraphQLError } from 'src/app/services/apollo/graphql-payload.type';
import { submitMaskAnswers } from 'src/graphql/mutations';
import { AlertService } from 'src/app/modules/alert/components/services/alert.service';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MaskSettingDialogComponent } from './mask-setting-dialog/mask-setting-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class MaskService {
  maskQuestions: MaskQuestion[];
  hasPillowOptimization: boolean = false;
  maskMp4Url: string = null;
  maskSettingDialog: MatLegacyDialogRef<MaskSettingDialogComponent> = null;

  constructor(private apolloService: ApolloService, private alertService: AlertService) { }

  getMaskQuestions(maskCode: String, locale: String): Observable<[MaskQuestion[], GraphQLError]> {
    return this.apolloService
      .query<GetMaskQuestionsQuery>({
        query: gql(getMaskQuestions),
        variables: {
          maskCode: maskCode,
          locale: locale
        }
      })
      .pipe(
        map(([result, error]) => {
          if (error) {
            this.alertService.info();
            return [null, error];
          }
          return [
            result.getMaskQuestions,
            null,
          ];
        }),
      );
  }
  submitMaskAnswers(input: MaskQuestionsInput): Observable<[ GraphQLError,SubmitMaskAnswersMutation]> {
    return this.apolloService
      .mutate<SubmitMaskAnswersMutation>({
        mutation: gql(submitMaskAnswers),
        variables: {
          input: input
        },
        update: (cache) => {
          cache.reset();
        }
      })
      .pipe(
        map(([error, result]) => {
          if (!result) {
            this.alertService.info();
          }
          return [error, result]
        })
      );
  }
}
