import { Component, Output, EventEmitter } from '@angular/core';
import { ComponentState } from '../component-state.enum';

@Component({
  selector: 'mpp-base-view',
  template: '',
})
export abstract class BaseViewComponent {
  @Output() stateChanged = new EventEmitter<ComponentState>();

  edit(): void {
    this.stateChanged.emit(ComponentState.Edit);
  }
}
