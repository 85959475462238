import { Injectable } from '@angular/core';

import { LanguageService } from 'src/app/services/language/language.service';

interface IOutage {
  startTime: number;
  endTime: number;
  localizedErrorTemplate: string;
}

@Injectable({
  providedIn: 'root',
})
export class LokalizeOutageService {
  private options: { [key: string]: string } = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };

  constructor(private languageService: LanguageService) {}

  errorMessage(outageData: IOutage): string {
    const startTime = this.lokalize(outageData.startTime);
    const endTime = this.lokalize(outageData.endTime);
    return outageData.localizedErrorTemplate
      .replace(/\$starttime|\$startTime/, startTime)
      .replace(/\$endtime|\$endTime/, endTime);
  }

  lokalize(timeStamp: number): string {
    return new Date(timeStamp * 1000).toLocaleString(
      this.languageService.getCurrent2LettersLanguage(),
      this.options,
    );
  }
}
