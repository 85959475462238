import { Injectable, OnDestroy } from '@angular/core';
import { LanguageService } from './language/language.service';
import { Notification, NotificationAction } from '../../graphql/types/api.types';
import ac11 from '../../assets/machine-pta/AC11.json';
import as11 from '../../assets/machine-pta/AS11.json';
import airfitF20 from '../../assets/mask-pta/airfit_f20.json';
import airfitF30 from '../../assets/mask-pta/airfit_f30.json';
import airfitF30i from '../../assets/mask-pta/airfit_f30i.json';
import airfitF40 from '../../assets/mask-pta/airfit_f40.json';
import airfitN20 from '../../assets/mask-pta/airfit_n20.json';
import airfitN30 from '../../assets/mask-pta/airfit_n30.json';
import airfitN30i from '../../assets/mask-pta/airfit_n30i.json';
import airfitP10 from '../../assets/mask-pta/airfit_p10.json';
import airfitP30i from '../../assets/mask-pta/airfit_p30i.json';
import airtouchF20 from '../../assets/mask-pta/airtouch_f20.json';
import airtouchN20 from '../../assets/mask-pta/airtouch_n20.json';
import airtouchN30i from '../../assets/mask-pta/airtouch_n30i.json';
import airfitF20nonmagnetic from '../../assets/mask-pta/airfit_f20_non_magnetic.json';
import airfitF30inonmagnetic from '../../assets/mask-pta/airfit_f20_non_magnetic.json';
import { PtaCloseModalComponent } from '../components/pta-close-modal/pta-close-modal.component';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { SleepRecordService } from './sleep-record/sleep-record.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PtaStepperComponent } from '../components/pta-stepper/pta-stepper.component';
import { AnalyticsService } from './analytics/analytics.service';
import { PtaPromptModalComponent } from '../components/pta-prompt-modal/pta-prompt-modal.component';

// Replaces all instances of ###language_code### with the passed in language code
function replaceLanguageCode(obj: any, langCode: string): void {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === 'object') {
        replaceLanguageCode(obj[key], langCode);
      } else if (typeof obj[key] === 'string') {
        obj[key] = obj[key].replace(/###language_code###/g, langCode);
      }
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class PtaService implements OnDestroy {
  private currentLanguage: string;
  public maskAssets: any;
  public machineAssets: any;
  public notification: Notification;
  public machineSelected: string;
  public doNotLaunchLibraryModal: boolean = false;
  public displayMask: boolean = false;
  public displayMachine: boolean = false;
  protected unsubscribe$ = new Subject<void>();
  private isPtaDialogOpen = false;  // Helps prevent multiple sumultanious PTA dialogs
  public lockPTAFromDashboard = false; // Prevent PTA from appearing in the wrong order, such as when Mask Magnets modal should come first.

  constructor(private languageService: LanguageService, private dialog: MatLegacyDialog, private sleepRecordService: SleepRecordService, private analyticsService: AnalyticsService) { 
    this.currentLanguage = this.languageService.getCurrent2LettersLanguage()
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setMaskAssets(maskCode: string): any {
    // Check to see if the mask code matches
    switch (maskCode) {
      case 'air-fit-f20': this.maskAssets = airfitF20;
        break;
      case 'air-fit-f30': this.maskAssets = airfitF30;
        break;
      case 'air-fit-f30i': this.maskAssets = airfitF30i;
        break;
      case 'air-fit-f40': this.maskAssets = airfitF40;
        break;
      case 'air-fit-n20': this.maskAssets = airfitN20;
        break;
      case 'air-fit-n30': this.maskAssets = airfitN30;
        break;
      case 'air-fit-n30i': this.maskAssets = airfitN30i;
        break;
      case 'air-fit-p10': this.maskAssets = airfitP10;
        break;
      case 'air-fit-p30i': this.maskAssets = airfitP30i;
        break;
      case 'air-touch-f20': this.maskAssets = airtouchF20;
        break;
      case 'air-touch-n20': this.maskAssets = airtouchN20;
        break;
      case 'air-touch-n30i': this.maskAssets = airtouchN30i;
        break;
      case 'air-fit-f20-non-magnetic': this.maskAssets = airfitF20nonmagnetic;
        break;
      case 'air-fit-f30i-non-magnetic': this.maskAssets = airfitF30inonmagnetic;
        break;
      default: this.maskAssets = null;
    }
    // Set the language code based on language service
    replaceLanguageCode(this.maskAssets, this.currentLanguage)
  }

  setMachineAssets(deviceFamily: string, deviceSeries: string): any {
    // Save the machine for PTA stepper final step
    this.machineSelected = deviceFamily;
    // Check to see if the device family and device series matches
    if (deviceFamily === 'airCurve' && deviceSeries === 'air11') {
      this.machineAssets = ac11;
    } else if (deviceFamily === 'airSense' && deviceSeries === 'air11') {
      this.machineAssets = as11;
    } else {
      // Return null if no match
      return null;
    }
    // Set the language code code based on language service
    replaceLanguageCode(this.machineAssets, this.currentLanguage);
    this.machineAssets = this.machineAssets.options[0];
  }

  attemptToLaunchLibraryModal(): void {
    // Control close behavior and only launch the library module at the right time a single time
    if (!this.doNotLaunchLibraryModal) {
      this.dialog.open(PtaCloseModalComponent).beforeClosed().pipe(
        switchMap(() => this.clearNotification()),
          takeUntil(this.unsubscribe$)
      ).subscribe();
    }
  }

  clearNotification() {
    return this.sleepRecordService.clearNotification(
      { id: this.notification.id,
        confirmed: true,
        action: NotificationAction.virtualCoach,
      });
  }

  openDevice(): void {
    this.displayMask = false;
    this.displayMachine = true;
    this.openPTA();
  }

  openMask(): void {
    this.displayMask = true;
    this.displayMachine = false;
    this.openPTA();
  }

  openPtaPrompt(doNotLaunchLibraryModal?: boolean): void {
    if (!this.isPtaDialogOpen) {
      this.isPtaDialogOpen = true;
      this.dialog.open(PtaPromptModalComponent).afterClosed().subscribe(() => {
        this.isPtaDialogOpen = false;  // Reset flag when dialog closes
        this.lockPTAFromDashboard = false;
      });
      this.doNotLaunchLibraryModal = doNotLaunchLibraryModal;
      // This observable completes itself after the dialog is closed. The subscription will automatically be cleaned up.
      this.clearNotification().subscribe()
    }
  }

  openPTA(): void {
    // Open PTA without library module
    this.recordPTALaunched();
    this.dialog.open(PtaStepperComponent, { panelClass: 'pta-dialog'}).beforeClosed().subscribe(() => {
      this.doNotLaunchLibraryModal = true;
    });
  }

  recordPTALaunched(): void {
    this.analyticsService.logPTALaunched();
  }
}
