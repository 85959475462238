export const sleepTestIds = ['notKnown', 'home', 'lab', 'both'] as const;
export const sleepTestTranslationIds = [
  '',
  'DATA/SLEEP-TEST.Home',
  'DATA/SLEEP-TEST.SleepLab',
  'DATA/SLEEP-TEST.Both',
] as const;
export const sleepTestImgSources = [
  '/assets/images/sleeptesthome.png',
  '/assets/images/sleeptestlab.png',
  '/assets/images/sleeptestboth.png',
] as const;
export const sleepTestImgSourcesSelected = [
  '/assets/images/sleeptesthome_selected.png',
  '/assets/images/sleeptestlab_selected.png',
  '/assets/images/sleeptestboth_selected.png',
];
export type SleepTestId = typeof sleepTestIds[number];
export type SleepTestTranslated = typeof sleepTestTranslationIds[number];
export type SleepTestImgSource = typeof sleepTestImgSources[number];
export type SleepTestImgSourceSelected = typeof sleepTestImgSourcesSelected[number];

export const sleepTestTypes: Partial<Record<SleepTestId, SleepTestImgSource>> = {
  home: '/assets/images/sleeptesthome.png',
  lab: '/assets/images/sleeptestlab.png',
  both: '/assets/images/sleeptestboth.png',
} as const;

export const sleepTestTypesSelected: Partial<Record<SleepTestId, SleepTestImgSourceSelected>> = {
  home: '/assets/images/sleeptesthome_selected.png',
  lab: '/assets/images/sleeptestlab_selected.png',
  both: '/assets/images/sleeptestboth_selected.png',
} as const;

export const sleepTestTranslated: Record<SleepTestId, SleepTestTranslated> = {
  notKnown: '',
  home: 'DATA/SLEEP-TEST.Home',
  lab: 'DATA/SLEEP-TEST.SleepLab',
  both: 'DATA/SLEEP-TEST.Both',
};
