import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ResponseResult, ResponseResultPatientMessaging } from 'src/app/services/showtime-api/response.structure';
import { ShowTimeApiService } from 'src/app/services/showtime-api/showtime-api.service';
import { Alert, AlertType } from '../model/alert.model';
import { AlertService } from './alert.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MetadataService } from 'src/app/services/metadata/metadata.service';

@Injectable({
  providedIn: 'root'
})
export class PatientMessagingService extends ShowTimeApiService {
  protected unsubscribe$ = new Subject<void>();

  constructor(protected http: HttpClient, protected alertService: AlertService, private router: Router, private metadata: MetadataService) {
    super(alertService);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getPatientMessaging(languageId?: string, timezoneId?: string): void {
    if (!timezoneId) {
      timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    // Do not make the Patient Messaging call if we do not have a language ID
    if (languageId) {
      // Skip an Angular Change Detection Cycle
      setTimeout(() =>
      this.http
        .post<ResponseResult>(`${this.metadata.instanceInfo.restUrl}/V2/Patient/PatientMessagingApi`,{ 'timeZone': timezoneId })
        .pipe(takeUntil(this.unsubscribe$), this.showError()).subscribe((res: ResponseResult) => {
          if ((res?.entity as ResponseResultPatientMessaging)?.header) {
            // Craft a custom alert to display our Patient Message
            let alert = new Alert({...{
              links: [
                {
                  title: (res?.entity as ResponseResultPatientMessaging)?.link ? (res?.entity as ResponseResultPatientMessaging).body : '',
                  callback: (): void => {
                    (res?.entity as ResponseResultPatientMessaging)?.link ? window.open((res?.entity as ResponseResultPatientMessaging)?.link) : this.router.navigate(['/support']);
                  },
                  textAfterLink: !(res?.entity as ResponseResultPatientMessaging)?.link ? (res?.entity as ResponseResultPatientMessaging).body : '',
                },
              ],
              isDismissable: false,
              isPatientMessaging: true,
              iconUrl: (res?.entity as ResponseResultPatientMessaging)?.iconUrl
            }, type: AlertType.Info, message: (res?.entity as ResponseResultPatientMessaging).header });
            // If the current alert, if any, is not identical to the incoming alert, clear the existing alert and display the new alert
            if (!this.messageService.checkIfPatientMessagingAndIdentical(alert)) {
              this.alertService.removePatientMessagingAlert();
              this.alertService.alert(alert);
            }
        }
    }), 0);
  }}
}