import { Component, Input } from '@angular/core';
import { monthIds, MonthId, months, MonthName } from 'src/app/modules/registration/data/month.type';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'mpp-add-date-of-birth',
  templateUrl: './add-date-of-birth.component.html',
  styleUrls: ['./add-date-of-birth.component.scss'],
})
export class AddDateOfBirthComponent {
  @Input() form: UntypedFormGroup;
  // arrays with values from 1 to 31, for days selection
  daysArray = Array.from(Array(31), (_, i) => i + 1);
  @Input() minimumAge = 13;

  public getMonthName(monthId: MonthId): MonthName {
    return months[monthId];
  }

  public getMonthIds(): readonly MonthId[] {
    return monthIds;
  }

  /**
   * Checks if birthday control form wasn't filled with data
   * Each part of this control: day, month, year should be checked
   */
  public isBirthdayControlRequired(): boolean {
    return (
      (this.form.get('day').errors?.required !== undefined ||
        this.form.get('month').errors?.required !== undefined ||
        this.form.get('year').errors?.required !== undefined) &&
      this.form.touched
    );
  }
}
