import { Subject } from 'rxjs';
import { OnDestroy, Component } from '@angular/core';

@Component({
  selector: 'mpp-base-component',
  template: '',
})
export abstract class BaseComponent implements OnDestroy {
  protected unsubscribe$ = new Subject<void>();

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
