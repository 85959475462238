<div id="modal-ConfirmDeleteAccountConfirmation" class="c-modal-material c-modal--confirm">
  <div class="c-modal__header">
    <h2 class="c-modal__heading has-icon"><svg class="c-svg c-svg--warning-circle-outline-24">
        <use xlink:href="#c-svg--warning-circle-outline-24"></use>
      </svg>{{'MY-ACCOUNT/MY-PRIVACY.ConfirmDeletion.ConfirmDeletion' | translate}}</h2>
  </div>
  <div class="c-modal__body" innerHTML="{{'MY-ACCOUNT/MY-PRIVACY.ConfirmDeletion.WantToCloseAccount' | translate}}">
  </div>
  <div class="c-modal__footer">
    <div class="o-layout o-layout--auto@tablet">
      <div class="o-layout__item"><button class="c-modal__warnConfirm-btn [ c-btn c-btn--primary ]"
          (click)="openPermanentlyDelete()">{{'MY-ACCOUNT/MY-PRIVACY.Continue' | translate}}</button>
      </div>
      <div class="o-layout__item"><button class="c-modal__warnCancel-btn [ c-btn c-btn--tertiary ]"
          (click)="cancel()">{{'MY-ACCOUNT/MY-PRIVACY.Cancel' | translate}}</button>
      </div>
    </div>
  </div>
</div>