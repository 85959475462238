import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AuthService } from '@auth/auth.service';
import { CredentialsService } from 'src/app/services/credentials/credentials.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';

@Component({
  selector: 'mpp-permanently-delete',
  templateUrl: './permanently-delete.component.html',
  styleUrls: ['./permanently-delete.component.scss'],
})
export class PermanentlyDeleteComponent {
  constructor(
    public dialogRef: MatDialogRef<PermanentlyDeleteComponent>,
    private fb: UntypedFormBuilder,
    private credentialsService: CredentialsService,
    private authService: AuthService,
    private spinnerService: SpinnerService,
  ) {}

  form = this.fb.group({
    password: ['', Validators.required],
    feedback: [''],
  });
  deleteAccount(): void {
    const password = this.form.get('password').value;
    const feedback = this.form.get('feedback').value;
    const spinner = this.spinnerService.show();
    // We are trying to delete our account. Call the V2 endpoint
    this.credentialsService.deleteAccount(password, feedback).subscribe((response) => {
      spinner.hide();
      // If we succeed, log out and nothing else matters
      if (response.result?.success) {
        this.authService.logout();
        this.dialogRef.close(false);
        return;
      // If we fail, update the form appropriately so the user knows and does not lose their form data
      } else if (response.result.errorCode === "invalidAccountPassword") {
        this.form.get('password').setErrors({ invalid: true});
        this.form.markAsUntouched();
      // Throw an error if an API call truly failed
      } else {
        this.dialogRef.close(true);
      }
    });
  }

  cancel(): void {
    this.dialogRef.close(false);
  }
}
