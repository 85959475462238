import { Dayjs } from 'dayjs';
import { GetPatientWrapperQuery, Notification } from '../../../../graphql/types/api.types';
import { SleepRecord } from './sleep-record.type';
import { DayJSService } from '../../dayJS/dayJS.service';

/**
 * A class that provides all the backend data needed by the Dashboard UI.
 */
export class DashboardData {
  readonly sleepRecords: SleepRecord[];
  readonly daysSinceDataWasReceived: number;
  readonly userFirstname: string;
  readonly lastSleepDataReportTime?: string;
  readonly timezoneId: string;
  readonly notifications: Notification[];
  readonly maskCode: string;
  readonly deviceSeries: string;
  readonly deviceFamily: string;

  /**
   * Returns an instance where:
   *
   * a) `this.sleepRecords` holds a maximum of 30 sleep records, ordered by 'startDate' in descending order.  If there
   *     are missing sleep records between the latest sleep record supplied and {@param today}, will fill in those
   *     missing days with zero usage records.  {@param today} is intended to be at the start of today,
   *     in the patient's timezone.  Populated in part by the sleep records contained in {@param queryResults}.
   * b) `this.daysSinceDataWasReceived` is the number of days between {@param today} and the startDate of the most
   *     recent sleep record supplied.
   * c) `this.userFirstName` extracted from {@param queryResults}.
   * d) 'this.lastSleepDataReportTime` extracted from {@param queryResults}.
   *
   * @param queryResults - The GraphQL results of a GetPatient query.
   * @param today - at the start of today, in the patient's timezone.
   */
  constructor(queryResults: GetPatientWrapperQuery, today: Dayjs, dayJsService: DayJSService) {
    const sleepRecords = queryResults.getPatientWrapper.sleepRecords.items.map(
      (it) => new SleepRecord(it),
    );

    this.daysSinceDataWasReceived =
      sleepRecords.length === 0
        ? 0
        : today.diff(dayJsService.dayJS(sleepRecords[sleepRecords.length - 1].startDate), 'day');
    this.lastSleepDataReportTime =
      queryResults.getPatientWrapper.fgDevices?.length &&
      queryResults.getPatientWrapper.fgDevices[0].lastSleepDataReportTime;
    this.userFirstname = queryResults.getPatientWrapper.patient.firstName;

    // Start with the day after the most recent sleep record.
    let nextDay =
      sleepRecords.length === 0
        ? today
        : dayJsService.dayJS(sleepRecords[sleepRecords.length - 1].startDate).add(1, 'day');

    while (nextDay.isSameOrBefore(today)) {
      sleepRecords.push(SleepRecord.newMissingRecord(nextDay));
      nextDay = nextDay.add(1, 'day');
    }

    // Trim to most recent 30 days of data, then reverse it to make
    // sure its sorted in desc order by start date.
    this.sleepRecords = sleepRecords.slice(-30).reverse();
    
    // Set Timezone Id
    this.timezoneId = queryResults.getPatientWrapper.patient.timezoneId;

    // Set Notifications
    this.notifications = queryResults.getPatientWrapper.notifications;

    // Set Mask Code, Device Series, and Device Family
    this.maskCode = queryResults.getPatientWrapper.masks[0].maskCode;
    this.deviceFamily = queryResults.getPatientWrapper.fgDevices[0].deviceFamily;
    this.deviceSeries = queryResults.getPatientWrapper.fgDevices[0].deviceSeries;
  }
}
