import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PatientService } from 'src/app/services/patient/patient.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { BaseEditComponent } from '../../../base-view-edit-components/base-edit/base-edit.component';
import { ComponentState } from '../../../base-view-edit-components/component-state.enum';
import { MyPrivacySettings } from '../my-privacy.component';

@Component({
  selector: 'mpp-receive-additional-information-edit',
  templateUrl: './receive-additional-information-edit.component.html',
  styleUrls: ['./receive-additional-information-edit.component.scss'],
})
export class ReceiveAdditionalInformationEditComponent extends BaseEditComponent implements OnInit {
  @Input() privacySettings: MyPrivacySettings;
  @Output() additionalInfoChanged: EventEmitter<boolean> = new EventEmitter();
  form: UntypedFormGroup;

  constructor(
    private patientService: PatientService,
    private fb: UntypedFormBuilder,
    private spinner: SpinnerService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      receiveAdditionalInformation: [this.privacySettings.marketingOptIn],
    });
  }

  async save(): Promise<void> {
    const spinner = this.spinner.show();
    const marketingOptIn = this.form.get('receiveAdditionalInformation').value;
    await this.patientService.setPrivacySettings({ marketingOptIn }).toPromise();
    this.stateChanged.next(ComponentState.View);
    this.additionalInfoChanged.next(marketingOptIn);
    spinner.hide();
  }
}
