<div class="js-acc-panel is-active">
  <div class="c-acc-panel c-acc-panel--edit">

    <p>{{ 'MY-ACCOUNT/ACCOUNT-DETAILS.YourEmailSuccessfullyChanged.YourEmailWasChanged' | translate}}</p>
    <p>{{ 'MY-ACCOUNT/ACCOUNT-DETAILS.YourEmailSuccessfullyChanged.MyAirHasSentEmailTo' | translate}}<strong>{{email}}</strong>
      {{ 'MY-ACCOUNT/ACCOUNT-DETAILS.YourEmailSuccessfullyChanged.WithInstructions' | translate}}</p>
    <p class="u-margin-bottom-large">{{ 'MY-ACCOUNT/ACCOUNT-DETAILS.YourEmailSuccessfullyChanged.SignInUsing' | translate}}<strong>{{email}}</strong>{{ 'MY-ACCOUNT/ACCOUNT-DETAILS.YourEmailSuccessfullyChanged.EndOfSignInUsing' | translate}}</p>

    <button class="c-btn c-btn--primary" (click)="save()">{{ 'MY-ACCOUNT/ACCOUNT-DETAILS.Ok' | translate}}</button>

  </div>
</div>
