 
<section class="o-section">
  <div class="o-wrapper">
    <div class="o-layout">
      <div class="o-layout__item [ u-10/12@desktop u-push-1/12@desktop ]">
        <form mppFocusOnInvalidControl [formGroup]="registrationForm" (ngSubmit)="onSubmit()">
        <mpp-global-legal-consents
        [form]="registrationForm.get('patientLegalConsents')"
        [formSubmitted]="formSubmitted"
        [formSubmitted]="formSubmitted" 
        [requireAnalyticsMode]="this.requireAnalyticsMode"
        [marketingConsentDefault]="this.marketingConsentDefault"
        [requireProcessHealthDataOptIn]="this.requireProcessHealthDataOptIn"
        [parentComponent]="this.parentComponent"></mpp-global-legal-consents>

        <div class="c-form-field c-form-field--buttons c-form-field--buttons-wizard">
        <div class="c-form-field--buttons-wizard__next">
          <button class="c-btn c-btn--primary" type="submit">
            {{"PTA.PtaStepperDone" | translate}}
          </button>
        </div>
        <div class="c-form-field--buttons-wizard__back">
          <button class="c-btn c-btn--tertiary" (click)="onCancel()">
            {{"REGISTRATION-FORM.Cancel" | translate}}
          </button>
        </div>
        </div>
        </form>
        </div>
    </div>
  </div>
</section>