<div id="videoOverlay" class="c-video c-video--overlay" [attr.data-state]="dataState">
  <div class="c-video__table">
    <div class="c-video__pos">
      <div class="c-video__wrapper">
        <a (click)="stopVideo()" id="videoClose" data-toggle="videoOverlay" data-playback="pauseVideo" class="c-video__close"><svg
            class="c-svg c-svg--clear-circle-fill-24">
            <use xlink:href="#c-svg--clear-circle-fill-24"></use>
          </svg></a>
        <div class="c-video__panel" style="display: block;" #videoPanel width="960" height="550">
          <div class="fit-video" ID="divVideo" Visible="False">
            <div class="fluid-width-video-wrapper" style="padding-bottom: 56.25%;">
              <iframe #videoContent id="videoContent"  width="936" height="526.5"
                [src]="videoUrl"
                frameborder="0" allowfullscreen webkitallowfullscreen mozallowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
