import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '@auth/auth.service';
import { BaseComponent } from 'src/app/components/base/component/base-component';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/models/user.model';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PatientService } from 'src/app/services/patient/patient.service';
import { DOCUMENT } from '@angular/common';
import { DownloadSleepReportDialogComponent } from '../dialogs/download-sleep-report-dialog/download-sleep-report-dialog.component';
import { CountryService } from 'src/app/services/country/country.service';

@Component({
  selector: 'mpp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends BaseComponent implements OnInit {
  isAuthenticationSet = false;
  isAuthenticated = false;
  user: User = null;
  isMenuOpen = false;
  isNotSupportedCountry: boolean = true;

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private analyticsService: AnalyticsService,
    private spinnerService: SpinnerService,
    private patientService: PatientService,
    @Inject(DOCUMENT) private document: Document,
    private countryService: CountryService
  ) {
    super();
  }
  ngOnInit(): void {
    this.authService.isAuthenticated$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isAuthenticated) => {
        if (isAuthenticated !== undefined) {
          this.isAuthenticationSet = true;
        }
        this.isAuthenticated = isAuthenticated;
      });
    this.authService.authenticatedUser$.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      this.user = user;
    });

    this.patientService.patient$.pipe(takeUntil(this.unsubscribe$)).subscribe((patient) => {
      this.user = { ...this.user, ...patient };
    });

    this.countryService.loadSupportedCountries().subscribe(res => this.isNotSupportedCountry = !res.includes(this.countryService.getSelectedCountryId()));
  }
  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  openSleepTherapyReportDialog(): void {
    if (this.user) {
      this.dialog.open(DownloadSleepReportDialogComponent, { data: this.user.FullName });
    }
  }

  async logOut(): Promise<void> {
    const spinner = this.spinnerService.show();
    this.analyticsService.logLoggedOutEvent();
    await this.authService.logout();
    spinner.hide();
  }

  scrollToMainContent(skipBtn: HTMLElement): void {
    const el = this.document.getElementById('main');
    el?.scrollIntoView(true);
    skipBtn.blur();
  }
}
