<a href="http://www.resmed.com" target="_blank" title="Res Med" class="site-nav__home-link" id="header_resmed-logo">
  <div class="site-nav__logo">
    <span class="c-logo c-logo--resmed">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 274.6 198.8">
        <title>Res Med</title>
        <path
          class="st0"
          d="M46.9 171.2c-0.8 1.5-1.9 2.8-3.3 3.9 -1.6 1.2-3.3 1.9-7.1 3.3l8 19.5H31.2l-6.5-17.6h-8.6l-4
          17.6H0l11.1-49.1h21.6c1 0 2 0 3.1 0.1 1.4 0.1 4.6 0.4 7.8 2.6 2.2 1.5 5.4 4.8 5.4 11.5C49 164.5 48.8 167.9 46.9
          171.2M35.8 159.9c-1.3-1.6-2.7-2.2-6.2-2.2h-8.3l-3.1 13.4h10.3c0.3 0 1.5-0.1 2.6-0.3 2.2-0.5 3.5-1.6 4.6-3.5 1-1.8 1-3.3
          1-4.1C36.8 162.8 36.8 161.2 35.8 159.9"
        ></path>
        <path
          class="st0"
          d="M113.3 173.5c-1-1.5-1.3-1.9-2-2.5 -1.7-1.6-4.5-2.3-6.7-2.3 -3 0-4.6 1.3-4.6 2.8 0 1.9 2.5 2.6 5.6 3.5 1.5 0.4
          3 0.8 4.5 1.4 3.6 1.3 7.2 3.1 8.4 6.7 0.5 1.3 0.5 2.8 0.5 3.2 0 4.2-2.8 7.2-3.8 8.1 -2.3 2.2-6.7 4.7-14 4.7
          -2.6 0-9.9-0.1-15.2-6.3 -0.8-0.9-1.2-1.4-2.2-3.3l9-4.4c0.6 1.1 1 1.7 1.3 2.1 1 1.2 3.5 3.3 7.9 3.3 0.6 0 1.9-0.1
          3.5-0.6 0.8-0.3 1.5-0.6 1.9-1.3 0.1-0.2 0.4-0.7 0.4-1.3 0-0.3-0.1-0.8-0.4-1.3 -0.8-1.2-2.4-1.6-4.9-2.4
          -7.1-2.2-10.1-3.3-12.3-6.7 -0.4-0.7-1.4-2.5-1.4-4.7 0-1 0.2-3 1.6-5.1 1.9-3.1 6.3-6.2 14.3-6.2 8.3 0
          12.4 3.1 14.7 5.8 0.6 0.8 1.1 1.4 2 3L113.3 173.5z"
        ></path>
        <polygon
          class="st0"
          points="173.2 197.9 161.9 197.9 170.6 159.9 152.3 197.9 142 197.9 140.6 159.4
          132.2 197.9 120.7 197.9 131.7 148.8 149.8 148.8 150.7 181 165.9 148.8 184.3 148.8 "
        ></polygon>
        <path
          class="st0"
          d="M252 197.9h-10.7l0.5-2.5c-0.6 0.4-1.1 0.8-1.7 1.2 -1.3 0.8-3.9 2.3-7.9 2.3 -4.1 0-6.5-1.7-7.7-2.7
          -2.3-1.9-5.1-5.4-5.1-12.4 0-2.4 0.4-8.3 4-14 4.9-7.6 11.6-8.8 15-8.8 1.3 0 4.8 0.1 7.8 2.4 1.2 0.9 2
          2.1 2.3 2.5l3.8-17 10.8 0L252 197.9zM245.5 173.4c-0.9-2.6-3.2-4.2-5.9-4.1 -1.9 0.1-3.3 1-3.8 1.3 -4
          2.6-5.7 8.4-5.7 12.9 0 2.4 0.6 3.8 1.2 4.8 1.1 1.7 2.7 2.4 4.7 2.4 1.7 0 4.6-0.6 7-4.1 1.8-2.7 2.8-7.1 2.8-10.2C245.9
          175.3 245.8 174.4 245.5 173.4"
        ></path>
        <path
          class="st0"
          d="M84.2 181.9c0.1-1.5 0.3-3.1 0.3-4.7 0-0.8 0-3.2-0.7-5.6 -0.5-1.9-1.4-3.5-2.6-5.1 -4.3-5.4-10.9-5.6-13.1-5.6
          -1.8 0-6.5 0.2-10.7 2.9 -4.5 2.8-7.8 7.9-9.1 13.1 -0.6 2.2-0.6 4-0.6 4.9 0 6.9 3.3 13.2 9.7 15.8 3.2 1.3 6.5 1.4
          7.7 1.4 8.8 0 13.2-4.1 15.4-6.9 0.6-0.8 1.2-1.7 1.8-2.6l-8.3-4.1c-0.6 0.9-1.1 1.8-1.8 2.5 -1.7 1.7-4.2 2.8-6.7 2.8
          -0.8 0-2.3 0-4-1 -3.8-2.1-3.3-6.8-3.2-7.8H84.2zM62.8 170.3c0.8-0.7 2.4-2 5.3-2 4 0 5.9 2.6 6.5 4.1 0.3 1 0.3 2.5 0.3
          2.7H59.6C60.9 172.3 61.6 171.5 62.8 170.3"
        ></path>
        <polygon class="st0" points="82.2 189.4 82.2 189.4 82.2 189.4 "></polygon>
        <path
          class="st0"
          d="M216.9 181.9c0.1-1.5 0.3-3.1 0.3-4.7 0-0.8 0-3.2-0.7-5.6 -0.5-1.9-1.4-3.5-2.6-5.1 -4.3-5.4-10.9-5.6-13.1-5.6
          -1.8 0-6.5 0.2-10.7 2.9 -4.5 2.8-7.8 7.9-9.1 13.1 -0.6 2.2-0.6 4-0.6 4.9 0 6.9 3.3 13.2 9.7 15.8 3.2 1.3 6.5 1.4
          7.7 1.4 8.8 0 13.2-4.1 15.4-6.9 0.6-0.8 1.2-1.7 1.8-2.6l-8.3-4.1c-0.6 0.9-1.1 1.8-1.8 2.5 -1.7 1.7-4.2 2.8-6.7 2.8
          -0.8 0-2.3 0-4-1 -3.8-2.1-3.3-6.8-3.2-7.8H216.9zM195.5 170.3c0.8-0.7 2.4-2 5.3-2 4 0 5.9 2.6 6.5 4.1 0.3 1 0.3 2.5
          0.3 2.7h-15.3C193.6 172.3 194.3 171.5 195.5 170.3"
        ></path>
        <polygon class="st0" points="214.9 189.4 214.9 189.4 214.9 189.4 "></polygon>
        <path
          class="st1"
          d="M274.6 79.4c-0.2-5-4.5-8.9-9.5-8.6 -5 0.2-8.9 4.5-8.6 9.5 0.2 5 4.5 8.9 9.5 8.6C270.9 88.7 274.8 84.5 274.6 79.4"
        ></path>
        <path
          class="st2"
          d="M259 96.1c-0.2-4.5-4-8-8.6-7.8 -4.5 0.2-8 4-7.8 8.6 0.2 4.5 4 8 8.6 7.8C255.7 104.4 259.2 100.6 259 96.1"
        ></path>
        <path
          class="st3"
          d="M241.8 108.3c-0.2-4.1-3.6-7.2-7.7-7 -4.1 0.2-7.2 3.6-7 7.7 0.2 4.1 3.6 7.2 7.7 7C238.9 115.8 242 112.4 241.8 108.3"
        ></path>
        <path
          class="st4"
          d="M223.6 115.3c-0.2-3.7-3.3-6.5-6.9-6.3 -3.7 0.2-6.5 3.3-6.3 6.9 0.2 3.7 3.3 6.5 6.9 6.3C221 122 223.8 118.9 223.6 115.3"
        ></path>
        <path
          class="st5"
          d="M205.6 116.4c-0.2-3.3-3-5.8-6.2-5.7 -3.3 0.2-5.8 3-5.7 6.2 0.2 3.3 2.9 5.8 6.2 5.7C203.3 122.5 205.8 119.7 205.6 116.4"
        ></path>
        <path
          class="st6"
          d="M189.6 111.7c-0.1-3-2.7-5.2-5.6-5.1 -3 0.1-5.2 2.7-5.1 5.6 0.1 3 2.7 5.3 5.6 5.1C187.5 117.2 189.8 114.6 189.6 111.7"
        ></path>
        <path
          class="st7"
          d="M176.7 103.2c-0.1-2.7-2.4-4.7-5-4.6 -2.7 0.1-4.7 2.4-4.6 5.1 0.1 2.7 2.4 4.7 5.1 4.6C174.8 108.1 176.8 105.9 176.7 103.2"
        ></path>
        <path
          class="st8"
          d="M166.6 93c-0.1-2.4-2.2-4.2-4.5-4.1 -2.4 0.1-4.2 2.2-4.1 4.5 0.1 2.4 2.1 4.2 4.5 4.1C164.9 97.5 166.7 95.4 166.6 93"
        ></path>
        <path
          class="st9"
          d="M158.6 82.3c-0.1-2.2-1.9-3.8-4.1-3.7 -2.2 0.1-3.8 1.9-3.7 4.1 0.1 2.2 1.9 3.8 4.1 3.7C157.1 86.3 158.7 84.5 158.6 82.3"
        ></path>
        <path
          class="st10"
          d="M152.3 71.6c-0.1-1.9-1.7-3.4-3.7-3.3 -1.9 0.1-3.4 1.7-3.4 3.7 0.1 1.9 1.7 3.4 3.7 3.4C150.9 75.2 152.4 73.5 152.3 71.6"
        ></path>
        <path
          class="st11"
          d="M147.5 61.1c0-1.8-1.4-3.2-3.2-3.2 -1.8 0-3.2 1.4-3.2 3.2 0 1.8 1.4 3.2 3.2 3.2C146 64.3 147.5 62.9 147.5 61.1"
        ></path>
        <path
          class="st12"
          d="M0 62.7c0 5.6 4.6 10.2 10.2 10.2 5.6 0 10.2-4.6 10.2-10.2 0-5.6-4.6-10.2-10.2-10.2C4.6 52.5 0 57.1 0 62.7"
        ></path>
        <path
          class="st13"
          d="M14 42.7c0.2 5 4.5 8.9 9.5 8.6 5-0.2 8.9-4.5 8.6-9.5 -0.2-5-4.5-8.9-9.5-8.6C17.6 33.5 13.8 37.7 14 42.7"
        ></path>
        <path
          class="st14"
          d="M29.6 26.1c0.2 4.5 4 8 8.6 7.8 4.5-0.2 8-4 7.8-8.6 -0.2-4.5-4-8-8.6-7.8C32.8 17.8 29.4 21.6 29.6 26.1"
        ></path>
        <path
          class="st15"
          d="M46.7 13.9c0.2 4.1 3.6 7.2 7.7 7 4.1-0.2 7.2-3.6 7-7.7 -0.2-4.1-3.6-7.2-7.7-7C49.6 6.4 46.5 9.8 46.7 13.9"
        ></path>
        <path
          class="st16"
          d="M64.9 6.9c0.2 3.6 3.3 6.5 6.9 6.3 3.7-0.2 6.5-3.3 6.3-6.9C78 2.7 74.9-0.2 71.2 0 67.5 0.2 64.7 3.3 64.9 6.9"
        ></path>
        <path
          class="st17"
          d="M82.9 5.8c0.2 3.3 3 5.8 6.2 5.7 3.3-0.2 5.8-2.9 5.7-6.2 -0.2-3.3-2.9-5.8-6.2-5.7C85.3-0.3 82.8 2.5 82.9 5.8"
        ></path>
        <path
          class="st18"
          d="M98.9 10.5c0.1 3 2.7 5.2 5.6 5.1 3-0.1 5.2-2.7 5.1-5.6 -0.1-3-2.7-5.2-5.6-5.1C101.1 5 98.8 7.6 98.9 10.5"
        ></path>
        <path
          class="st19"
          d="M111.8 19c0.1 2.7 2.4 4.7 5 4.6 2.7-0.1 4.7-2.4 4.6-5.1 -0.1-2.7-2.4-4.7-5.1-4.6C113.8 14.1 111.7 16.3 111.8 19"
        ></path>
        <path
          class="st20"
          d="M121.9 29.2c0.1 2.4 2.2 4.2 4.6 4.1 2.4-0.1 4.2-2.1 4.1-4.5 -0.1-2.4-2.2-4.3-4.5-4.1C123.7 24.7 121.8 26.8 121.9 29.2"
        ></path>
        <path
          class="st21"
          d="M129.9 39.9c0.1 2.2 1.9 3.8 4.1 3.7 2.2-0.1 3.8-1.9 3.7-4.1 -0.1-2.2-1.9-3.8-4.1-3.7C131.5 35.9 129.8 37.7 129.9 39.9"
        ></path>
        <path
          class="st22"
          d="M136.2 50.6c0.1 1.9 1.7 3.4 3.7 3.4 1.9-0.1 3.4-1.7 3.3-3.7 -0.1-1.9-1.7-3.4-3.7-3.4C137.6 47 136.1 48.7 136.2 50.6"
        ></path>
      </svg>
    </span>
  </div>
</a>
