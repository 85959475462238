<div class="c-language-menu">
  @if (selectedCountry?.languages.length > 1) {
    <ul id="languageMenuList" class="c-language-menu__list">
      <li class="c-language-menu__item">

        <ul class="c-language-menu__sub-menu" [ngClass]="{'show-mobile-language': isLangMenuOpen}">
          <li class="c-language-menu__item u-text-align-right">
            <a (click)="toggleMenu()" class="c-language-menu__close"><svg class="c-svg c-svg--close-24">
              <use xlink:href="#c-svg--close-24"></use>
            </svg></a>
          </li>
          @for (languageId of getLanguageIds(); track languageId) {
            <li class="c-language-menu__item">
              <a class="c-language-menu__link"
                (click)="selectLanguage(languageId)"><span>{{ 'LANGUAGES.' + languageId | translate }}</span></a>
              </li>
            }
          </ul>
          <a (click)="toggleMenu()"
            class="c-language-menu__current c-icon--drop-down-arrow"><span>{{ 'LANGUAGES.' + selectedLanguageId | translate }}</span></a>
          </li>
        </ul>
      }

      @if (selectedCountry) {
        <a [title]="selectedCountry.name | translate" (click)="selectCountry()" class="c-language-menu__flag"><span
          [class]="selectedCountry.flagClass"
        [attr.data-country]="selectedCountry.name | translate">{{selectedCountry.name | translate}}</span></a>
      }
    </div>
