import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { AuthService } from '@auth/auth.service';
import { BaseComponent } from '../base/component/base-component';
import { environment } from 'src/environments/environment';
import { UserRegistrationService } from 'src/app/modules/registration/services/user-registration.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { takeUntil } from 'rxjs';
import { ErrorCodes } from 'src/app/services/showtime-api/response.structure';

@Component({
  selector: 'mpp-device-registration',
  templateUrl: './device-registration.component.html',
  styleUrls: ['./device-registration.component.scss'],
})
export class DeviceRegistrationComponent extends BaseComponent implements OnInit {
  public isEU = environment.isEU;
  public equipmentFormGroup: UntypedFormGroup;
  public errorWithDevice = false;
  public legallyAuthorized = new UntypedFormControl('', this.isEU ? Validators.requiredTrue : undefined);
  public deviceVerified = false;
  public deviceImagePath: string = null;
  public deviceText: string = null;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private spinnerService: SpinnerService,
    public authService: AuthService,
    private userRegistration: UserRegistrationService,
    private analyticsService: AnalyticsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.equipmentFormGroup = this.formBuilder.group({
      device: this.formBuilder.group({
        deviceSerialNumber: [null, [Validators.required, Validators.minLength(11)]],
        deviceNumber: [null, [Validators.required, Validators.minLength(3)]],
      }),
    });
    // If the form group is modified, reset the API-based validation
    this.equipmentFormGroup.statusChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(_ => {
        this.deviceVerified = false;
    });

    this.analyticsService.logScreenViewWeb('screen_machine_verification');
  }

  async onCancel(): Promise<void> {
    const spinner = this.spinnerService.show();
    this.analyticsService.logLoggedOutEvent();
    await this.authService.logout();
    spinner.hide();
  }

  async onStartOver(): Promise<void> {
    this.deviceVerified = false;
    this.equipmentFormGroup.reset();
  }  

  onSubmitDevice(): void {
    this.analyticsService.logConfirmMachineButtonTapped();
    if (this.equipmentFormGroup.valid) {
      // Set the device number and serial number to be consumed later
      this.userRegistration.deviceNumber = this.equipmentFormGroup.get('device.deviceNumber').value;
      this.userRegistration.serialNumber = this.equipmentFormGroup.get('device.deviceSerialNumber').value;
      this.router.navigate(['/account-registration']);
    }
  }

  onVerifyDevice(): void {
    if (this.equipmentFormGroup.valid) {
      const spinner = this.spinnerService.show();
      this.userRegistration
        .validateDevice(this.equipmentFormGroup.get('device.deviceSerialNumber').value,this.equipmentFormGroup.get('device.deviceNumber').value)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((response) => {
          if (response?.result?.errorCode) {
            this.equipmentFormGroup.get('device').setErrors({ [response?.result?.errorCode]: true });
            if (response.result.errorCode === ErrorCodes.tooManyRequests) {
              this.analyticsService.logMachineValidationExceeded();
            } else {
              this.analyticsService.logMachineValidationFailure();
            }
          } else {
            this.deviceImagePath = environment.endpoints.staticMyair + '/' + (response.entity as any).imagePath;
            this.deviceText = (response.entity as any).localizedName;
            this.deviceVerified = true;
            this.analyticsService.logMachineValidationSuccessful();
            this.analyticsService.logScreenViewWeb('screen_machine_verification_confirmation');
          }
          spinner.hide();
      });
    }
  }
}
