import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Alert, AlertType } from '../model/alert.model';

@Injectable({ providedIn: 'root' })
export class AlertService {
  constructor(private router: Router) {}
  alerts: Alert[] = [];

  defaultMessage = 'ERRORS.ApplicationError.ApplicationErrorOccured';
  defaultOptions: Partial<Alert> = {
    links: [
      {
        title: 'ERRORS.ApplicationError.MyAirSupport',
        callback: (): void => {
          this.router.navigate(['/support']);
        },
        textAfterLink: 'ERRORS.ApplicationError.ProblemContinues',
      },
    ],
  };
  public alerts$ = new BehaviorSubject<Alert[]>(null);

  info(
    message: string = this.defaultMessage,
    options: Record<string, unknown> = this.defaultOptions,
  ): void {
    this.alert(new Alert({ ...options, type: AlertType.Info, message }));
  }

  alert(alert: Alert): void {
    // We do not want duplicate alerts
    let hasAlert = false;
    this.alerts?.forEach((item: Alert) => {
      if (alert.message === item.message) {
        hasAlert = true;
      };
    });
    if (!hasAlert) {
      this.alerts.push(alert);
      this.alerts$.next(this.alerts);
    }
  }

  clear(): void {
    this.alerts = [];
    this.alerts$.next(this.alerts);
  }

  removeAlert(alert: Alert): void {
    this.alerts = this.alerts.filter((a) => a !== alert);
    this.alerts$.next(this.alerts);
  }

  removePatientMessagingAlert(): void {
    // If Patient Messaging backend removes the alert, we need the ability to remove it from the list of alerts
    this.alerts = this.alerts.filter((a) => !a.isPatientMessaging);
    this.alerts$.next(this.alerts);
  }

  checkIfPatientMessagingAndIdentical(alert: Alert): boolean {
    // Isolate the Patient Message alert then check to see if it exists and matches the alert passed in
    this.alerts = this.alerts.filter((a) => a.isPatientMessaging);
    return this.alerts.length === 1 && JSON.stringify(alert) === JSON.stringify(this.alerts[0]);
  }
}
