import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { map, takeUntil } from 'rxjs/operators';
import { PatientService } from 'src/app/services/patient/patient.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { AuthService } from '@auth/auth.service';
import { BaseComponent } from '../../../components/base/component/base-component';
import { environment } from 'src/environments/environment';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MaskMagnetsComponent } from '../../../components/dialogs/mask-magnets/mask-magnets.component';
import { MaskService } from 'src/app/modules/my-account/components/my-account/my-equipment/my-account-mask/mask.service';
import { MetadataService } from 'src/app/services/metadata/metadata.service';

@Component({
  selector: 'mpp-mask-registration',
  templateUrl: './mask-registration.component.html',
  styleUrls: ['./mask-registration.component.scss'],
})
export class MaskRegistrationComponent extends BaseComponent implements OnInit {
  public isEU = environment.isEU;
  public equipmentFormGroup: UntypedFormGroup;
  public errorWithDevice = false;
  public legallyAuthorized = new UntypedFormControl('', this.isEU ? Validators.requiredTrue : undefined);
  public showMaskMagneticEducationalMaterials: boolean = false;

  constructor(
    private patientService: PatientService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private spinnerService: SpinnerService,
    public authService: AuthService,
    private analyticsService: AnalyticsService,
    private dialog: MatDialog,
    private metadataService: MetadataService,
    private maskService: MaskService
  ) {
    super();
  }

  ngOnInit(): void {
    this.equipmentFormGroup = this.formBuilder.group({
      mask: this.formBuilder.group({
        maskType: ['', Validators.required],
        maskManufacturer: ['', Validators.required],
        maskModel: ['', Validators.required],
        hasMagnets: ['', Validators.required]
      }),
    });
    this.metadataService.getMetadataFromApi().pipe(takeUntil(this.unsubscribe$)).subscribe(metadata => {
      this.showMaskMagneticEducationalMaterials = metadata.countries.find(country => country.code === this.metadataService.getSelectedCountryId())?.showMaskMagneticEducationalMaterials;
    });

  }

  async onCancel(): Promise<void> {
    const spinner = this.spinnerService.show();
    this.analyticsService.logLoggedOutEvent();
    await this.authService.logout();
    spinner.hide();
  }

  onSubmit(): void {
    if (this.equipmentFormGroup.valid) {
      const spinner = this.spinnerService.show();
      this.registerMask().then(() => {
        spinner.hide();
        this.analyticsService.logCompletedMaskRegistrationEvent();
      });
    }
  }

  private registerMask(): Promise<void> {
    if (this.equipmentFormGroup.get('mask.hasMagnets').value && this.showMaskMagneticEducationalMaterials) {
      this.dialog.open(MaskMagnetsComponent);
    }
    return this.patientService
      .addMask({
        maskCode: this.equipmentFormGroup.get('mask.maskModel').value,
        maskManufacturerName: this.equipmentFormGroup.get('mask.maskManufacturer').value,
      })
      .pipe(
        map(([error]) => {
          if (error) {
            const errorCode = error.errorInfo?.errorCode as string;
            this.equipmentFormGroup.get('mask').setErrors({ [errorCode]: true });
          } else {
            // As far as I can tell, mask questions were never implemented
            // Submit all present mask answers from the form group
            let index = 0;
            while(this.equipmentFormGroup.get('mask.maskQuestion' + index)) {
              this.maskService.submitMaskAnswers({ data: [{ questionId: this.maskService.maskQuestions[index].questionId, answerId: this.equipmentFormGroup.get('mask.maskQuestion' + index).value }]}).pipe(takeUntil(this.unsubscribe$)).subscribe();
              index++;
            }
            this.router.navigate(['/dashboard']);
          }
        }),
      )
      .toPromise();
  }
}
