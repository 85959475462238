import { Injectable } from '@angular/core';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SpinnerComponent } from 'src/app/shared/components/spinner/spinner.component';
import { Spinner, InternalSpinner } from './internal-spinner';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private spinnerRef: InternalSpinner = null;
  private config: OverlayConfig = new OverlayConfig({
    scrollStrategy: this.overlay.scrollStrategies.block(),
    positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
    backdropClass: 'mpp-spinner-backdrop',
    hasBackdrop: true,
  });

  constructor(private overlay: Overlay) {}

  show(): Spinner {
    if (this.spinnerRef) {
      this.spinnerRef.add();
    } else {
      const overlayRef = this.overlay.create(this.config);
      const portal = new ComponentPortal(SpinnerComponent);
      overlayRef.attach(portal);

      this.spinnerRef = new InternalSpinner(overlayRef);
      this.spinnerRef.afterDisposed().subscribe(() => {
        this.spinnerRef = null;
      });
    }

    return this.spinnerRef;
  }
}
