import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AuthService } from '@auth/auth.service';
import { MetadataService } from '../metadata/metadata.service';

@Injectable({
  providedIn: 'root',
})
export class HomeGuard  {
  constructor(public authService: AuthService, public router: Router, private metadata: MetadataService) {}

  async canActivate(): Promise<boolean | UrlTree> {
    // Wait for API Metadata to load
    await this.metadata.waitForMetadataToLoad();
    if (await this.authService.checkAuthenticated()) {
      return this.router.createUrlTree(['/dashboard']);
    }
    return true;
  }
}
