/* eslint-disable max-classes-per-file */
import { GenderId } from 'src/app/modules/registration/data/gender.type';
import { AnalyticsMode } from 'src/graphql/types/api.types';
import { SleepTestId } from '../../data/sleep-test.type';
import { StartTherapyGroupId } from '../../data/start-therapy-group.type';

export class NameDetails {
  constructor(
    public givenName: string,
    public familyName: string,
    public furiganaGivenName: string,
    public furiganaFamilyName: string,
  ) {}
}

// Registration V2 payload (TherapyPatientRegistrationRequestPostBody)
export class PatientInfoV2 {
  public acceptTerms: boolean;
  public dateOfBirth: string;
  public email: string;
  public isoCountryCode: string;
  public locale: string;
  public familyName: string;
  public furiganaFamilyName?: string;
  public furiganaGivenName?: string;
  public givenName: string;
  public personGender: GenderId;
  public timeZoneId: string;
  public analyticsMode?: AnalyticsMode;
  public marketingOptIn: boolean;
  public processHealthDataOptIn?: boolean;
  public shareDetailsWithProviderOptIn: boolean;
  public sleepTestType?: SleepTestId;
  public startTherapyGroup?: StartTherapyGroupId;
  public userEnteredAhi?: number;
  public serialNumber?: string;
  public deviceNumber?: string;

  constructor (
    acceptTermsIn: boolean,
    dateOfBirthIn: string,
    emailIn: string,
    isoCountryCodeIn: string,
    localeIn: string,
    familyNameIn: string,
    givenNameIn: string,
    personGenderIn: GenderId,
    timeZoneIdIn: string,
    marketingOptInIn: boolean,
    shareDetailsWithProviderOptInIn: boolean,
    furiganaFamilyNameIn?: string,
    furiganaGivenNameIn?: string,
    analyticsModeIn?: AnalyticsMode,
    processHealthDataOptInIn?: boolean,
    serialNumberIn?: string,
    deviceNumberIn?: string,
    sleepTestTypeIn?: SleepTestId,
    startTherapyGroupIn?: StartTherapyGroupId,
    userEnteredAhiIn?: number,
  ) {
      this.acceptTerms = acceptTermsIn;
      this.dateOfBirth = dateOfBirthIn;
      this.email = emailIn;
      this.isoCountryCode = isoCountryCodeIn;
      this.locale = localeIn;
      this.familyName = familyNameIn;
      this.furiganaFamilyName = furiganaFamilyNameIn;
      this.furiganaGivenName = furiganaGivenNameIn;
      this.givenName = givenNameIn;
      this.personGender = personGenderIn;
      this.timeZoneId = timeZoneIdIn;
      this.analyticsMode = analyticsModeIn;
      this.marketingOptIn = marketingOptInIn;
      this.processHealthDataOptIn = processHealthDataOptInIn;
      this.shareDetailsWithProviderOptIn = shareDetailsWithProviderOptInIn;
      this.sleepTestType = sleepTestTypeIn;
      this.startTherapyGroup = startTherapyGroupIn;
      this.userEnteredAhi = userEnteredAhiIn;
      this.serialNumber = serialNumberIn;
      this.deviceNumber = deviceNumberIn;
  }
}

export class PatientInfoWithConsent {
  constructor(
    public marketingOptIn: boolean,
    public shareDetailsWithProviderOptIn: boolean,
    public analyticsMode: AnalyticsMode,
    public processHealthDataOptIn: boolean,
   ) {}
}
