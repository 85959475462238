<section class="o-section">
    <div class="o-wrapper">
      <div class="o-layout">
        <div class="o-layout__item [ u-10/12@desktop u-push-1/12@desktop ]">
          <div class="u-margin-bottom-large [ u-11/12@tablet u-7/12@desktop ]">
            <h1 class="c-heading-page">{{ 'REGISTRATION-FORM.onbarding_do_you_have_device' | translate }}</h1>
            <div class="c-form-field c-form-field--buttons">
                <form [formGroup]="optionsForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <div class="option-card" [class.selected]="optionsForm.get('option0')?.value" (click)="setValue('option0')">
                            <div class="option-text" data-cy="i-have-a-device">
                              {{ 'REGISTRATION-FORM.registration_flow_therapy_device_triage_have_device' | translate }}
                              <div class="subtext">{{ 'REGISTRATION-FORM.onboarding_only_available' | translate }}</div>
                            </div>
                            <svg class="c-svg c-svg--checkmark" *ngIf="optionsForm.get('option0')?.value">
                                <use xlink:href="#c-svg--checkmark"></use>
                            </svg>
                            <div class="checkmark-abscence" *ngIf="!optionsForm.get('option0')?.value"></div>
                        </div>

                        <div class="option-card" [class.selected]="optionsForm.get('option1')?.value" (click)="setValue('option1')">
                            <div class="option-text" data-cy="i-do-not-have-a-device">
                              {{ 'REGISTRATION-FORM.registration_flow_therapy_device_triage_do_not_have_device' | translate }}
                            </div>
                            <svg class="c-svg c-svg--checkmark" *ngIf="optionsForm.get('option1')?.value">
                                <use xlink:href="#c-svg--checkmark"></use>
                            </svg>
                            <div class="checkmark-abscence" *ngIf="!optionsForm.get('option1')?.value"></div>
                        </div>
                        @if (optionsForm.get('option2')) {
                          <div class="option-card" [class.selected]="optionsForm.get('option2')?.value" (click)="setValue('option2')">
                              <div class="option-text">
                                {{ 'REGISTRATION-FORM.onboarding_nightowl_device' | translate }}
                                <div class="subtext">
                                  {{ 'REGISTRATION-FORM.onboarding_nightowl_not_supported' | translate }}
                                </div>
                              </div>
                              <svg class="c-svg c-svg--checkmark" *ngIf="optionsForm.get('option2')?.value">
                                  <use xlink:href="#c-svg--checkmark"></use>
                              </svg>
                              <div class="checkmark-abscence" *ngIf="!optionsForm.get('option2')?.value"></div>
                          </div>
                        }
                        <div class="get-myair-mobile" *ngIf="optionsForm.get('option2')?.value">
                            <div class="get-myair-mobile">
                              {{ 'REGISTRATION-FORM.onboarding_get_myair_mobile' | translate }}
                            </div>
                            <div class="c-app-badges [ o-layout o-layout--auto o-layout--small ]">
                                <div class="o-layout__item">
                                  <a (click)="appStoreLinkClicked()"
                                    id="lnkAppStoreLinkApple" class="c-apple-badge"
                                    [style.background-image]="languageService.selectedAppStoreImage"
                                    [href]="countryService.appStoreLink$ | async"
                                    title="{{'HOME/VIDEO-CAROUSEL.DownloadAppStore' | translate}}"
                                    target="_blank">{{'HOME/VIDEO-CAROUSEL.DownloadAppStore' | translate}}</a>
                                </div>
                                <div class="o-layout__item">
                                  <a (click)="playStoreLinkClicked()"
                                    id="lnkAppStoreLinkAndroid" class="c-android-badge"
                                    [style.background-image]="languageService.selectedPlayStoreImage"
                                    [href]="countryService.playStoreLink$ | async"
                                    title="{{'HOME/VIDEO-CAROUSEL.GetGooglePlay' | translate}}"
                                    target="_blank">{{'HOME/VIDEO-CAROUSEL.GetGooglePlay' | translate}}</a>
                                </div>
                              </div>
                        </div>
                    </div>
                    <div class="buttons">
                        <div class="c-form-field--buttons-wizard__next">
                            <button class="c-btn c-btn--primary" type="submit" [disabled]="isSubmitDisabled()">
                            {{"PTA.PtaStepperNext" | translate}}
                            </button>
                        </div>
                        <div class="c-form-field--buttons-wizard__back">
                            <button class="c-btn c-btn--tertiary" [routerLink]="['/']">
                            {{"REGISTRATION-FORM.Cancel" | translate}}
                            </button>
                        </div>
                    </div>
                </form>
                </div>
          </div>
        </div>
      </div>
    </div>
</section>
