import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { PrivacyNoticeComponent } from './privacy-notice/privacy-notice.component';
import { CookieNoticeComponent } from './cookie-notice/cookie-notice.component';
import { PoliciesRoutingModule } from './policies-routing.module';
import { ConsumerHealthDataComponent } from './consumer-health-data/consumer-health-data.component';

@NgModule({
  imports: [CommonModule, PoliciesRoutingModule],
  declarations: [TermsOfUseComponent, PrivacyNoticeComponent, CookieNoticeComponent, ConsumerHealthDataComponent],
  exports: [TermsOfUseComponent, PrivacyNoticeComponent, CookieNoticeComponent, ConsumerHealthDataComponent],
})
export class PoliciesModule {}
