import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MyAirInterceptor } from 'src/app/services/showtime-api/interceptors/myair.interceptor';
import { AngularFireAnalyticsModule, DEBUG_MODE } from '@angular/fire/compat/analytics';
import { AngularFireModule } from '@angular/fire/compat';
import { SharedModule, ModuleHttpLoaderFactory } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { MainComponent } from './components/main/main.component';
import { SignInComponent } from './components/home/sign-in/sign-in.component';
import { environment } from '../environments/environment';
import { VideoCarouselComponent } from './components/home/video-carousel/video-carousel.component';
import { GetStartedComponent } from './components/home/get-started/get-started.component';
import { FeatureDescriptionComponent } from './components/home/feature-description/feature-description.component';
import { LanguageMenuComponent } from './components/header/language-menu/language-menu.component';
import { ResMedLabelComponent } from './components/header/res-med-label/res-med-label.component';
import { MyAirLabelComponent } from './components/header/my-air-label/my-air-label.component';
import { SvgSpriteComponent } from './components/svg-sprite/svg-sprite.component';
import { WelcomeVideoComponent } from './components/home/welcome-video/welcome-video.component';
import { MaterialModule } from './modules/material/material.module';
import { PoliciesModule } from './modules/policies/policies.module';
import { CountrySelectionComponent } from './components/country-selection/country-selection.component';
import { CountrySelectionFooterComponent } from './components/country-selection-footer/country-selection-footer.component';
import { HeaderNoNavComponent } from './components/header-no-nav/header-no-nav.component';
import { AlertModule } from './modules/alert/alert.module';
import { LegalDisclaimerComponent } from './components/dialogs/legal-disclaimer/legal-disclaimer.component';
import { DeviceRegistrationComponent } from './components/device-registration/device-registration.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ApolloSetupService } from './services/apollo-setup/apollo-setup.service';
import { ApolloModule } from 'apollo-angular'
import { MyAccountModule } from './modules/my-account/my-account.module';
import { DownloadSleepReportDialogComponent } from './components/dialogs/download-sleep-report-dialog/download-sleep-report-dialog.component';
import { AcceptPoliciesComponent } from './components/accept-policies/accept-policies.component';
import { AppOnlyCountryComponent } from './components/country-selection/app-only-country/app-only-country.component';
import { MaskMagnetsComponent } from './components/dialogs/mask-magnets/mask-magnets.component';
import { PtaStepperComponent } from './components/pta-stepper/pta-stepper.component';
import { MatStepperModule } from '@angular/material/stepper';
import { PtaPromptModalComponent } from './components/pta-prompt-modal/pta-prompt-modal.component';
import { PtaCloseModalComponent } from './components/pta-close-modal/pta-close-modal.component';
import { MetadataService } from './services/metadata/metadata.service';
import { AddFlowDeviceComponent } from './components/add-flow-device/add-flow-device.component';
import { NotSupportedComponent } from './components/dialogs/not-supported/not-supported.component';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';

export const TranslateModuleForRoot = TranslateModule.forRoot({
  loader: {
    provide: TranslateLoader,
    useFactory: ModuleHttpLoaderFactory,
    deps: [HttpClient],
  },
  defaultLanguage: 'en',
  isolate: false,
});

// const config = {
//   clientId: '0oa3d9872mbA6QEY80x7',
//   issuer: 'https://resmed-airview-ps-eu.oktapreview.com/oauth2/aus3dca9lbJaW9bxP0x7',
//   redirectUri: 'https://myair-web-dev.rmdeu.live',
//   pkce: true
// }

@NgModule({
  declarations: [
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    MainComponent,
    SignInComponent,
    VideoCarouselComponent,
    GetStartedComponent,
    FeatureDescriptionComponent,
    LanguageMenuComponent,
    LegalDisclaimerComponent,
    ResMedLabelComponent,
    MyAirLabelComponent,
    SvgSpriteComponent,
    WelcomeVideoComponent,
    CountrySelectionComponent,
    CountrySelectionFooterComponent,
    HeaderNoNavComponent,
    DeviceRegistrationComponent,
    AddFlowDeviceComponent,
    ForgotPasswordComponent,
    DownloadSleepReportDialogComponent,
    AcceptPoliciesComponent,
    AppOnlyCountryComponent,
    MaskMagnetsComponent,
    PtaStepperComponent,
    PtaPromptModalComponent,
    PtaCloseModalComponent,
    NotSupportedComponent
  ],
  imports: [
    ApolloModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    MaterialModule,
    PoliciesModule,
    AlertModule,
    ReactiveFormsModule,
    TranslateModuleForRoot,
    SharedModule,
    MyAccountModule,
    MatStepperModule,
    MatLegacyDialogModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyAirInterceptor,
      multi: true,
    },
    { provide: DEBUG_MODE, useValue: environment.firebase.debugMode },
  ],
  bootstrap: [MainComponent],
})
export class AppModule {
  private apolloCreated: boolean = false;

  constructor(private apolloSetupService: ApolloSetupService, private metadata: MetadataService) {
    this.metadata.metadataApiSubject.asObservable().subscribe((res) => {
      if (res && !this.apolloCreated) {
        this.apolloSetupService.setupApolloClient();
        this.apolloCreated = true;
      }
    });
  }
}