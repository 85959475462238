import { Inject, Injectable, InjectionToken } from '@angular/core';

export const WINDOW_OBJECT = new InjectionToken<Window>('Window Object', {
  providedIn: 'root',
  factory: () => window,
});

@Injectable({
  providedIn: 'root',
})
export class WindowService {
  constructor(@Inject(WINDOW_OBJECT) public window: Window) {}

  get pageYOffset(): number {
    return this.window.pageYOffset;
  }

  requestAnimationFrame(callback: FrameRequestCallback): number {
    return this.window.requestAnimationFrame(callback);
  }

  scrollTo(x: number, y: number): void {
    return this.window.scrollTo(x, y);
  }

  fetch(info: RequestInfo, init?: RequestInit): Promise<Response> {
    return this.window.fetch(info, init);
  }
}
