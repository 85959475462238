<div class="c-notifications-wrapper" #notificationWrapper>

  @for (alert of alerts; track alert; let i = $index) {
    <div class="c-alert c-alert--warning js-toggle-data-state"
      role="alert"
      (animationend)="animationend(alert, $event)"
      [attr.data-state]="alert.isDismissed ? 'is-dismissed' : 'is-active'"
      >
      <div class="icon-wrapper">
        <object [id]="i" [data]="imageUrls[i]" type="image/svg+xml" width="24" height="24"></object>
      </div>
      <div class="c-alert__body">
        <p>
          <span [ngClass]="alert.isPatientMessaging ? 'main-message': ''">{{ alert.message | translate }}</span>@if (alert.isPatientMessaging) {
          <span class="main-message"> - </span>
        }
        @for (link of alert.links; track link) {
          <span>
            <a (click)="link.callback(alert)">{{ link.title | translate }}</a>
            @if (link.textAfterLink !== null) {
              <span>
              {{link.textAfterLink | translate}}</span>
            }
          </span>
        }
      </p>
    </div>
    @if (alert.isDismissable) {
      <div class="c-alert__close">
        <button (click)="onClose(alert)" class="c-icon-btn c-icon-btn--close" aria-label="Close">
          <svg class="c-svg c-svg--close-24">
            <use xlink:href="#c-svg--close-24"></use>
          </svg>
        </button>
      </div>
    }
  </div>
}
</div>
