import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { CountryService } from 'src/app/services/country/country.service';

@Component({
  selector: 'mpp-app-only-country',
  templateUrl: './app-only-country.component.html',
  styleUrls: ['./app-only-country.component.scss'],
})
export class AppOnlyCountryComponent implements OnInit {
  apacCountries$: Observable<any>;

  isApac$: Observable<boolean>;

  isKorea: boolean = false;

  isLuxembourg: boolean = false;

  constructor(public countryService: CountryService, private analyticsService: AnalyticsService) {}

  ngOnInit(): void {

    // Check if app-only country is APAC
    this.isApac$ = this.countryService.loadAPACCountries().pipe(
      map(apacCountries => apacCountries.includes(this.countryService.getSelectedCountryId()))
    );

    // get and set appStoreLink$ and playStoreLink$
    this.countryService.initializeMobileStoreLinks();

    this.isKorea = this.countryService.getSelectedCountryId() === 'KR';

    this.isLuxembourg = this.countryService.getSelectedCountryId() === 'LU';

  }

  playStoreLinkClicked(): void {
    this.analyticsService.logPlayStoreRedirect(this.countryService.getSelectedCountryId(), this.analyticsService.redirectLocationAppOnly);
  }

  appStoreLinkClicked(): void {
    this.analyticsService.logAppStoreRedirect(this.countryService.getSelectedCountryId(), this.analyticsService.redirectLocationAppOnly);
  }
}
