<section class="o-section">
  <div class="o-wrapper">
    <div class="o-layout">
      <div class="o-layout__item  [ u-10/12@desktop u-push-1/12@desktop ]">
        <h1 class="c-heading-page">{{ 'MY-ACCOUNT/MY-HEALTH-PROFILE.MyHealthProfile' | translate}}</h1>

        <p>{{ 'MY-ACCOUNT/MY-HEALTH-PROFILE.IfYouAnswerFollowingQuestions' | translate}}</p>

        <div>
          <mpp-add-health-profile [healthProfileForm]="healthProfileForm"></mpp-add-health-profile>

          <div class="c-form-buttons [ o-layout o-layout--auto ]">
            <div class="o-layout__item">
              <button class="c-btn c-btn--primary" [disabled]="healthProfileForm.invalid" (click)="updateHealthProfile()">{{ 'MY-ACCOUNT/MY-HEALTH-PROFILE.Save' | translate}}</button>
            </div>
            <div class="o-layout__item">
              <a id="health-profile_my-account_link" class="c-btn c-btn--tertiary" [routerLink]="['/my-account']">{{ 'MY-ACCOUNT/MY-HEALTH-PROFILE.Cancel' | translate}}</a>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>
