import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { PatientService } from 'src/app/services/patient/patient.service';
import { FgDevice } from 'src/app/services/patient/types/device.type';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { map, takeUntil } from 'rxjs/operators';
import { ErrorTypes } from 'src/app/services/showtime-api/response.structure';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/components/base/component/base-component';
import { DeviceService } from './services/device.service';
import { MyDeviceReturningPath } from './types/device-return-path.enum';
import { environment } from 'src/environments/environment';
import { Apollo } from 'apollo-angular';
import { PtaService } from 'src/app/services/pta.service';

@Component({
  selector: 'mpp-my-account-device',
  templateUrl: './my-account-device.component.html',
  styleUrls: ['./my-account-device.component.scss'],
})
export class MyAccountDeviceComponent extends BaseComponent implements OnInit {
  public isEU = environment.isEU;
  public deviceFormGroup = this.formBuilder.group({
    deviceSerialNumber: [null, [Validators.required, Validators.minLength(11)]],
    deviceNumber: [null, [Validators.required, Validators.minLength(3)]],
  });

  public legallyAuthorized = new UntypedFormControl('', this.isEU ? Validators.requiredTrue : undefined);

  private currentDevice: FgDevice;
  private return: MyDeviceReturningPath;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private patientService: PatientService,
    private spinnerService: SpinnerService,
    private deviceService: DeviceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private client: Apollo,
    private pta: PtaService,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe(async (params) => {
      this.return = params.return;
    });
    await this.setCurrentDevice();
  }

  getRouteBack(): string[] {
    if (Object.values(MyDeviceReturningPath).includes(this.return)) {
      return [this.return];
    }
    // redirect to my-account if return query isn't correct or present
    return [MyDeviceReturningPath.myaccount];
  }

  async goBack(showPTA: boolean): Promise<boolean> {
    if (showPTA) {
      const device = await this.patientService.getDevices().toPromise();
      this.pta.setMachineAssets(device[0].fgDevices[0].deviceFamily, device[0].fgDevices[0].deviceSeries);
      this.pta.notification = device[0].notifications ? device[0].notifications.filter(item => item.action === 'virtualCoach')[0] as any : null;
      if (this.pta.notification) {
        this.pta.displayMask = false;
        this.pta.displayMachine = true;
        this.pta.openPtaPrompt(true);
      }
    }
    return this.router.navigate(this.getRouteBack());
  }

  private async setCurrentDevice(): Promise<void> {
    const spinner = this.spinnerService.show();
    const [devices] = await this.patientService.getDevices().toPromise();
    if (devices) {
      [this.currentDevice] = devices.fgDevices;
    }
    spinner.hide();
  }

  public cancelDeviceChanges(): void {
    this.goBack(false);
  }

  public async updatePatientDevice(): Promise<void> {
    const spinner = this.spinnerService.show();
    const serialNumber = this.deviceFormGroup.get('deviceSerialNumber').value;
    const deviceNumber = this.deviceFormGroup.get('deviceNumber').value;
    const validationResult = await this.deviceService
      .validateDevice(serialNumber, deviceNumber)
      .toPromise();
    if (!validationResult.result.success) {
      if (validationResult.result.errorType === ErrorTypes.badRequest) {
        const errorCode = validationResult.result.errorCode as string;
        this.deviceFormGroup.setErrors({ [errorCode]: true });
      }
      spinner.hide();
      return;
    }
    this.patientService
      .removeDevice({ serialNumber: this.currentDevice.serialNumber })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([error]) => {
        if (error) {
          const errorCode = error.errorInfo?.errorCode as string;
          this.deviceFormGroup.setErrors({ [errorCode]: true });
          spinner.hide();
        } else {
          this.addDevice().then(() => {
            spinner.hide();
          });
        }
      });
  }

  private addDevice(): Promise<void> {
    return this.patientService
      .addDevice({
        deviceNumber: this.deviceFormGroup.get('deviceNumber').value,
        serialNumber: this.deviceFormGroup.get('deviceSerialNumber').value
      })
      .pipe(
        map(([error]) => {
          if (error) {
            const errorCode = error.errorInfo?.errorCode as string;
            this.deviceFormGroup.setErrors({ [errorCode]: true });
          } else {
            this.client.client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'getPatientWrapper', });
            this.client.client.cache.gc();
            this.goBack(true);
          }
        }),
      )
      .toPromise();
  }
}
