<div class="u-margin-bottom-large">

  <div class="c-acc-panel c-acc-panel--header no-btn">
    <div class="c-acc-panel__item">
      <div class="c-acc-panel__heading">
        <h2 id="myequipment" class="c-heading-acc">{{ 'MY-ACCOUNT/MY-EQUIPMENT.MyEquipment' | translate}}</h2>
      </div>
    </div>
  </div>

  <div class="visible">
    <div class="c-acc-panel c-acc-panel--read c-acc-panel--media">
      <div class="c-acc-panel__item" id="my-account-device">

        <div class="c-acc-panel__img">
          <img [src]="getDeviceImagePath()" [alt]="device.localizedName">
        </div>
        <div class="c-acc-panel__body">
          <div class="c-acc-panel__item">
            <div class="c-acc-panel__btn  no-label">
              <a id="my-equipment_my-account-device_link" class="c-link-ui"
                [routerLink]="['/my-account/my-account-device']">{{ 'MY-ACCOUNT.Change' | translate}}</a>
            </div>
          </div>
          <div class="c-acc-panel__item">
            <div class="c-acc-panel__label">
              {{ 'MY-ACCOUNT/MY-EQUIPMENT.MadeBy' | translate}}
            </div>
            <div class="c-acc-panel__value">
              <span>{{device.fgDeviceManufacturerName}}</span>
            </div>
          </div>
          <div class="c-acc-panel__item">
            <div class="c-acc-panel__label">
              {{ 'MY-ACCOUNT/MY-EQUIPMENT.Model' | translate}}
            </div>
            <div class="c-acc-panel__value">
              <span>{{device.localizedName}}</span>
            </div>
          </div>
          <div class="c-acc-panel__item">
            <div class="c-acc-panel__label">
              {{ 'MY-ACCOUNT/MY-EQUIPMENT.SerialNumber' | translate}}
            </div>
            <div class="c-acc-panel__value">
              <span>{{device.serialNumber}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="c-acc-panel c-acc-panel--read c-acc-panel--media">
    <div class="c-acc-panel__item" id="my-account-mask">
      <div class="c-acc-panel__img">
        <img [src]="getMaskImagePath()">
      </div>
      <div class="c-acc-panel__body">
        <div class="c-acc-panel__item">
          <div class="c-acc-panel__btn no-label">
            <a id="my-equipment_my-account-mask_link" class="c-link-ui"
              [routerLink]="['/my-account/my-account-mask']">{{ 'MY-ACCOUNT.Change' | translate}}</a>
          </div>
        </div>
        <div class="c-acc-panel__item">
          <div class="c-acc-panel__label">
            {{ 'MY-ACCOUNT/MY-EQUIPMENT.MadeBy' | translate}}
          </div>
          <div class="c-acc-panel__value">
            <span>{{mask.maskManufacturerName}}</span>
          </div>
        </div>
        <div class="c-acc-panel__item">
          <div class="c-acc-panel__label">
            {{ 'MY-ACCOUNT/MY-EQUIPMENT.Model' | translate}}
          </div>
          <div class="c-acc-panel__value">
            <span>{{mask.localizedName}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
