// This needs to be referenced via web-config.json. Bad to duplicate this as it was also out of date (albeit not affecting anything critical)
const SUPPORTED_COUNTRIES = [
    "CA", "AU", "US", "NZ", // AMR
    "JP", "TW", "TH", // APAC
    "BR", "MX", "AR", "CL", "CO", //LATAM
    "GB", "IE", "DE", "FI", "CH", "NL", "SE", "NO", "PL", "ES", "DK", "BE", "FR", "PT", "AT", "CZ", "GR", "IT", "CY", "ZA", "IS", "MT" // EU
];

export function getSupportedCountries(): string[] {
  return SUPPORTED_COUNTRIES;
}