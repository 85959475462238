import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { LanguageService } from 'src/app/services/language/language.service';
import { environment } from 'src/environments/environment';
import { SleepReportService } from 'src/app/services/sleep-report/sleep-report.service';
import { FileSaverService } from 'src/app/services/file-saver/file-saver.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { CultureLanguage2LetterId, languages2Letters } from 'src/app/types/language.type';
import { ResponseResultEntity } from 'src/app/services/showtime-api/response.structure';

@Component({
  selector: 'mpp-download-sleep-report-dialog',
  templateUrl: './download-sleep-report-dialog.component.html',
  styleUrls: ['./download-sleep-report-dialog.component.scss'],
})
export class DownloadSleepReportDialogComponent {
  responseError = false;
  constructor(
    private dialogRef: MatDialogRef<DownloadSleepReportDialogComponent>,
    private languageService: LanguageService,
    private sleepReportService: SleepReportService,
    private fileSaver: FileSaverService,
    private spinnerService: SpinnerService,
    @Inject(MAT_DIALOG_DATA) public userFullName: string,
  ) {}

  async downloadReport(): Promise<void> {
    const spinner = this.spinnerService.show();
    const response = await this.sleepReportService.getTherapyReport().toPromise();
    if (!response.result.success) {
      this.responseError = true;
      spinner.hide();
      return;
    }
    const blob = await this.fileSaver.base64toBlob((response.entity as ResponseResultEntity).content, 'application/pdf');
    this.fileSaver.saveAs(blob, `myAir-${this.userFullName}.pdf`);
    spinner.hide();
    this.dialogRef.close();
  }

  cancel(): void {
    this.dialogRef.close();
  }

  getReportGuideReference(): string {
    let current2LettersLanguage = this.languageService.getCurrent2LettersLanguage();
    if (current2LettersLanguage === languages2Letters.FrenchCanadian) {
      // Guide is available in both FR and FR-CA, so make distinction here
      current2LettersLanguage = current2LettersLanguage.toLowerCase() as CultureLanguage2LetterId;
    } else if (current2LettersLanguage === languages2Letters.ChineseTraditional || current2LettersLanguage === languages2Letters.Greek) {
      // Link fix for Traditional Chinese and Greek (new translations for this document no longer supported by Tech Comms) 
      return `${environment.endpoints.staticMyair}/Web%20Assets/docs/en/myAirReportGuide.pdf`;
    } else {
      // If language has a suffix but guide is only supported in the prefix (such as es-ES but we only support es), trim the suffix here. All non-suffixed languages end up here as well
      current2LettersLanguage = current2LettersLanguage.substr(0, 2) as CultureLanguage2LetterId;
    }

    return `${environment.endpoints.staticMyair}/Web%20Assets/docs/${current2LettersLanguage}/myAirReportGuide.pdf`;
  }
}
