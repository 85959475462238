<form [formGroup]="form" (ngSubmit)="save()" style="display: block;">
  <div class="js-acc-panel is-active">
    <div class="c-acc-panel c-acc-panel--edit">
      <div class="c-form-field">
        <label class="c-form-field__label" for="new-email-address">
          <span>{{ 'MY-ACCOUNT/ACCOUNT-DETAILS.NewEmailAddress' | translate}}</span>
        </label>
        <div class="c-input--validation" data-form-field="signup-email" [attr.data-form-field-status]="form.get('email').invalid && form.get('email').touched ? 'error' :
                                                                                                       form.get('email').touched && form.get('email').valid ? 'completed' : 'incompleted'">
          <input id="new-email-address" formControlName="email" type="text" [value]="email" [maxlength]="emailMaxLength" class="c-input c-input--text"
            style="cursor: auto;">
        </div>
        @if (form.get('email').invalid && form.get('email').touched) {
          <div>
            @if (form.get('email').errors?.pattern) {
              <div class="c-form-field__error" role="alert" aria-live="assertive">
              {{"ERRORS.IncorrectEmailFormat" | translate}}</div>
            }
            @if (form.get('email').errors?.required) {
              <div class="c-form-field__error" role="alert" aria-live="assertive">{{"ERRORS.Required" | translate}}
              </div>
            }
            @if (form.get('email').errors?.emailInUse) {
              <div class="c-form-field__error" role="alert" aria-live="assertive">
                {{"ERRORS.EmailInUse.AccountAlreadyExists" | translate}}
                <br>
                  <a [routerLink]="['/']">{{"ERRORS.EmailInUse.SignIn" | translate}}</a>
                  {{"ERRORS.EmailInUse.Or" | translate}}
                  <a [routerLink]="['/forgot-password']">{{"ERRORS.EmailInUse.ForgotPassword" | translate}}</a>
                </div>
              }
            </div>
          }
        </div>


        <div>

          <div class="c-form-field">
            <label class="c-form-field__label" for="confirm-email-address">
              <span>{{ 'MY-ACCOUNT/ACCOUNT-DETAILS.ConfirmEmail' | translate}}</span>
            </label>
          <div class="c-input--validation" data-form-field="signup-confirmemail" [attr.data-form-field-status]="(form.errors?.emailNotConfirmed && form.get('confirmEmail').touched) ||
                                                                                                                (form.get('confirmEmail').invalid && form.get('confirmEmail').touched) ? 'error'
                                                                                                                : form.get('confirmEmail').touched && form.get('confirmEmail').valid
                                                                                                                ? 'completed' : 'incompleted'">
              <input id="confirm-email-address" formControlName="confirmEmail" type="text" [maxlength]="emailMaxLength" class="c-input c-input--text">
            </div>
            @if (form.invalid && form.get('confirmEmail').touched) {
              <div>
                @if (form.errors?.emailNotConfirmed && !form.get('confirmEmail').errors?.required) {
                  <div class="c-form-field__error" role="alert" aria-live="assertive">
                  {{"ERRORS.ConfirmEmailDoesNotMatchEmail" | translate}}</div>
                }
                @if (form.get('confirmEmail').errors?.required) {
                  <div class="c-form-field__error" role="alert" aria-live="assertive">
                  {{"ERRORS.Required" | translate}}</div>
                }
              </div>
            }
            <div class="c-form-field__hint">
              {{ 'MY-ACCOUNT/ACCOUNT-DETAILS.ChangeYourSignInDetails' | translate}}
            </div>
          </div>

        </div>

        <div class="c-form-buttons  [ o-layout o-layout--auto ]">
          <div class="o-layout__item">
            <button type="submit" class="c-btn c-btn--primary"
            [disabled]="form.invalid">{{ 'MY-ACCOUNT/ACCOUNT-DETAILS.Save' | translate}}</button>
          </div>
          <div class="o-layout__item">
            <a class="c-btn c-btn--tertiary" (click)="cancel()">{{ 'MY-ACCOUNT/ACCOUNT-DETAILS.Cancel' | translate}}</a>
          </div>
        </div>
      </div>
    </div>
  </form>
