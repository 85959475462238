<div id="modal-SleepReport" class="c-modal-material c-modal--confirm">
  <div class="c-modal__header">
    <h2 class="c-modal__heading has-icon"><svg class="c-svg c-svg--doc-pdf-24">
      <use xlink:href="#c-svg--doc-pdf-24"></use>
    </svg>{{ 'DIALOGS/DOWNLOAD-SLEEP-REPORT.DownloadSleepTherapyReport' | translate}}</h2>
  </div>
  <div class="c-modal__body">
    @if (!responseError) {
      <div>
        <p>{{ 'DIALOGS/DOWNLOAD-SLEEP-REPORT.DownloadAndShareReport' | translate}} <a [href]="getReportGuideReference()" class="c-link-ui" target="_blank">{{ 'DIALOGS/DOWNLOAD-SLEEP-REPORT.DownloadReportGuide' | translate}}</a>.</p>
      </div>
    }
    @if (responseError) {
      <div>
        <p class="u-color--secondary-red u-font-weight-bold">{{ 'ERRORS.DownloadFailed' | translate}}</p>
      </div>
    }

  </div>
  <div class="c-modal__footer">
    <div class="o-layout o-layout--auto@tablet">
      @if (!responseError) {
        <div class="o-layout__item">
          <button (click)="downloadReport()"
          class="c-modal__warnConfirm-btn [ c-btn c-btn--primary ]">{{ 'DIALOGS/DOWNLOAD-SLEEP-REPORT.DownloadMyReport' | translate}}</button>
        </div>
      }
      @if (responseError) {
        <div class="o-layout__item">
          <button (click)="responseError = false"
          class="c-modal__warnConfirm-btn [ c-btn c-btn--primary ]">{{ 'DIALOGS/DOWNLOAD-SLEEP-REPORT.TryAgain' | translate}}</button>
        </div>
      }
      <div class="o-layout__item">
        <button (click)="cancel()"
        class="c-modal__warnCancel-btn [ c-btn c-btn--tertiary ]">{{ 'DIALOGS/DOWNLOAD-SLEEP-REPORT.Cancel' | translate}}</button>
      </div>
    </div>
  </div>
</div>
