import { Component, Input } from '@angular/core';
import { BaseViewComponent } from 'src/app/modules/my-account/components/base-view-edit-components/base-view/base-view.component';
import { MyPrivacySettings } from '../my-privacy.component';

@Component({
  selector: 'mpp-allow-personalized-coaching-view',
  templateUrl: './allow-personalized-coaching-view.component.html',
  styleUrls: ['./allow-personalized-coaching-view.component.scss']
})
export class AllowPersonalizedCoachingViewComponent extends BaseViewComponent {
  @Input() privacySettings: MyPrivacySettings;
}
