import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmDeletionComponent } from './dialogs/confirm-deletion/confirm-deletion.component';
import { PermanentlyDeleteComponent } from './dialogs/permanently-delete/permanently-delete.component';

@Component({
  selector: 'mpp-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss'],
})
export class DeleteAccountComponent {
  isEditMode = false;
  isError = false;
  constructor(
    private dialog: MatDialog
  ) {}

  openEdit(): void {
    this.isEditMode = true;
  }

  cancelEdit(): void {
    this.isEditMode = false;
  }

  closeError(): void {
    this.isError = false;
  }

  openConfirmDeletion(): void {
    const dialogRef = this.dialog.open(ConfirmDeletionComponent, { disableClose: true });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.openPermanentlyDelete();
        return;
      }
      this.isEditMode = false;
    });
  }

  openPermanentlyDelete(): void {
    const dialogRef = this.dialog.open(PermanentlyDeleteComponent, { disableClose: true });
    dialogRef.afterClosed().subscribe((data) => {
      this.isEditMode = false;
      this.isError = data;
    });
  }

  
}
