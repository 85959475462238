<a [routerLink]="['/']" class="site-nav__home-link" id="header_myair-logo" title="my Air">
  <div class="site-nav__logo">
    <span class="c-logo c-logo--myair">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 672.3 259"
      >
        <title>my Air</title>
        <path
          class="st0"
          d="M165.9,199.9v-79.3c0-16.5-1.4-24.2-4.7-30.3c-4.1-7.4-11.3-11.8-22.3-11.8c-11.3,0-22,5.3-28.9,14.3
          c-8.3,10.5-10.7,22.3-10.7,40.4v66.6H83.1v-78.4c0-10.2-0.3-20.6-3.3-28.6c-3.6-9.6-11.3-14.3-23.7-14.3c-8.8,0-21.7,4.4-30.3,16.8
          c-6.9,9.7-9.6,20.4-9.6,37.4v67.1H0V65.6h16.2v23.9c9.2-16.1,
          26.3-26,44.9-25.9c20.1,0,31.6,9.9,36,27c9.7-18.2,27.3-26.7,46.5-26.7
          c18.2,0,31.9,7.7,36.6,26.4c1.4,5.8,1.9,15.1,1.9,25.3v84.2L165.9,199.9z"
        ></path>
        <path
          class="st0"
          d="M193.3,65.6h18.2c30.3,84.5,36.3,102.6,42.1,120c6.6-19.8,10.5-32.5,41-120h17.9l-55,149.4
          c-5.5,14.6-9.1,23.9-16,32.5c-7.2,8.8-16,11.6-27,11.6c-4.7,0-9.3-0.8-13.8-2.2v-14.6c4.2,1.6,8.7,2.4,13.2,2.5
          c11.8,0,17.1-1.6,30.5-41.3L193.3,65.6z"
        ></path>
        <path
          class="st0"
          d="M446.9,199.9l-15.4-45.4h-76.8L339,199.9h-37.7L375,5.6h38.8l71,194.3L446.9,199.9z M422.9,127
          c-11-31.6-20.4-58.1-29.4-86.4c-6.3,19.5-13.2,38.8-30.3,86.4H422.9z"
        ></path>
        <path
          class="st0"
          d="M496.1,35.9V5.6h32.7v30.3H496.1z M528.8,199.9h-32.5v-137h32.5L528.8,199.9z"
        ></path>
        <path
          class="st0"
          d="M553.3,199.9v-137h30.8V84c8.3-14.9,19-22.8,38-22.8c4,0,8,0.6,11.8,1.7v32.5c-4.7-2.7-10-4-15.4-3.8
          c-10.4-0.2-20.2,4.9-25.9,13.5c-4.7,6.9-7.2,16.5-7.2,31.1v63.8L553.3,199.9z"
        ></path>
        <path
          class="st0"
          d="M625.4,28.5V4.4h-8.3V0h21.3v4.4h-8.3v24.1H625.4z M667.6,28.5V14c0-3.1,0.1-6.1,0.2-9.1
          c-1,3.2-1.8,5.8-2.7,8.5l-5.3,15.2h-4.5l-5-14.2c-1-2.8-1.8-5-3.1-8.9c0.2,3.8,0.2,6,0.2,8.9v14.2h-4.7V0h7.2l5.1,13.9
          c0.7,1.7,1.6,4.8,2.8,8.2c1.1-3,2-6,2.8-8.4L665.1,0h7.3v28.5H667.6z"
        ></path>
      </svg>
    </span>
  </div>
</a>
