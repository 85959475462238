import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'mpp-serial-number-hint',
  templateUrl: './serial-number-hint.component.html',
  styleUrls: ['./serial-number-hint.component.scss'],
})
export class SerialNumberHintComponent {
  constructor(private dialogRef: MatDialogRef<SerialNumberHintComponent>) {}

  close(): void {
    this.dialogRef.close();
  }
}
