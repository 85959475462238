import { HttpErrorResponse } from '@angular/common/http';
import { OperatorFunction, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ResponseResult, ErrorTypes } from 'src/app/services/showtime-api/response.structure';
import { Alert } from 'src/app/modules/alert/components/model/alert.model';
import { environment } from 'src/environments/environment';
import { AlertService } from '../../modules/alert/components/services/alert.service';

export interface ShowErrorInput {
  disableAlert?: boolean;
  errorMessage?: string;
  alertOptions?: Partial<Alert>;
}

export abstract class ShowTimeApiService {
  constructor(protected messageService: AlertService) {}

  // Designed for Observables
  public showError(input?: ShowErrorInput): OperatorFunction<ResponseResult, ResponseResult> {
    const { errorMessage, alertOptions, disableAlert } = input ?? {};
    return catchError((response: HttpErrorResponse) => {
      if ((response.error as ResponseResult)?.result) {
        const errorType = (response.error as ResponseResult)?.result.errorType;
        if (errorType === ErrorTypes.internalServerError) {
          if (!disableAlert) {
            this.messageService.info(errorMessage, alertOptions);
          }
          if (!environment.production) {
            // eslint-disable-next-line no-console
            console.error(response);
          }
        }
        return of(response.error as ResponseResult);
      }
      if (!disableAlert) {
        this.messageService.info(errorMessage, alertOptions);
      }
      if (!environment.production) {
        // eslint-disable-next-line no-console
        console.error(response);
      }
      return of({ result: { success: false, errorType: ErrorTypes.httpError } } as ResponseResult);
    });
  }

  // Designed for Promises
  public async showErrorAsync(error, input?: ShowErrorInput): Promise<void> {
    const { errorMessage, alertOptions, disableAlert } = input ?? {};
    if (error?.result) {
      const errorType = error.result.errorType;
      if (errorType === ErrorTypes.internalServerError) {
        if (!disableAlert) {
          this.messageService.info(errorMessage, alertOptions);
        }
        if (!environment.production) {
          console.error(error);
        }
      }
    } else {
      if (!disableAlert) {
        this.messageService.info(errorMessage, alertOptions);
      }
      if (!environment.production) {
        console.error(error);
      }
    }
  }  

  
}
