import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AlertService } from 'src/app/modules/alert/components/services/alert.service';
import { Router } from '@angular/router';
import { GraphQLError } from 'src/app/services/apollo/graphql-payload.type';
import { ApolloService } from 'src/app/services/apollo/apollo.service';
import { ClearNotificationInput, GetPatientWrapperQuery } from '../../../graphql/types/api.types';
import { DayJSService } from '../dayJS/dayJS.service';
import { DashboardData } from './types/dashboard-data.type';

@Injectable({
  providedIn: 'root',
})
export class SleepRecordService {
  constructor(
    protected http: HttpClient,
    protected messageService: AlertService,
    protected router: Router,
    private apolloService: ApolloService,
    private dayJs: DayJSService,
  ) {}

  getDashboardData(): Observable<[DashboardData, GraphQLError]> {
    const today = this.dayJs.dayJS().startOf('day').subtract(1, 'day');
    const endMonth = today.format('YYYY-MM-DD');
    const startMonth = today.subtract(29, 'day').format('YYYY-MM-DD');

    const query = /* GraphQL */ `
      query GetPatientSleepRecords {
        getPatientWrapper {
          patient {
            id
            firstName
            timezoneId
          }
          masks {
            maskCode
          }
          fgDevices {
            deviceSeries
            deviceFamily
            lastSleepDataReportTime
          }
          notifications {
            id
            action
            title
            description
            positiveButtonTitle
            negativeButtonTitle
            url
            negativeActionId
          }
          sleepRecords(startMonth: "${startMonth}", endMonth: "${endMonth}") {
            items {
              startDate
              totalUsage
              sleepScore
              usageScore
              ahiScore
              maskScore
              leakScore
              ahi
              maskPairCount
              leakPercentile
              sleepRecordPatientId
            }
          }
        }
      }
    `;

    return this.apolloService
      .query<GetPatientWrapperQuery>({
        query: gql(query),
      })
      .pipe(
        map(([patientResult, error]) => {
          if (error) {
            return [null, error];
          }
          return [new DashboardData(patientResult, today, this.dayJs), null];
        }),
      );
  }

  clearNotification(notificationToClear: ClearNotificationInput): Observable<[GraphQLError, Notification[]]> {
    return this.apolloService.mutate<Notification[]>({
      mutation: gql(/* GraphQL */ `
      mutation ClearNotifications($input: [ClearNotificationInput!]!) {
        clearNotifications(input: $input) {
          id
          action
          title
          description
          positiveButtonTitle
          negativeButtonTitle
          url
          negativeActionId
          negativeActionContent {
            title
            description
            positiveButtonTitle
            negativeButtonTitle
            __typename
          }
          achievement {
            id
            name
            description
            imageUrl
            earnedDate
            __typename
          }
          __typename
        }
      }
    `),
      variables: { input: notificationToClear },
    }).pipe(
      map(([error, notification]) => {
        if (error) {
          return [error, null];
        }
        return [null, notification];
      }),
    );
  }
}