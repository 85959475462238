import { Component, Input } from '@angular/core';
import { AccountUpdateService } from 'src/app/modules/my-account/services/account-update.service';
import { MyAccountDataService } from 'src/app/modules/my-account/services/my-account-data.service';
import { BaseEditComponent } from '../../../base-view-edit-components/base-edit/base-edit.component';
import { ComponentState } from '../../../base-view-edit-components/component-state.enum';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DataEmailSentComponent } from './dialogs/data-email-sent/data-email-sent.component';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
@Component({
  selector: 'mpp-download-my-data-edit',
  templateUrl: './download-my-data-edit.component.html',
  styleUrls: ['./download-my-data-edit.component.scss'],
})
export class DownloadMyDataEditComponent extends BaseEditComponent {
  @Input() email: string;

  constructor(
    private accountUpdateService: AccountUpdateService,
    public myAccountDataService: MyAccountDataService,
    private dialog: MatDialog,
    public analyticsService: AnalyticsService,
    private spinnerService: SpinnerService
  ) {
    super();
  }

  // DATA DOWNLOAD EMAIL
  save(): void | Promise<void> {
    const spinner = this.spinnerService.show();
    this.analyticsService.logDownloadMyDataRequestedEvent();
    this.accountUpdateService.downloadMyDataV2().subscribe((data) => {
      spinner.hide();
      if (data.result.success) {
        this.openDataEmailSentDialog();
        this.analyticsService.logScreenViewWeb('screen_download_my_data_success');
      } else {
          // Re-enable download buttons if request fails
          this.myAccountDataService.dataIsDownloaded.next(false);
          this.analyticsService.logDownloadMyDataRequestFailureEvent();
      }
      this.stateChanged.next(ComponentState.View);
    });
  }

  openDataEmailSentDialog(): void {
      this.dialog.open(DataEmailSentComponent, {
        disableClose: true,
      });
  }
}
