import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { AlertComponent } from './components/alert/alert.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [AlertComponent],
  exports: [AlertComponent],
})
export class AlertModule {}
