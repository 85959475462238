import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  LanguageId,
  languages,
  CultureLanguage2LetterId,
  languages2Letters,
} from 'src/app/types/language.type';
import { LocalStorageService } from '../storage/storage.service';
import { environment } from 'src/environments/environment';
import { PatientMessagingService } from 'src/app/modules/alert/components/services/patient-messaging.service';
import { CountryService } from '../country/country.service';
import { CountryData, CountryId } from 'src/app/types/country.type';
import { first } from 'rxjs/operators';

const playStoreBadgePath: string = 'assets/images/badges/play-store/';
const appStoreBadgePath: string = 'assets/images/badges/app-store/';
@Injectable({ providedIn: 'root' })
export class LanguageService {
  private LanguageIdKey = 'CurrentMyAirLanguageId';
  public isEU = environment.isEU;

  selectedLanguageId$ = new BehaviorSubject<LanguageId>('EnglishUs');
  selectedLanguageId: LanguageId;
  languageByBrowser: LanguageId | undefined;
  selectedPlayStoreImage: string = '';
  selectedAppStoreImage: string = '';
  defaultLanguageSelect: LanguageId;

  constructor(private localStorage: LocalStorageService,
              private patientMessagingService: PatientMessagingService,
              private countryService: CountryService) {
    this.languageByBrowser = this.getLanguageByBrowser();
  }

  selectLanguage(languageId: LanguageId, from?: string): void {
    const previouslySelected = this.selectedLanguageId;
    this.setBadgeLinks(languageId);
    this.selectedLanguageId = languageId;
    this.localStorage.setItem(this.LanguageIdKey, languageId);
    this.selectedLanguageId$.next(languageId);
    // We must grab a new Patient Messaging entry if the language changes
    if (previouslySelected && from !== 'countrySelectionComponent') {
      this.patientMessagingService.getPatientMessaging(this.selectedLanguageId);
    }
  }

  setLanguageInPreferredOrder(countryId: CountryId, from?: string): void {
    this.countryService.countries$.pipe(
      first(),
    ).subscribe(countries => {
      const countryLanguages = countries[countryId].languages;
      const [firstLanguageInList] = countryLanguages;
      const currentLanguage = this.selectedLanguageId;
      const browserLanguage = countryLanguages.find((lang) => this.languageByBrowser === lang);
  
      // Upon country selection, if the country is not an App-only country, first the Language Service attempts to obtain the browser language, then attempts to obtain the language stored in local storage, then grabs the first language in the list as a last resort
      this.selectLanguage(
        browserLanguage ||
        (countryLanguages.includes(currentLanguage) && currentLanguage) ||
        firstLanguageInList, from
      );
    });
  }

  // restore language on add init
  // set a default language of EnglishUs or EnglishUk if initial languageId checks fail
  checkAndSetLanguageId(defaultLanguageSelect?): void {
    if (typeof window !== undefined) {
      const currentMyAirLanguageId = this.localStorage.getItem(this.LanguageIdKey) as LanguageId;

      if (currentMyAirLanguageId && languages[currentMyAirLanguageId]) {
        this.selectedLanguageId = currentMyAirLanguageId;
        this.selectedLanguageId$.next(currentMyAirLanguageId);
        this.setBadgeLinks(currentMyAirLanguageId);
        return;
      }
      // Improvement: We need to check that languageByBrowser exists in this country
      this.selectLanguage(this.languageByBrowser || defaultLanguageSelect);
      return;
    }
    this.selectLanguage(defaultLanguageSelect);
  }

  // When user has language set in their browser, automatically select this language in the language dropdown
  getLanguageByBrowser(): LanguageId | null {
    let languageId: string[];


    if (typeof window !== undefined) {
      let browserCultureLanguage = navigator.language as CultureLanguage2LetterId;
      
      /* - MWEB-1011 -
      Chrome supports 'pt-PT', 'pt-BR', and 'pt' as language choices.
      Firefox supports 'pt-PT' and 'pt-BR'.
      Safari supports 'pt-PT' and 'pt-BR'.
      No browsers support Portugual Macau.
      */

      // The following if statements allow us to properly set the languageId which derives from languages2Letters (which Hyperdrive defines). This allows us to auto select language by browser even if Hyperdrive language codes for RMDLanguageHeader don't match the browser's language code.

      if (browserCultureLanguage === 'pt-PT') {
        browserCultureLanguage = 'pt';
      }

      if (
          browserCultureLanguage === 'es-US' || 
          browserCultureLanguage === 'es-419'|| 
          browserCultureLanguage === 'es-MX' || 
          browserCultureLanguage === 'es-AR' || 
          browserCultureLanguage === 'es-CL' || 
          browserCultureLanguage === 'es-CO' ) {
          browserCultureLanguage = 'es';
      }

      if (browserCultureLanguage === 'zh-TW') {
        browserCultureLanguage = 'zh-Hant';
      }

      // Primary check to find a match between Hyperdrive allowed languages and the user's browser language
      languageId = Object.entries(languages2Letters).find(
        (lang) => lang[1] === browserCultureLanguage
      );

      // Secondary check to find a match between Hyperdrive allowed languages and the user's browser language (this one trims the ends off of two part language codes)
      // This secondary check doesn't play nicely with languages that have the same first two letters such as pt-BR and pt-PT, es-es and es. Not a problem with current languages, but as we add new languages it may become one. Consider modifying or removing. 
      // These shouldn't set the languageId to a language which the current country does not have.
      if (!languageId) {
        languageId = Object.entries(languages2Letters).find(
          (lang) => lang[1].substr(0, 2) === browserCultureLanguage.substr(0, 2),
        );
      }
    }

    return languageId ? (languageId[0] as LanguageId) : null;
  }

  getCurrent2LettersLanguage(): CultureLanguage2LetterId {
    return languages2Letters[this.selectedLanguageId];
  }

  // after login we should check if language selected before login is
  // supported in country where user is registered
  checkAndSetCountryAvailableLanguage(currentSelectedCountry: CountryData): void {
    const currentMyAirLanguageId = this.localStorage.getItem(this.LanguageIdKey) as LanguageId;
    const [defaultLanguageForCountry] = currentSelectedCountry.languages;
    if (
      currentMyAirLanguageId &&
      currentSelectedCountry.languages.includes(currentMyAirLanguageId)
    ) {
      this.selectedLanguageId = currentMyAirLanguageId;
      this.selectedLanguageId$.next(currentMyAirLanguageId);
      this.setBadgeLinks(currentMyAirLanguageId);
      return;
    }

    this.selectLanguage(defaultLanguageForCountry);
  }

  isJapaneseLanguage(): boolean {
    return this.getCurrent2LettersLanguage() === 'ja';
  }

  setBadgeLinks(languageId: string): void {
    // Set the badge for the Google Play and App Store based on language
    this.selectedPlayStoreImage = 'url(' + playStoreBadgePath + languageId + '.png)';
    this.selectedAppStoreImage = 'url(' + appStoreBadgePath + languageId + '.svg)';
  }
}