import { Injectable } from '@angular/core';
import { UrlTree, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@auth/auth.service';
import { PatientService } from '../patient/patient.service';
import { HeadersService } from '../showtime-api/headers.service';
import { SpinnerService } from '../spinner/spinner.service';
import { MetadataService } from '../metadata/metadata.service';

@Injectable({
  providedIn: 'root',
})
export class DeviceRegisteredGuard  {
  constructor(
    private router: Router,
    private patientService: PatientService,
    private spinnerService: SpinnerService,
    private headersService: HeadersService,
    private authService: AuthService,
    private metadata: MetadataService
  ) {}

  canActivateChild(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> | boolean {
    return this.canActivate(route, state);
  }

  canLoad(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> | boolean {
    return this.canActivate(route, state);
  }

  async canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    // Wait for API Metadata to load
    await this.metadata.waitForMetadataToLoad();
    // Ensure the patient is authenticated and has the expected list of headers
    // before checking if they have a device assigned.
    if (!await this.authService.checkAuthenticated() || !this.headersService.isHeaderItemsAvailable()) {
      return false;
    }

    const spinner = this.spinnerService.show();
    return this.patientService
      .getDevices()
      .toPromise()
      .then(([devices, error]) => {
        spinner.hide();
        // Only go forward if the targetted URL isn't the onboarding flow AND we have registered device
        if (devices?.fgDevices) {
          return true;
        }
        // If we don't have a device, we should always be in a journey (safe assumption?)
        return this.onboardingErrorFlow(error, state.url);       
      })
      .catch((error) => {
        spinner.hide();
        //If we have any GraphQLErrors, then the call was successfully answered by AppSync but there was no device.
        error.graphQLErrors?.forEach(error => {
          if(error.errorInfo.errorCode === "onboardingFlowInProgress"){
            return this.onboardingErrorFlow(error, state.url);
          }
        });
        //If there was an error, but there are no graphQLErrors, then the call did not successfully reach AppSync (or returned an error).
        return false;
      });
  }

  public onboardingErrorFlow(error, state): any {
    // Prioritize consent info first, then registering device, then mask
    // Do not redirect if we are already on the right route
    if ((error.errorInfo.additionalInfo.onboardingFlow as any).additionalRegistrationRequired) {
      return (state === '/give-consent' ? true : this.router.createUrlTree(['give-consent']));
    } else if ((error.errorInfo.additionalInfo?.onboardingFlow as any)?.equipmentMissing?.some(v => "fgDevice".includes(v))) {
      return (state === '/register-device' ? true : this.router.createUrlTree(['register-device']));
    } else {
      return (state === '/mask-selection' ? true : this.router.createUrlTree(['mask-selection']));
    }
  }
}