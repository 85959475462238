export function getFirebaseConfig(isEU: boolean): { [key: string]: string | boolean } {
  if (!isEU) {
    return {
      apiKey: 'AIzaSyC5oPR5XIuqwv7mpRakRpDwbRVJvfb_mvU',
      authDomain: 'myair-stg.firebaseapp.com',
      databaseURL: 'https://myair-stg.firebaseio.com',
      projectId: 'myair-stg',
      storageBucket: 'myair-stg.appspot.com',
      messagingSenderId: '468499825007',
      appId: '1:468499825007:web:e3243c4266c0477da2e89a',
      measurementId: 'G-CLLT7E0BCV',
    };
  } else {
    return {
      apiKey: 'AIzaSyCBF_XiDu5U6iWGJrsJe04k01bFQ-zu0Gc',
      authDomain: 'myair-eu-stg.firebaseapp.com',
      databaseURL: 'https://myair-eu-stg.firebaseio.com',
      projectId: 'myair-eu-stg',
      storageBucket: 'myair-eu-stg.appspot.com',
      messagingSenderId: '183845397836',
      appId: '1:183845397836:web:7e7b9ca6c698ea0b59dbe9',
      measurementId: 'G-T4Y7K949FD',
    };
  }
}
