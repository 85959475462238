import { Component, OnInit, Input, ViewChild } from '@angular/core';
import {
  MaskTypeId,
  maskTypes,
  maskTypesSelected,
  maskTypesTranslated,
  maskTypeIds,
} from 'src/app/modules/my-account/components/my-account/my-equipment/my-account-mask/types/mask.type';
import {
  RootObject,
  Manufacturers,
} from 'src/app/modules/my-account/components/my-account/my-equipment/my-account-mask/models/mask.model';
import { environment } from 'src/environments/environment';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { takeUntil } from 'rxjs/operators';
import { BaseFormComponent } from 'src/app/components/base/component/base-form-component';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { Manufacturer } from '../types/manufacturer.enum';
import { MetadataService } from 'src/app/services/metadata/metadata.service';
import { MaskService } from '../mask.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MaskSettingDialogComponent } from '../mask-setting-dialog/mask-setting-dialog.component';

declare type MaskModelType = { imagePath: string; name: string; maskCode: string, containsMagnets?: boolean, maskSetupExtras?: string[] };
@Component({
  selector: 'mpp-add-mask',
  templateUrl: './add-mask.component.html',
  styleUrls: ['./add-mask.component.scss'],
})
export class AddMaskComponent extends BaseFormComponent implements OnInit {
  @Input() maskFormGroup: UntypedFormGroup;
  @ViewChild('slickCarousel') slickCarousel: SlickCarouselComponent;

  maskData: RootObject;
  // Flag that indicades that initial scroll started
  initialScroll = true;
  manufacturerNames: string[] = [];
  maskTypeIsSelected = false;
  displayResMedMasks = false;
  maskModelsList: MaskModelType[] = [];

  constructor(private metadataService: MetadataService,
    private spinner: SpinnerService,
    public maskService: MaskService,
    private languageService: LanguageService,
    private dialog: MatDialog
    ) {
    super();
  }

  /**
   * Options to show mask carousel
   */
  slickOptions = {
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: true,
    arrows: true,
    autoplay: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  async ngOnInit(): Promise<void> {
    const spinner = this.spinner.show();
    this.maskData = await this.metadataService.getMaskMetadata().toPromise();
    if (this.maskData) {
      (this.maskData.manufacturers as Manufacturers[])?.forEach((manufacturer) => {
        this.manufacturerNames.push(manufacturer.manufacturerName);
      });
    }
    this.maskModelsList = this.getMaskByManufacturerAndType();
    spinner.hide();

    this.maskFormGroup
      .get('maskManufacturer')
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.updateMaskModelsList();
      });

    this.maskFormGroup
      .get('maskType')
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.updateMaskModelsList();
      });

      this.setHasMagnetsByMaskModelAndMaskModelsList();
      this.setMaskQuestions();
      this.setPillowOptimization();
  }

  getCurrentMaskSlideId(): number {
    const [currentMask] = this.maskModelsList.filter(
      (mask) => mask.maskCode === this.maskFormGroup.get('maskModel').value,
    );
    return this.maskModelsList.indexOf(currentMask);
  }

  getMaskTypeIds(): readonly MaskTypeId[] {
    return maskTypeIds;
  }

  getManufacturerNames(): string[] {
    return this.manufacturerNames;
  }

  getMaskTypeImageSource(maskTypeId: MaskTypeId): string {
    return this.maskFormGroup.get('maskType').value === maskTypeId
      ? maskTypesSelected[maskTypeId]
      : maskTypes[maskTypeId];
  }

  getMaskTypeTranslation(maskTypeId: MaskTypeId): string {
    return maskTypesTranslated[maskTypeId];
  }

  onMaskTypeSelected(maskTypeId: MaskTypeId): void {
    this.maskFormGroup.get('maskType')?.setValue(maskTypeId);
    this.maskTypeIsSelected = true;
    this.removeMaskQuestions();
    this.removePillowOptimization();
  }

  onMaskModelSelected(maskModel: string): void {
    this.maskFormGroup.get('maskModel').setValue(maskModel);
    this.setHasMagnetsByMaskModelAndMaskModelsList();
    this.setMaskQuestions();
    this.setPillowOptimization();
  }

  setHasMagnetsByMaskModelAndMaskModelsList(): void {
    this.maskFormGroup.get('hasMagnets')?.setValue(this.maskModelsList.filter(
      (mask) => mask.maskCode === this.maskFormGroup.get('maskModel').value,
    )[0]?.containsMagnets);
  }
  
  setMaskQuestions(): void {
    if (this.maskModelsList.filter(
      (mask) => mask.maskCode === this.maskFormGroup.get('maskModel').value,
    )[0]?.maskSetupExtras?.length > 0 &&
    this.maskModelsList.filter(
      (mask) => mask.maskCode === this.maskFormGroup.get('maskModel').value,
    )[0]?.maskSetupExtras?.find(item => item === 'maskQuestions')) {
      this.maskService.getMaskQuestions(this.maskFormGroup.get('maskModel').value, this.languageService.getCurrent2LettersLanguage()).subscribe(res => {
        res[0].forEach((_, index) => this.maskFormGroup.addControl('maskQuestion'+ index, new FormControl('', Validators.required)));
        this.maskService.maskQuestions = res[0];
      });
    } else {
      this.removeMaskQuestions();
    }
  }

  removeMaskQuestions(): void {
    if (this.maskService.maskQuestions) {
      this.maskService.maskQuestions.forEach((_, index) => this.maskFormGroup.removeControl('maskQuestion'+ index));
      this.maskService.maskQuestions = null;
    }
  }

  setPillowOptimization(): void {
    if (this.maskModelsList.filter(
      (mask) => mask.maskCode === this.maskFormGroup.get('maskModel').value,
    )[0]?.maskSetupExtras?.length > 0 &&
    this.maskModelsList.filter(
      (mask) => mask.maskCode === this.maskFormGroup.get('maskModel').value,
    )[0]?.maskSetupExtras?.find(item => item === 'pillowConfigOptimization')) {
      this.maskService.hasPillowOptimization = true;
    } else {
      this.maskService.hasPillowOptimization = false;
    }
  }

  removePillowOptimization(): void {
    if (this.maskService.hasPillowOptimization) {
      this.maskService.hasPillowOptimization = false;
    }
  }

  openMaskSettingMp4(): void {
    this.maskService.maskSettingDialog = this.dialog.open(MaskSettingDialogComponent, { panelClass: 'dark-mode' });
  }

  getMaskByManufacturerAndType(): MaskModelType[] {
    const spinner = this.spinner.show();
    const maskInfo: MaskModelType[] = [];
    (this.maskData.manufacturers as Manufacturers[])
      ?.find(
        (manufacturer) =>
          manufacturer.manufacturerName === this.maskFormGroup.get('maskManufacturer').value,
      )
      ?.maskFamilies.filter(
        (maskFamily) => maskFamily.maskType === this.maskFormGroup.get('maskType').value,
      )
      .forEach((mask) => {
        mask.models.forEach((maskModel) => {
          maskInfo.push({
            imagePath: `${environment.endpoints.staticMyair}${maskModel.imagePath}`,
            name: maskModel.modelName,
            maskCode: maskModel.maskCode,
            containsMagnets: !!maskModel.containsMagnets,
            maskSetupExtras: maskModel.maskSetupExtras
          });
        });
      });
    spinner.hide();
    return maskInfo;
  }

  private updateMaskModelsList(): void {
    if (
      this.maskFormGroup.get('maskType').valid &&
      this.maskFormGroup.get('maskManufacturer').valid
    ) {
      this.maskFormGroup.get('maskModel').reset();
      this.maskModelsList = this.getMaskByManufacturerAndType();
    }
  }

  ToggleMaskModelsDisplay(): boolean {
    const isResmedMask = this.maskFormGroup.get('maskManufacturer').value === Manufacturer.ResMed;
    // Scroll to selected mask model on first load
    if (isResmedMask && this.slickCarousel?.initialized && this.initialScroll) {
      // Lifecycle hooks cannot be used as slick carousel is initialized later
      this.initialScroll = false;
      const maskSlideId = this.getCurrentMaskSlideId();
      // if index of mask is negative it means that there is
      // no mask selected and no need to scroll
      if (maskSlideId >= 0) {
        this.slickCarousel.slickGoTo(maskSlideId);
      }
    }
    return isResmedMask;
  }

  public getDataFormFieldStatus(dataFieldName: string): string {
    return this.getFieldStatus(dataFieldName, this.maskFormGroup);
  }
}
