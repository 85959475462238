<div [formGroup]="form" class="u-padding-top-large">
  <h2 class="u-h4 u-margin-bottom-none">{{"REGISTRATION-FORM.EnhanceYourExperience" | translate}}</h2>

  <fieldset class="c-form-field">
    <legend class="c-form-field__label u-margin-bottom-small" id="optInEnhanceExperience" [innerHTML]="'REGISTRATION-FORM.registration_consent_header' | translate"></legend>

    <ul class="c-form-field__input c-input--validation" aria-labelledby="optInEnhanceExperience" role="group">
      <li class="c-input c-input--checkbox is-consent">
        <input id="shareInformation" formControlName="shareDetailsWithProviderOptIn" type="checkbox" />
        <label  class="c-form-field__label" for="shareInformation">
           {{"REGISTRATION-FORM.registration_share_details_with_provider_consent" | translate}}
        </label>
      </li>
    @if (requireAnalyticsMode) {
      <li class="c-input c-input--checkbox is-consent">
        <input id="allowAnalyticsCheckBox" formControlName="analyticsMode" type="checkbox" />
        <label class="c-form-field__label" for="allowAnalyticsCheckBox">
          {{"REGISTRATION-FORM.EuropaAllowToUseUserLevelAnalytics" | translate}}
        </label>
      </li>
    }
    <!-- Hides field if marketingConsentDefault is "", null, undefined, etc. -->
    @if (marketingConsentDefault === true || marketingConsentDefault === false) {
      <li class="c-input c-input--checkbox is-consent">
        <input id="marketingOptInCheckBox" formControlName="keepMeInformed" type="checkbox" />
        <label class="c-form-field__label" for="marketingOptInCheckBox">
          {{"REGISTRATION-FORM.EuropaKeepMeInformedOfNews" | translate}}
        </label>
      </li>
    }
    </ul>
  </fieldset>

  <fieldset class="c-form-field">
    <!-- Status: replace with registration_conditions_of_service_header. This string got pushed back from 23rd Cadence Release to the old version, will release new version after sept 2024 release -->
      <legend class="u-hidden" id="acceptPrivacy"><span class="u-screen-reader-text" [innerHTML]="'REGISTRATION-FORM.ConditionsOfService' | translate"></span></legend>

      @if (parentComponent === "extraConsentsForm" && requireProcessHealthDataOptIn) {
        <h2 class="u-h4 u-margin-bottom-none" [innerHTML]="'REGISTRATION-FORM.ConditionsOfService' | translate"></h2>
        <p class="u-margin-bottom-none" [innerHTML]="'REGISTRATION-FORM.ProcessesYourHealthData' | translate"></p>
        <p class="u-margin-bottom-small" [innerHTML]="'REGISTRATION-FORM.TakeAMomentToReadPrivacyNotice' | translate"></p>
      }
    
      @if (parentComponent === "registrationForm") {
        <h2 class="u-h4 u-margin-bottom-none" [innerHTML]="'REGISTRATION-FORM.ConditionsOfService' | translate"></h2>
        <p class="u-margin-bottom-none" [innerHTML]="'REGISTRATION-FORM.ProcessesYourHealthData' | translate"></p>
        <p class="u-margin-bottom-small" [innerHTML]="'REGISTRATION-FORM.TakeAMomentToReadPrivacyNotice' | translate"></p>
      }

    @if (requireProcessHealthDataOptIn) {
      <ul class="c-form-field__input c-input--validation" aria-labelledby="acceptPrivacy" role="group"
        data-form-field="signup-accept-privacy-eu"
        [attr.data-form-field-status]="(form.get('processHealthDataOptIn').touched
                                                    && form.get('processHealthDataOptIn').invalid)
                                                    || (form.get('processHealthDataOptIn').invalid
                                                    && formSubmitted)
                                                    ? 'error' : 'incomplete'">

        <li class="c-input c-input--checkbox is-consent">
          <input id="processHealthDataOptInCheckBox" formControlName="processHealthDataOptIn" type="checkbox" />
          <!-- same text but swap w the key reference var version which is onboarding_create_account_consent once all translations are approved -->
          <label class="c-form-field__label" for="processHealthDataOptInCheckBox">{{"REGISTRATION-FORM.ProcessMyHealthDataConsent" | translate}}</label>
        </li>
      

      </ul>
    }
    </fieldset>


    <!-- Eventually EMEA and ROW will consolidate here and both utilize  registration_terms_of_use_consent_string -->
    @if (!isEU && parentComponent === "registrationForm") {
      <div class="c-form-field">
        <div class="c-form-field__input c-input--validation"
          data-form-field="signup-accept-terms"
              [attr.data-form-field-status]="(form.get('termsOfUseConsent').touched
                                          && form.get('termsOfUseConsent').invalid)
                                          || (form.get('termsOfUseConsent').invalid
                                          && formSubmitted)
                                          ? 'error' : 'incomplete'">
          <div class="c-form-field__label">{{"REGISTRATION-FORM.TermsOfUse" | translate}}</div>
          <div class="c-form-field__input">
            <div class="u-clearfix">
              <div class="u-float-left">
                <div class="c-input c-input--checkbox has-no-label is-consent">
                  <input id="acceptTermsCheckBox" formControlName="termsOfUseConsent" type="checkbox"><label
                for="acceptTermsCheckBox">&nbsp;</label>
              </div>
            </div>
            <div class="u-10/12 u-float-left u-padding-left-tiny">
              <p>{{"REGISTRATION-FORM.Consent.ConsentTo" | translate}}</p>
              <ul class="u-margin-bottom-none">
                <li>{{"REGISTRATION-FORM.Consent.SensitivePersonalData" | translate}}</li>
                <li [innerHTML]="'REGISTRATION-FORM.Consent.TermsAndPrivacyConsent' | translate"></li>
                <li>{{"REGISTRATION-FORM.Consent.LegallyAuthorizedToEnterInfo" | translate}}</li>
              </ul>
            </div>
          </div>
        </div>
        @if ((form.get('termsOfUseConsent').touched && form.get('termsOfUseConsent').invalid)
          || (form.get('termsOfUseConsent').invalid && formSubmitted)
          || (requireProcessHealthDataOptIn && form.get('processHealthDataOptIn').touched && form.get('processHealthDataOptIn').invalid)
          || (requireProcessHealthDataOptIn && form.get('processHealthDataOptIn').invalid && formSubmitted)
          ) {
          <div
          class="c-form-field__error" role="alert" aria-live="assertive">{{"ERRORS.AcceptanceOfTermsOfUseIsRequired" | translate}}</div>
        }
      </div>
    </div>
  } <!-- /!isEU -->

     @if (isEU && parentComponent === "registrationForm") {
    <fieldset class="c-form-field">
      <legend class="c-form-field__label u-margin-bottom-small" id="acceptToC" [innerHTML]="'REGISTRATION-FORM.TermsOfUseText' | translate"></legend>
    <ul class="c-form-field__input c-input--validation" aria-labelledby="acceptToC" role="group"
    data-form-field="signup-accept-terms-eu"
    [attr.data-form-field-status]="(form.get('termsOfUseConsent').touched
                                                && form.get('termsOfUseConsent').invalid)
                                                || (form.get('termsOfUseConsent').invalid
                                                && formSubmitted)
                                                ? 'error' : 'incomplete'">

      <li class="c-input c-input--checkbox is-consent">
        <input id="acceptTermsCheckBox" formControlName="termsOfUseConsent" type="checkbox" />
        
        <label class="c-form-field__label" for="acceptTermsCheckBox" [innerHTML]="'REGISTRATION-FORM.EuropaConsentTermsOfUse' | translate"></label>
      </li>
    </ul>

    <!-- Consider matching these to ROW checks above once new global 'terms of use and privacy notice' string is approved -->
      @if ((form.get('termsOfUseConsent').touched && form.get('termsOfUseConsent').invalid)
      || (form.get('termsOfUseConsent').invalid && formSubmitted)
      || (requireProcessHealthDataOptIn && form.get('processHealthDataOptIn').touched && form.get('processHealthDataOptIn').invalid)
      || (requireProcessHealthDataOptIn && form.get('processHealthDataOptIn').invalid && formSubmitted)
      ) {
      <div
        class="c-form-field__error"
        role="alert"
      aria-live="assertive">{{"ERRORS.AcceptanceOfTermsOfUseIsRequired" | translate}}</div>
    }
  </fieldset>
} <!-- /isEU -->

  <p class="u-font-weight-medium">
    {{"REGISTRATION-FORM.WeAreExcited" | translate}}
  </p>
</div>