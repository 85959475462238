import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AlertService } from 'src/app/modules/alert/components/services/alert.service';
import { ShowTimeApiService } from 'src/app/services/showtime-api/showtime-api.service';
import { ResponseResult } from 'src/app/services/showtime-api/response.structure';
import { MetadataService } from 'src/app/services/metadata/metadata.service';

@Injectable({
  providedIn: 'root',
})
export class AccountUpdateService extends ShowTimeApiService {
  constructor(protected http: HttpClient, protected messageService: AlertService, private metadata: MetadataService) {
    super(messageService);
  }

  updateEmail(email: string): Observable<ResponseResult> {
    return this.http
      .post<ResponseResult>(`${this.metadata.instanceInfo.restUrl}/V1/Patient/ChangeEmail`, {
        newEmail: email,
      })
      .pipe(this.showError());
  }

  downloadMyDataV2(): Observable<ResponseResult> {
    return this.http
      .post<ResponseResult>(
        `${this.metadata.instanceInfo.restUrl}/V2/Patient/DataExport`, 
        {}
      )
      .pipe(this.showError());
  }

  changePassword(oldPassword: string, newPassword: string): Observable<ResponseResult> {
    return this.http
      .post<ResponseResult>(`${this.metadata.instanceInfo.restUrl}/V1/Patient/ChangePassword`, {
        oldPassword,
        newPassword,
      })
      .pipe(this.showError());
  }
}
