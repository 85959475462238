import { Dayjs } from 'dayjs';

export class SleepRecord {
  readonly startDate: string;
  /**
   * Total usage in minutes
   */
  readonly totalUsage = 0;
  readonly sleepScore = 0;
  readonly usageScore = 0;
  readonly ahiScore = 0;
  readonly maskScore = 0;
  readonly leakScore = 0;
  readonly ahi = 0;
  readonly maskPairCount = 0;
  /**
   * Mask leak in L/min.
   */
  readonly leakPercentile = 0;
  readonly sleepRecordPatientId: string | null = null;
  /**
   * A SleepRecord for a day the backend server has supplied data for, but is just zero use.
   */
  readonly isZeroUseRecordType: boolean;
  /**
   * A SleepRecord for a day the backend server hasn't supplied data for.
   */
  readonly isMissingRecordType: boolean;

  /**
   * A SleepRecord for future or before earliest sleep record data
   */
  readonly isVoidRecordType = false;

  /**
   * the hour part of totalUsage in "hh:mm" format
   */
  readonly usageHourPart: string = '0';

  /**
   * the minute part of totalUsage in "hh:mm" format
   */
  readonly usageMinutePart: string = '00';

  /**
   * @param graphQlSleepRecord - A 'getPatient' GraphQL SleepRecord object.
   */
  constructor(graphQlSleepRecord: unknown) {
    Object.assign(this, graphQlSleepRecord);
    this.isMissingRecordType =
      this.isVoidRecordType === false && this.sleepRecordPatientId === null;
    this.isZeroUseRecordType =
      this.isVoidRecordType === false &&
      this.sleepRecordPatientId !== null &&
      this.totalUsage === 0;
    if (this.totalUsage) {
      this.usageHourPart = `${Math.floor(this.totalUsage / 60)}`;
      let minutes = (this.totalUsage % 60).toString();
      minutes = minutes.length === 2 ? minutes : `0${minutes}`;
      this.usageMinutePart = minutes;
    }
  }

  /**
   * Returns a SleepRecord that represents a day that the server has not returned a SleepRecord for.
   * `this.isMissingRecordType` will be 'true' for this record type.
   *
   * @param startDate - The sleep record startDate.
   */
  static newMissingRecord(startDate: Dayjs): SleepRecord {
    return new SleepRecord({ startDate: startDate.format('YYYY-MM-DD') });
  }
}
