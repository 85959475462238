import { Injectable } from '@angular/core';
import { UrlTree, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LocalStorageService } from '../storage/storage.service';
import { CountryId } from 'src/app/types/country.type';
import { LanguageService } from '../language/language.service';
import { CountryService } from '../country/country.service';
import { MetadataService } from '../metadata/metadata.service';

@Injectable({
  providedIn: 'root',
})
export class PoliciesGuard  {
  private CountryIdKey = 'CurrentMyAirCountryId';
  constructor(
    private countryService: CountryService,
    private metadataService: MetadataService,
    private router: Router,
    private localStorage: LocalStorageService,
    private languageService: LanguageService
  ) {
    
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return this.canActivate(route, state);
  }
  canLoad(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return this.canActivate(route, state);
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
     // Wait for API Metadata to load
     await this.metadataService.waitForMetadataToLoad();
    // Check to see if the query string provided country parameters
    const urlParams = new URLSearchParams(window.location.search);
    const countryId: CountryId = urlParams.get('country') as CountryId;
    const appOnlyCountries = await this.countryService.loadAppOnlyCountries().toPromise();
    const supportedCountries = await this.countryService.loadSupportedCountries().toPromise();
    // Confirm we have a countryId passed in and it is in our list of countries
    if (countryId && (appOnlyCountries.includes(countryId) || supportedCountries.includes(countryId))) {
      this.countryService.setSelectedCountryId(countryId);
    } else if (!this.countryService.getSelectedCountryId()) { 
      // Defensive coding, this should be handled for us in the API Metadata guard
      // Set the current path for redirection later if country isn't set
      const currentPath = window.location.pathname;
      this.localStorage.setItem(this.localStorage.redirectURL, currentPath);
      // Otherwise if they are in a non-supported country force them back to country selection
      return this.router.createUrlTree(['country-selection']);
    }
    // Autodetect and set language by browser if necessary
    const languageByBrowser = this.languageService.getLanguageByBrowser();
    if (!this.languageService.getCurrent2LettersLanguage() && languageByBrowser) {
      this.languageService.selectLanguage(languageByBrowser)
    }
    if (this.localStorage.getItem(this.CountryIdKey) as CountryId && this.languageService.getCurrent2LettersLanguage()) {
      return true;
    } else {
      return this.router.createUrlTree(['country-selection']);
    }
  }
}