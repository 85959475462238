import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { WindowService } from '../window/window.service';

@Injectable({
  providedIn: 'root',
})
export class FileSaverService {
  constructor(private window: WindowService) {}
  saveAs = saveAs;

  async base64toBlob(base64Data: string, contentType: string): Promise<Blob> {
    const base64Response = await this.window.fetch(`data:${contentType};base64,${base64Data}`);
    return base64Response.blob();
  }
}
