<div class="c-acc-panel c-acc-panel--read">
  <div class="c-acc-panel__item">
    <div class="c-acc-panel__label">
      <span>{{ 'MY-ACCOUNT/MY-PRIVACY.AllowMyAirAnalytics' | translate}}</span>
    </div>
    <div class="c-acc-panel__value">
      @if (privacySettings.allowIdentifiedAnalytics) {
        <span>{{ 'MY-ACCOUNT/MY-PRIVACY.Yes' | translate}}</span>
      }
      @if (!privacySettings.allowIdentifiedAnalytics) {
        <span>{{ 'MY-ACCOUNT/MY-PRIVACY.No' | translate}}</span>
      }
    </div>
    <div class="c-acc-panel__btn">
      <a class="c-link-ui" (click)="edit()">{{ 'MY-ACCOUNT.Change' | translate}}</a>
    </div>
  </div>
</div>
