import { Component, Input } from '@angular/core';
import { BaseEditComponent } from '../../../base-view-edit-components/base-edit/base-edit.component';
import { ComponentState } from '../../../base-view-edit-components/component-state.enum';

@Component({
  selector: 'mpp-email-changed',
  templateUrl: './email-changed.component.html',
  styleUrls: ['./email-changed.component.scss'],
})
export class EmailChangedComponent extends BaseEditComponent {
  @Input() email: string;
  async save(): Promise<void> {
    this.stateChanged.next(ComponentState.View);
  }
}
