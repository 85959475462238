<div id="" class="modal-wrapper c-modal c-modal--img-only is-active"
  style="min-width: 280px; max-width: 600px;">
  <svg class="c-svg c-svg--close-24" (click)="closeModal()">
    <use xlink:href="#c-svg--close-24"></use>
  </svg>
  <div class="c-modal__body">
      <h2>
          <span [innerHTML]="'REGISTRATION-FORM.onboarding_nightowl_not_supported' | translate"></span>
      </h2>
      <!-- mobile app banners-->
        @if (showMobileAppsBanner) {
            <div class="c-app-badges [ o-layout o-layout--auto o-layout--small ]">
              <div class="o-layout__item">
                <a (click)="appStoreLinkClicked()" 
                  id="lnkAppStoreLinkApple" class="c-apple-badge"
                  [style.background-image]="languageService.selectedAppStoreImage"
                  [href]="countryService.appStoreLink$ | async"
                  title="{{'HOME/VIDEO-CAROUSEL.DownloadAppStore' | translate}}"
                  target="_blank">{{'HOME/VIDEO-CAROUSEL.DownloadAppStore' | translate}}</a>
              </div>
              <div class="o-layout__item">
                <a (click)="playStoreLinkClicked()" 
                  id="lnkAppStoreLinkAndroid" class="c-android-badge"
                  [style.background-image]="languageService.selectedPlayStoreImage"
                  [href]="countryService.playStoreLink$ | async"
                  title="{{'HOME/VIDEO-CAROUSEL.GetGooglePlay' | translate}}"
                  target="_blank">{{'HOME/VIDEO-CAROUSEL.GetGooglePlay' | translate}}</a>
              </div>
            </div>
        }
      <!-- /mobile app banners -->
      <div class="close-button">
        <button (click)="closeModal()" class="c-btn c-btn--primary">{{'MASK-MAGNETS.Close' | translate}}</button>
      </div>
  </div>
</div>