<div class="c-modal-material c-modal-overlay c-modal--img-only is-active"></div>
<div id="modal-DeviceNumberDeviceReveal" class="c-modal c-modal--img-only is-active"
  style="min-width: 280px; max-width: 600px;">
  <button class="c-modal__close" (click)="close()"></button>
  <div class="c-modal__body">
      <h2>{{'MASK-MAGNETS.MaskMagnets' | translate}}</h2>
      <h4>
        @if (!isEU) {
          <span [innerHTML]="'MASK-MAGNETS.MaskMagnetsModal' | translate"></span>
        }
        @else if (isEU) {
          <span [innerHTML]="'MASK-MAGNETS.MaskMagnetsModalEMEA' | translate"></span>
        }
      </h4>
      <div class="close-button">
        <button (click)="close()" class="c-btn c-btn--primary">{{'MASK-MAGNETS.Close' | translate}}</button>
      </div>
  </div>
</div>