import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import {
  ModuleTranslateLoader,
  IModuleTranslationOptions,
} from '@larscom/ngx-translate-module-loader';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { AddDeviceComponent } from '../modules/my-account/components/my-account/my-equipment/my-account-device/add-device/add-device.component';
import versionData from '../../../cacheVersion.json';

export function ModuleHttpLoaderFactory(http: HttpClient): ModuleTranslateLoader {
  const baseTranslateUrl = `./assets/${versionData.jsonVersion}/i18n`;

  const options: IModuleTranslationOptions = {
    modules: [
      // final url: ./assets/${versionData.jsonVersion}/i18n/en.json
      // { baseTranslateUrl },
      { moduleName: 'mask-magnets', baseTranslateUrl },
      { moduleName: 'header', baseTranslateUrl },
      { moduleName: 'footer', baseTranslateUrl },
      { moduleName: 'languages', baseTranslateUrl },
      { moduleName: 'faq-tabs', baseTranslateUrl },
      { moduleName: 'support', baseTranslateUrl },
      { moduleName: 'support/content/myairaccount', baseTranslateUrl },
      { moduleName: 'support/content/myairdata', baseTranslateUrl },
      { moduleName: 'support/content/mytreatment', baseTranslateUrl },
      { moduleName: 'support/content/myequipment', baseTranslateUrl },
      { moduleName: 'support/content/sleeplibrary', baseTranslateUrl },
      { moduleName: 'support/content/security', baseTranslateUrl },
      { moduleName: 'home', baseTranslateUrl },
      { moduleName: 'home/feature-description', baseTranslateUrl },
      { moduleName: 'home/video-carousel', baseTranslateUrl },
      { moduleName: 'data/genders', baseTranslateUrl },
      { moduleName: 'data/months', baseTranslateUrl },
      { moduleName: 'data/sleep-test', baseTranslateUrl },
      { moduleName: 'data/start-therapy-group', baseTranslateUrl },
      { moduleName: 'data/timezones', baseTranslateUrl },
      { moduleName: 'data/countries', baseTranslateUrl },
      { moduleName: 'registration-form', baseTranslateUrl },
      { moduleName: 'registration-form/registration-confirmation', baseTranslateUrl },
      { moduleName: 'errors', baseTranslateUrl },
      { moduleName: 'country-selection', baseTranslateUrl },
      { moduleName: 'alerts', baseTranslateUrl },
      { moduleName: 'my-account/my-equipment', baseTranslateUrl },
      { moduleName: 'my-account/my-privacy', baseTranslateUrl },
      { moduleName: 'my-account/my-equipment/add-mask', baseTranslateUrl },
      { moduleName: 'my-account/my-health-profile', baseTranslateUrl },
      { moduleName: 'my-account/my-notifications', baseTranslateUrl },
      { moduleName: 'my-account/account-details', baseTranslateUrl },
      { moduleName: 'my-account/about-me', baseTranslateUrl },
      { moduleName: 'my-account', baseTranslateUrl },
      { moduleName: 'device-registration', baseTranslateUrl },
      { moduleName: 'forgot-password', baseTranslateUrl },
      { moduleName: 'errors/device-registration', baseTranslateUrl },
      { moduleName: 'dashboard', baseTranslateUrl },
      { moduleName: 'dashboard/score-text', baseTranslateUrl },
      { moduleName: 'dashboard/score-details', baseTranslateUrl },
      { moduleName: 'support/contact-support', baseTranslateUrl },
      { moduleName: 'dialogs/download-sleep-report', baseTranslateUrl },
      { moduleName: 'dialogs/device-type', baseTranslateUrl },
      { moduleName: 'dialogs/new-device', baseTranslateUrl },
      { moduleName: 'coaching-library', baseTranslateUrl },
      { moduleName: 'coaching-library/coaching-display-selector', baseTranslateUrl },
      { moduleName: 'coaching-history', baseTranslateUrl },
      { moduleName: 'history', baseTranslateUrl },
      { moduleName: 'dashboard/my-equipment', baseTranslateUrl },
      { moduleName: 'accept-policies', baseTranslateUrl },
      { moduleName: 'pta', baseTranslateUrl },
    ],
  };
  return new ModuleTranslateLoader(http, options);
}

@NgModule({
  declarations: [SpinnerComponent, AddDeviceComponent],
  imports: [
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: ModuleHttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
      isolate: false,
    }),
    SlickCarouselModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule
  ],
  exports: [TranslateModule, SlickCarouselModule, SpinnerComponent, AddDeviceComponent],
})
export class SharedModule {}
