export enum ErrorCodes {
  patientAlreadyExists = 'patientAlreadyExists',
  internalServerError = 'internalServerError',
  badRequest = 'badRequest',
  appDeprecated = 'appDeprecated',
  unauthorized = 'unauthorized',
  forbidden = 'forbidden',
  invalidArguments = 'invalidArguments',
  deviceInUse = 'deviceInUse',
  maximumDeviceLimitReached = 'maximumDeviceLimitReached',
  policyNotAccepted = 'policyNotAccepted',
  onboardingFlowInProgress = 'onboardingFlowInProgress',
  unsupportedMask = 'unsupportedMask',
  invalidSerialNumber = 'invalidSerialNumber',
  serialNumberInUse = 'serialNumberInUse',
  invalidDeviceNumber = 'invalidDeviceNumber',
  invalidSerialNumberOrDeviceNumber = 'invalidSerialNumberOrDeviceNumber',
  patientHMEDisallowed = 'patientHMEDisallowed',
  deviceNotInThisRegion = 'deviceNotInThisRegion',
  camNotSupported = 'camNotSupported',
  countryNotSupported = 'countryNotSupported',
  regionNotSupported = 'regionNotSupported',
  unsupportedPlatform = 'unsupportedPlatform'
}

export enum ErrorTypes {
  badRequest = 'badRequest',
  internalServerError = 'internalServerError',
  unauthorized = 'unauthorized',
  forbidden = 'forbidden',
  httpError = 'httpError',
}
