import { getSupportedCountries } from '../js/countryCodeUtil';
import { getFirebaseConfig } from '../js/firebaseStg';
import versionData from 'cacheVersion.json';

export const environment = {
  production: false,
  isEU: false,
  okta: {
    clientId: '0oa49buvhWWlTBC0k1d6',
    issuer: 'https://resmed-dht-uat.oktapreview.com/oauth2/aus49fvq9XsGRsVj01d6',
    redirectUri: 'https://myair-web-dev.dht.live',
    scopes: ['openid', 'profile', 'email'],
    baseUrl: 'https://resmed-dht-uat.oktapreview.com',
  },
  endpoints: {
    staticMyair: 'https://static.myair2-stg.dht.live',
    localMetadataMyAir: `assets/${versionData.jsonVersion}/config/web-config.json`,
  },
  app_info: {
    app_version: '2.0.0',
    app_name: 'myAir Canada',
  },
  firebase: getFirebaseConfig(false),
  supportedCountries: getSupportedCountries(),
  myAirLink: {
    EU: 'https://myair-web-dev.rmdeu.live',
    NonEU: 'https://myair-web-dev.dht.live',
  },
  appOnlyCountries: ['KR', 'SG', 'IN', 'HK', 'MY', 'PH'],
  oneTrustIdEU: 'f2a7e7b8-4e77-46d2-a8a9-ea9cb8e4c98a',
  oneTrustIdAMR: 'e2c29adf-4bb6-466a-aa5c-30e4291f132c',
  dawnURL: 'https://go66fshmtp5ecyrktgcsi6wage0vmufn.lambda-url.ap-northeast-1.on.aws/',
  dawnEnv: 'dev',
  brightcoveUrl: 'https://players.brightcove.net/2750693505001/NyrO8Y2ie_default/index.html'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
