import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { GenderId } from 'src/app/modules/registration/data/gender.type';
import { AnalyticsMode } from 'src/graphql/types/api.types';
import { genderInfos } from './types/gender-info.type';
import { LogoutCause } from './types/logout-cause.enum';
import { LoginType } from './types/login-type.enum';
import { PatientService } from '../patient/patient.service';
import { CountryService } from '../country/country.service';
import { Patient } from 'src/graphql/types/api.types';
import { MetadataService } from '../metadata/metadata.service';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {

  //// Screens ////
  // Be sure to set new screens as Custom Dimensions via the Firebase Console. ///
  
  //// Currently tracked in web UI via logScreenViewWeb: ////
  // screen_login
  // screen_download_my_data
  // screen_download_my_data_success
  // screenTherapyPapConsents
  // screenMachineVerification
  // screenRegistration
  // screenMachineVerificationConfirmation
  
  //// Not yet implemented or tracked in web UI: ////
  // screen_data_transparency
  // screen_therapy_pap_onboarding_welcome
  // patientJourneySelected
  // doNotHaveMachineTapped

  //// Events ////
  private loggedInEvent = 'logged_in';
  private loggedOutEvent = 'logged_out';
  private ageGroup = 'age_group_';
  private downloadMyDataRequestedEvent = 'download_my_data_requested';
  private downloadMyDataRequestFailureEvent = 'download_my_data_request_failure';
  private downloadDataSuccessScreenOkEvent = 'download_data_success_screen_ok';
  private clickCreateAccount = 'click_create_account';
  private endRegistrationForm = 'end_registration';
  private completedMaskRegistration = 'mask_setup_complete';
  private completedDeviceRegistration = 'device_setup_complete';
  private smartCoachingEnabled = 'smart_coaching_enabled';
  private appStoreRedirect = "app_store_redirect";
  private playStoreRedirect = "play_store_redirect";
  private ptaLaunched = "pta_launched";
  private dawnAILaunched = "dawn_ai_launched";
  private machineValidationSuccessful = "machine_validation_successful";
  private machineValidationFailure = "machine_validation_failure";
  private machineValidationExceeded = "machine_validation_exceeded";
  private confirmMachineButtonTapped = "confirm_machine_button_tapped";
  private registrationValidationFailure = "registration_validation_failure";
  private registrationSubmissionFailure = "registration_submission_failure";

  constructor(
    private analyticsService: AngularFireAnalytics,
    private patientService: PatientService,
    private countryService: CountryService,
    private metadata: MetadataService
  ) {}

  public smartCoachingOn = "on";
  public smartCoachingOff = "off";
  public redirectLocationAppOnly = "app_only";
  public redirectLocationVideoCarousel = "video_carousel";
  public redirectLocationFeatureDescription = "feature_description";

  public logDawnAILaunched(countryCode: string): void {
    this.analyticsService.logEvent(this.dawnAILaunched, {'country': countryCode})
  }

  // Capture distribution analytics for each tracked screen of the web app
  public logScreenViewWeb(screenName: string): void {
    this.analyticsService.logEvent('screen_view_web', { 
      screen_name_web: screenName // Tracks web distributions
    });
  }

  public logPTALaunched(): void {
    this.analyticsService.logEvent(this.ptaLaunched);
  }

  public logPlayStoreRedirect(countryCode: string, redirectLocation: string): void {
    this.analyticsService.logEvent(this.playStoreRedirect, {'country': countryCode, 'redirect_location': redirectLocation })
  }

  public logAppStoreRedirect(countryCode: string, redirectLocation: string): void {
    this.analyticsService.logEvent(this.appStoreRedirect, {'country': countryCode, 'redirect_location': redirectLocation })
  }

  public logSmartCoachingEnabled(onOrOff: string): void {
    this.analyticsService.logEvent(this.smartCoachingEnabled, {'on_off': onOrOff })
  }

  public logClickCreateAccountEvent(): void {
    this.analyticsService.logEvent(this.clickCreateAccount);
  }

  public logEndRegistrationForm(): void {
    this.analyticsService.logEvent(this.endRegistrationForm);
  }

  public logCompletedDeviceRegistrationEvent(): void {
    this.analyticsService.logEvent(this.completedDeviceRegistration);
  }

  public logCompletedMaskRegistrationEvent(): void {
    this.analyticsService.logEvent(this.completedMaskRegistration);
  }

  public setUserId(userId: string): void {
    this.analyticsService.setUserId(userId);
  }

  public logLoggedInEvent(): void {
    this.analyticsService.logEvent(this.loggedInEvent, { type: LoginType.Password });
  }

  public logLoggedOutEvent(): void {
    this.analyticsService.logEvent(this.loggedOutEvent, { logout_cause: LogoutCause.UserInitiated });
  }

  public logDownloadMyDataRequestedEvent(): void {
    this.analyticsService.logEvent(this.downloadMyDataRequestedEvent);
  }

  public logDownloadMyDataRequestFailureEvent(): void {
    this.analyticsService.logEvent(this.downloadMyDataRequestFailureEvent);
  }

  public logDownloadDataSuccessScreenOkEvent(): void {
    this.analyticsService.logEvent(this.downloadDataSuccessScreenOkEvent);
  }

  public logMachineValidationSuccessful(): void {
    this.analyticsService.logEvent(this.machineValidationSuccessful);
  }

  public logMachineValidationFailure(): void {
    this.analyticsService.logEvent(this.machineValidationFailure);
  }

  public logMachineValidationExceeded(): void {
    this.analyticsService.logEvent(this.machineValidationExceeded);
  }

  public logConfirmMachineButtonTapped(): void {
    this.analyticsService.logEvent(this.confirmMachineButtonTapped);
  }

  public logRegistrationValidationFailure(): void {
    this.analyticsService.logEvent(this.registrationValidationFailure);
  }

  public logRegistrationSubmissionFailure(): void {
    this.analyticsService.logEvent(this.registrationSubmissionFailure);
  }

  public setUserProperties(birthday: Date, gender: GenderId): void {
    this.analyticsService.setUserProperties({
      age_group: this.ageGroup + this.getAgeGroup(birthday),
    });
    this.analyticsService.setUserProperties({ gender_info: genderInfos[gender] });
  }

  private getAge(birthday: Date): number {
    const dateNow = new Date().getFullYear();
    let age = 0;
    if (dateNow > birthday.getFullYear()) {
      age = dateNow - birthday.getFullYear();
    }
    return age;
  }

  async checkAndSetAnalytics(): Promise<void> {
    // Wait for API Metadata to load
    await this.metadata.waitForMetadataToLoad();
    const [patientData, error] = await this.patientService.getAnalyticsInfo().toPromise();

    if (error) {
      return;
    }

    if (patientData.patient.analyticsMode === AnalyticsMode.untracked) {
      await this.analyticsService.setAnalyticsCollectionEnabled(false);
    } else if (
      patientData.patient.analyticsMode === AnalyticsMode.identified &&
      this.countryService.getSelectedCountryId() === 'US'
    ) {
      await this.setAnalyticsParameters(patientData.patient.id, patientData.patient);
    } else {
      await this.setAnalyticsParameters(null, patientData.patient);
    }
  }

  private async setAnalyticsParameters(id: string, patient: Patient) {
    await this.analyticsService.setAnalyticsCollectionEnabled(true);
    this.setUserId(id);
    this.setUserProperties(new Date(patient.dateOfBirth), patient.gender);
  }

  private getAgeGroup(birthday: Date): number {
    const age = this.getAge(birthday);
    let group = 1;
    if (age < 18) {
      group = 1;
    } else if (age >= 18 && age <= 24) {
      group = 2;
    } else if (age >= 25 && age <= 34) {
      group = 3;
    } else if (age >= 35 && age <= 44) {
      group = 4;
    } else if (age >= 45 && age <= 64) {
      group = 5;
    } else {
      group = 6;
    }
    return group;
  }
}
