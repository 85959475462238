import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  SleepTestId,
  sleepTestTypesSelected,
  sleepTestTranslated,
  sleepTestTypes,
  sleepTestIds,
} from 'src/app/modules/registration/data/sleep-test.type';
import {
  startTherapyGroupIds,
  StartTherapyGroupId,
  startTherapyGroups,
  StartTherapyGroupName,
} from 'src/app/modules/registration/data/start-therapy-group.type';
// implementation postponed due to MWEB-714
// import { BaselineSleepinessValues } from 'src/graphql/types/api.types';

@Component({
  selector: 'mpp-add-health-profile',
  templateUrl: './add-health-profile.component.html',
  styleUrls: ['./add-health-profile.component.scss'],
})
export class AddHealthProfileComponent {
  @Input() healthProfileForm: UntypedFormGroup;
  // the value of unknown test type
  public notKnownSleepTestTypeKey = (Object.keys(sleepTestTranslated) as Array<SleepTestId>).find(
    (key) => sleepTestTranslated[key] === '',
  );

  // implementation postponed due to MWEB-714
  // public baselineSleepinessValues = BaselineSleepinessValues;
  // public baselineSleepinessList: BaselineSleepinessValues[] = [
  //   BaselineSleepinessValues.extremely,
  //   BaselineSleepinessValues.very,
  //   BaselineSleepinessValues.moderately,
  //   BaselineSleepinessValues.slightly,
  //   BaselineSleepinessValues.notAtAll,
  // ];

  // get sleep test types except one that is 'notKnown'
  public getSleepTestTypeIds(): readonly SleepTestId[] {
    return sleepTestIds.filter((sleepTest) => sleepTest !== this.notKnownSleepTestTypeKey);
  }

  public getSleepTestTypeTranslationId(sleepTestId: SleepTestId): string {
    return sleepTestTranslated[sleepTestId];
  }

  public getSleepTestImgSource(sleepTestId: SleepTestId): string {
    // if sleep test type is selected
    // get appropriate image from images for selected sleep test types
    // if it's not selected - return standard image for sleep test type
    return this.healthProfileForm.get('sleepTestType').value === sleepTestId
      ? sleepTestTypesSelected[sleepTestId]
      : sleepTestTypes[sleepTestId];
  }

  public getStartTherapyGroupIds(): readonly string[] {
    return startTherapyGroupIds;
  }

  public getStartTherapyGroupName(startTherapyGroupId: StartTherapyGroupId): StartTherapyGroupName {
    return startTherapyGroups[startTherapyGroupId];
  }

  /**
   * Sets the values of selected sleep test type
   * @param sleepTestType type of sleep test selected by the patient
   */
  public onSleepTestTypeSelected(sleepTestType: SleepTestId): void {
    this.healthProfileForm.get('sleepTestType').setValue(sleepTestType);
  }

  public getAHIeventsErrorKeys(): string[] {
    const { errors } = this.healthProfileForm.get('AHIevents');
    if (!errors) return [];
    if (errors.min || errors.max) {
      return ['ERRORS.InvalidAHIBaseline'];
    }
    if (errors.firstZeroRestricted) {
      return ['ERRORS.InvalidAHIBaseline', 'ERRORS.AHIOneDecimal'];
    }
    if (errors.pattern) {
      return ['ERRORS.AHIOneDecimal'];
    }
    return [];
  }
}
