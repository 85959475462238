import { Component, Input, OnInit } from '@angular/core';
import { TimeZoneData, timeZones } from 'src/app/modules/registration/data/time-zone.type';
import { AboutMeDetails } from './types/about-me.type';
import { ComponentState } from '../../base-view-edit-components/component-state.enum';

@Component({
  selector: 'mpp-about-me',
  templateUrl: './about-me.component.html',
  styleUrls: ['./about-me.component.scss'],
})
export class AboutMeComponent implements OnInit {
  @Input() aboutMeDetails: AboutMeDetails;
  @Input() isJapan: boolean;
  aboutMeStateValue = ComponentState.View;
  aboutMeState = ComponentState;
  allTimezones = this.getAllTimeZones();
  currentTimezone: TimeZoneData = null;
  currentTimezoneId: string = null;


  ngOnInit(): void {
    this.setCurrentTimezoneOrId();
  }

  onAboutMeChange(aboutMeDetails: AboutMeDetails): void {
    this.aboutMeDetails = aboutMeDetails;
    this.setCurrentTimezoneOrId();
  }

  setCurrentTimezoneOrId() {
    // Attempt to match patient timezoneId with a timezone in Web's hardcoded timezone list, and set it to currentTimeZone
    this.currentTimezone = this.allTimezones.find((z) => z.id === this.aboutMeDetails.timezoneId);

    // If patient timezoneId does not exist in hardcoded Web timezone list, display patient timezoneId instead
    if (!this.currentTimezone) {
      this.currentTimezoneId = this.aboutMeDetails.timezoneId;
    }
  }

  private getAllTimeZones(): readonly TimeZoneData[] {
    let allTimezones: TimeZoneData[] = [];
    Object.values(timeZones).forEach((timezones) => {
      allTimezones = [...allTimezones, ...timezones];
    });

    const uniqTimezonesById = new Map<string, TimeZoneData>();
    const uniqTimezonesByTranslationKeys = new Map<string, TimeZoneData>();
    const uniqTimezones: TimeZoneData[] = [];

    allTimezones.forEach((z) => {
      if (!uniqTimezonesById.has(z.id) && !uniqTimezonesByTranslationKeys.has(z.name)) {
        uniqTimezonesById.set(z.id, z);
        uniqTimezonesByTranslationKeys.set(z.name, z);
        uniqTimezones.push(z);
      }
    });

    const sortedTimezones = uniqTimezones.sort((aZone, bZone) => bZone.offset - aZone.offset);

    return sortedTimezones;
  }
}
