import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth/auth.service';
import { PolicyService } from 'src/app/services/policy/policy.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';

@Component({
  selector: 'mpp-accept-policies',
  templateUrl: './accept-policies.component.html',
  styleUrls: ['./accept-policies.component.scss'],
})
export class AcceptPoliciesComponent {
  constructor(
    private policyService: PolicyService,
    private spinnerService: SpinnerService,
    private authService: AuthService,
    private router: Router,
  ) {}

  async acceptPolicies(): Promise<void> {
    const spinner = this.spinnerService.show();
    await this.policyService.acceptPolicies().toPromise();
    spinner.hide();
    this.router.navigate(['/dashboard']);
  }

  async cancel(): Promise<void> {
    const spinner = this.spinnerService.show();
    await this.authService.logout();
    spinner.hide();
  }
}
