import { GenderId } from '../../../modules/registration/data/gender.type';

export const genderInfoNames = ['Male', 'Female', 'Prefer not to say'];

export type GenderInfoName = typeof genderInfoNames[number];

export const genderInfos: Record<GenderId, GenderInfoName> = {
  male: 'Male',
  female: 'Female',
  preferNotToSay: 'Prefer not to say',
};
