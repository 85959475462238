import { OverlayRef } from '@angular/cdk/overlay';
import { Subject, Observable } from 'rxjs';

export interface Spinner {
  hide(): void;
}

export class InternalSpinner implements Spinner {
  private counter = 1;
  private afterDisposedSubject: Subject<void> = new Subject();

  constructor(private overlayRef: OverlayRef) {}

  hide(): void {
    this.counter -= 1;
    if (this.counter === 0) {
      this.overlayRef.dispose();
      this.afterDisposedSubject.next();
    }
  }

  add(): void {
    this.counter += 1;
  }

  afterDisposed(): Observable<void> {
    return this.afterDisposedSubject.asObservable();
  }
}
