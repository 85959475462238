import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { HeadersService } from 'src/app/services/showtime-api/headers.service';
import { environment } from 'src/environments/environment';
import { MetadataService } from '../../metadata/metadata.service';

@Injectable({
  providedIn: 'root',
})
export class MyAirInterceptor implements HttpInterceptor {
  constructor(private headersService: HeadersService, private metadata: MetadataService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Add headers to myAir api and static myAir api
    if (req.method === 'POST' || req.method === 'GET') {
      if (req.url.startsWith(`${environment.endpoints.staticMyair}/v2/metadata.json`)) {
        const headers = this.headersService.getHeadersForStaticMetadataRequest();
        return next.handle(req.clone({ headers }));
      } else if (req.url.startsWith(this.metadata.instanceInfo.restUrl)) {
        const headers = this.headersService.getHeadersForApiMyAirRequest();
        return next.handle(req.clone({ headers }));
      } else if (req.url.startsWith(environment.endpoints.staticMyair)) {
        const headers = this.headersService.getHeadersForStaticMyAirRequest();
        return next.handle(req.clone({ headers }));
      }
      return next.handle(req);
    }
    return next.handle(req);
  }
}