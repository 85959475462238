import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AccountUpdateService } from 'src/app/modules/my-account/services/account-update.service';
import { ErrorCodes } from 'src/app/services/showtime-api/response.structure';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { RegistrationValidators } from 'src/app/modules/registration/validators/registration.validators';
import { BaseEditComponent } from '../../../base-view-edit-components/base-edit/base-edit.component';
import { ComponentState } from '../../../base-view-edit-components/component-state.enum';

@Component({
  selector: 'mpp-email-edit',
  templateUrl: './email-edit.component.html',
  styleUrls: ['./email-edit.component.scss'],
})
export class EmailEditComponent extends BaseEditComponent implements OnInit {
  @Input() email: string;
  @Output() emailChagned = new EventEmitter<string>();

  form: UntypedFormGroup;
  emailMaxLength = 100;
  constructor(
    private accountUpdateService: AccountUpdateService,
    private spinnerService: SpinnerService,
    private formBuilder: UntypedFormBuilder,
  ) {
    super();
  }

  save(): void {
    const spinner = this.spinnerService.show();
    const email = this.form.get('email').value;
    this.accountUpdateService.updateEmail(email).subscribe((response) => {
      if (!response.result.success) {
        if (response.result.errorCode === ErrorCodes.emailInUse) {
          this.form.get('email').setErrors({ emailInUse: true });
        }
        spinner.hide();
        return;
      }
      this.stateChanged.next(ComponentState.ApproveChanges);
      this.emailChagned.next(email);
      spinner.hide();
    });
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {
        email: [
          this.email,
          [RegistrationValidators.isEmailCorrect, Validators.required, Validators.maxLength(255)],
        ],
        confirmEmail: ['', [Validators.required, Validators.maxLength(255)]],
      },
      {
        validator: RegistrationValidators.isEmailNotConfirmed,
      },
    );
  }
}
