<div id="modal-DataEmailSent" class="c-modal-material c-modal--confirm">
    <div class="c-modal__header">
        <h2 class="c-modal__heading">  <p>{{'MY-ACCOUNT/MY-PRIVACY.DownloadV2.ThanksForYourRequest' | translate}}</p>
        </h2>
    </div>
    <div class="c-modal__body">
      <p>{{'MY-ACCOUNT/MY-PRIVACY.DownloadV2.Confirmation' | translate}}</p>
    </div>
    <div class="c-modal__footer">
      <div class="o-layout o-layout--auto@tablet">
        <div class="o-layout__item">
          <button class="c-modal__warnConfirm-btn [ c-btn c-btn--primary ]"
            (click)="close()">{{'MY-ACCOUNT/MY-PRIVACY.DownloadV2.Ok' | translate}}</button>
        </div>
      </div>
    </div>
  </div>