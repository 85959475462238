import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { PatientService } from 'src/app/services/patient/patient.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { AnalyticsMode } from 'src/graphql/types/api.types';
import { MyPrivacySettings } from '../my-privacy.component';
import { ComponentState } from '../../../base-view-edit-components/component-state.enum';
import { BaseEditComponent } from '../../../base-view-edit-components/base-edit/base-edit.component';
import { environment } from 'src/environments/environment';
import { CountryService } from 'src/app/services/country/country.service';

@Component({
  selector: 'mpp-allow-analytics-edit',
  templateUrl: './allow-analytics-edit.component.html',
  styleUrls: ['./allow-analytics-edit.component.scss'],
})
export class AllowAnalyticsEditComponent extends BaseEditComponent implements OnInit {
  @Output() analyticsChanged = new EventEmitter<boolean>();
  @Input() privacySettings: MyPrivacySettings;

  public selectedCountry: string = this.countryService.getSelectedCountryId();

  form = this.fb.group({
    allowAnalytics: [false],
  });

  constructor(
    private patientService: PatientService,
    private fb: UntypedFormBuilder,
    private spinner: SpinnerService,
    private analyticsService: AnalyticsService,
    private countryService: CountryService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.form.controls.allowAnalytics.setValue(this.privacySettings.allowIdentifiedAnalytics);
  }

  async save(): Promise<void> {
    const spinner = this.spinner.show();
    const allowIdentifiedAnalytics = this.form.get('allowAnalytics').value;
    const allowAnonymous = environment.isEU ? AnalyticsMode.untracked : AnalyticsMode.anonymous;
    const analyticsModeByRegion = this.selectedCountry === 'US' ? AnalyticsMode.identified : AnalyticsMode.anonymous;
    const analyticsMode = allowIdentifiedAnalytics ? analyticsModeByRegion : allowAnonymous;
    await this.patientService.setPrivacySettings({ analyticsMode }).toPromise();
    this.analyticsService.checkAndSetAnalytics();

    this.stateChanged.next(ComponentState.View);
    this.analyticsChanged.next(allowIdentifiedAnalytics);
    spinner.hide();
  }
}
