import { CountryId } from 'src/app/types/country.type';

export interface TimeZoneData {
  id: string;
  name: string;
  offset: number;
  DSTOffset: number;
}

//note: the reason for the apparent inversion of offset/DSTOffset (minus sign where intuitively no minus sign should be) is due to the use of the JavaScript Date object. See https://stackoverflow.com/questions/14669475/date-gettimezoneoffset-inverted
export const timeZones: Record<CountryId, readonly TimeZoneData[]> = {
  US: [
    { id: 'America/New_York', name: 'DATA/TIMEZONES.EasternTime', offset: 300, DSTOffset: 240 },
    {
      id: 'America/Indianapolis',
      name: 'DATA/TIMEZONES.IndianaEast',
      offset: 300,
      DSTOffset: 240,
    },
    { id: 'America/Chicago', name: 'DATA/TIMEZONES.CentralTime', offset: 360, DSTOffset: 300 },
    { id: 'America/Denver', name: 'DATA/TIMEZONES.MountainTime', offset: 420, DSTOffset: 360 },
    { id: 'America/Phoenix', name: 'DATA/TIMEZONES.Arizona', offset: 420, DSTOffset: 420 },
    { id: 'America/Los_Angeles', name: 'DATA/TIMEZONES.PacificTime', offset: 480, DSTOffset: 420 },
    { id: 'America/Anchorage', name: 'DATA/TIMEZONES.Alaska', offset: 540, DSTOffset: 480 },
    { id: 'America/Adak', name: 'DATA/TIMEZONES.Aleutian', offset: 600, DSTOffset: 540 },
    { id: 'Pacific/Honolulu', name: 'DATA/TIMEZONES.Hawaii', offset: 600, DSTOffset: 600 },
  ],
  CA: [
    { id: 'America/St_Johns', name: 'DATA/TIMEZONES.Newfoundland', offset: 210, DSTOffset: 150 },
    { id: 'America/Halifax', name: 'DATA/TIMEZONES.AtlanticTime', offset: 240, DSTOffset: 180 },
    { id: 'America/La_Paz', name: 'DATA/TIMEZONES.Georgetown', offset: 240, DSTOffset: 240 },
    { id: 'America/New_York', name: 'DATA/TIMEZONES.EasternTime', offset: 300, DSTOffset: 240 },
    { id: 'America/Bogota', name: 'DATA/TIMEZONES.Bogota', offset: 300, DSTOffset: 300 },
    { id: 'America/Chicago', name: 'DATA/TIMEZONES.CentralTime', offset: 360, DSTOffset: 300 },
    { id: 'America/Regina', name: 'DATA/TIMEZONES.Saskatchewan', offset: 360, DSTOffset: 360 },
    { id: 'America/Denver', name: 'DATA/TIMEZONES.MountainTime', offset: 420, DSTOffset: 360 },
    { id: 'America/Phoenix', name: 'DATA/TIMEZONES.Arizona', offset: 420, DSTOffset: 420 },
    { id: 'America/Los_Angeles', name: 'DATA/TIMEZONES.PacificTime', offset: 480, DSTOffset: 420 },
  ],
  AR: [
    {
      id: 'America/Argentina/Buenos_Aires',
      name: 'DATA/TIMEZONES.BuenosAires',
      offset: 180,
      DSTOffset: 180,
    },
  ],
  BR: [
    { id: 'Etc/GMT+2', name: 'DATA/TIMEZONES.UTC-02', offset: 120, DSTOffset: 120 },
    { id: 'America/Fortaleza', name: 'DATA/TIMEZONES.Fortaleza', offset: 180, DSTOffset: 180 },
    { id: 'America/Araguaina', name: 'DATA/TIMEZONES.Araguaina', offset: 180, DSTOffset: 180 },
    { id: 'America/Bahia', name: 'DATA/TIMEZONES.Salvador', offset: 180, DSTOffset: 180 },
    { id: 'America/Sao_Paulo', name: 'DATA/TIMEZONES.Brasilia', offset: 180, DSTOffset: 180 },
    { id: 'America/Cuiaba', name: 'DATA/TIMEZONES.Cuiaba', offset: 240, DSTOffset: 240 },
    { id: 'America/Manaus', name: 'DATA/TIMEZONES.Georgetown', offset: 240, DSTOffset: 240 },
    { id: 'America/Eirunepe', name: 'DATA/TIMEZONES.Bogota', offset: 300, DSTOffset: 300 },
  ],
  CL: [
    { id: 'America/Santiago', name: 'DATA/TIMEZONES.Santiago', offset: 240, DSTOffset: 180 },
    { id: 'America/Punta_Arenas', name: 'DATA/TIMEZONES.PuntaArenas', offset: 180, DSTOffset: 180 },
    { id: 'Pacific/Easter', name: 'DATA/TIMEZONES.PacificEaster', offset: 360, DSTOffset: 300 },
  ],
  CO: [{ id: 'America/Bogota', name: 'DATA/TIMEZONES.Bogota', offset: 300, DSTOffset: 300 }],
  MX: [
    { id: 'America/Mexico_City', name: 'DATA/TIMEZONES.Guadalajara', offset: 360, DSTOffset: 300 },
    { id: 'America/Cancun', name: 'DATA/TIMEZONES.Chetumal', offset: 300, DSTOffset: 300 },
    { id: 'America/Matamoros', name: 'DATA/TIMEZONES.CentralTime', offset: 360, DSTOffset: 300 },
    { id: 'America/Chihuahua', name: 'DATA/TIMEZONES.Chihuahua', offset: 420, DSTOffset: 360 },
    { id: 'America/Ojinaga', name: 'DATA/TIMEZONES.MountainTime', offset: 420, DSTOffset: 360 },
    { id: 'America/Hermosillo', name: 'DATA/TIMEZONES.Arizona', offset: 420, DSTOffset: 420 },
    { id: 'America/Tijuana', name: 'DATA/TIMEZONES.Baja', offset: 480, DSTOffset: 420 },
  ],
  AU: [
    {
      id: 'Australia/Lord_Howe',
      name: 'DATA/TIMEZONES.LordHoweIsland',
      offset: -630,
      DSTOffset: -660,
    },
    {
      id: 'Pacific/Guadalcanal',
      name: 'DATA/TIMEZONES.SolomonIsland',
      offset: -660,
      DSTOffset: -660,
    },
    { id: 'Australia/Hobart', name: 'DATA/TIMEZONES.Tasmania', offset: -600, DSTOffset: -660 },
    { id: 'Australia/Sydney', name: 'DATA/TIMEZONES.Canberra', offset: -600, DSTOffset: -660 },
    {
      id: 'Australia/Adelaide',
      name: 'DATA/TIMEZONES.SouthAustralia',
      offset: -570,
      DSTOffset: -630,
    },
    { id: 'Australia/Brisbane', name: 'DATA/TIMEZONES.Queensland', offset: -600, DSTOffset: -600 },
    {
      id: 'Australia/Darwin',
      name: 'DATA/TIMEZONES.NorthernTerritory',
      offset: -570,
      DSTOffset: -570,
    },
    {
      id: 'Australia/Perth',
      name: 'DATA/TIMEZONES.WesternAustralia',
      offset: -480,
      DSTOffset: -480,
    },
    {
      id: 'Australia/Eucla',
      name: 'DATA/TIMEZONES.WesternAustraliaEucla',
      offset: -525,
      DSTOffset: -525,
    },
  ],
  NZ: [
    {
      id: 'Pacific/Auckland',
      name: 'DATA/TIMEZONES.NewZealandTime',
      offset: -720,
      DSTOffset: -780,
    },
    { id: 'Pacific/Chatham', name: 'DATA/TIMEZONES.ChathamIslands', offset: -765, DSTOffset: -825 },
  ],
  JP: [{ id: 'Asia/Tokyo', name: 'DATA/TIMEZONES.Tokyo', offset: -540, DSTOffset: -540 }],
  GB: [{ id: 'Europe/Dublin', name: 'DATA/TIMEZONES.Dublin', offset: 0, DSTOffset: -60 }],
  IE: [{ id: 'Europe/Dublin', name: 'DATA/TIMEZONES.Dublin', offset: 0, DSTOffset: -60 }],
  DE: [{ id: 'Europe/Amsterdam', name: 'DATA/TIMEZONES.Amsterdam', offset: -60, DSTOffset: -120 }],
  FI: [{ id: 'Europe/Helsinki', name: 'DATA/TIMEZONES.Helsinki', offset: -120, DSTOffset: -180 }],
  CH: [{ id: 'Europe/Amsterdam', name: 'DATA/TIMEZONES.Amsterdam', offset: -60, DSTOffset: -120 }],
  NL: [{ id: 'Europe/Amsterdam', name: 'DATA/TIMEZONES.Amsterdam', offset: -60, DSTOffset: -120 }],
  SE: [{ id: 'Europe/Amsterdam', name: 'DATA/TIMEZONES.Amsterdam', offset: -60, DSTOffset: -120 }],
  NO: [{ id: 'Europe/Amsterdam', name: 'DATA/TIMEZONES.Amsterdam', offset: -60, DSTOffset: -120 }],
  IT: [{ id: 'Europe/Rome', name: 'DATA/TIMEZONES.Rome', offset: -60, DSTOffset: -120 }],
  PL: [{ id: 'Europe/Sarajevo', name: 'DATA/TIMEZONES.Sarajevo', offset: -60, DSTOffset: -120 }],
  ES: [
    { id: 'Europe/Brussels', name: 'DATA/TIMEZONES.Brussels', offset: -60, DSTOffset: -120 },
    { id: 'Europe/Dublin', name: 'DATA/TIMEZONES.Dublin', offset: 0, DSTOffset: -60 },
  ],
  DK: [{ id: 'Europe/Brussels', name: 'DATA/TIMEZONES.Brussels', offset: -60, DSTOffset: -120 }],
  BE: [{ id: 'Europe/Brussels', name: 'DATA/TIMEZONES.Brussels', offset: -60, DSTOffset: -120 }],
  FR: [{ id: 'Europe/Brussels', name: 'DATA/TIMEZONES.Brussels', offset: -60, DSTOffset: -120 }],
  PT: [
    { id: 'Europe/Dublin', name: 'DATA/TIMEZONES.Dublin', offset: 0, DSTOffset: -60 },
    { id: 'Atlantic/Azores', name: 'DATA/TIMEZONES.Azores', offset: 60, DSTOffset: 0 },
  ],
  AT: [{ id: 'Europe/Amsterdam', name: 'DATA/TIMEZONES.Amsterdam', offset: -60, DSTOffset: -120 }],
  CZ: [{ id: 'Europe/Belgrade', name: 'DATA/TIMEZONES.Belgrade', offset: -60, DSTOffset: -120 }],
  GR: [{ id: 'Europe/Athens', name: 'DATA/TIMEZONES.Athens', offset: -120, DSTOffset: -180 }],
  ZA: [{ id: 'Africa/Johannesburg', name: 'DATA/TIMEZONES.Johannesburg', offset: -120, DSTOffset: -120 }],
  KR: [{ id: 'Asia/Seoul', name: 'DATA/TIMEZONES.Seoul', offset: -540, DSTOffset: -540 }],
  SG: [{ id: 'Asia/Singapore', name: 'DATA/TIMEZONES.SingaporeTZ', offset: -480, DSTOffset: -480 }],
  IN: [{ id: 'Asia/Kolkata', name: 'DATA/TIMEZONES.Kolkata', offset: -330, DSTOffset: -330 }],
  HK: [{ id: 'Asia/Hong_Kong', name: 'DATA/TIMEZONES.HongKongTZ', offset: -480, DSTOffset: -480 }],
  MY: [{ id: 'Asia/Singapore', name: 'DATA/TIMEZONES.SingaporeTZ', offset: -480, DSTOffset: -480 }],
  PH: [{ id: 'Asia/Manila', name: 'DATA/TIMEZONES.Manila', offset: -480, DSTOffset: -480 }],
  TW: [{ id: 'Asia/Taipei', name: 'DATA/TIMEZONES.Taipei', offset: -480, DSTOffset: -480 }],
  CY: [{ id: 'Asia/Nicosia', name: 'DATA/TIMEZONES.Nicosia', offset: -120, DSTOffset: -180 }],
  TH: [{ id: 'Asia/Bangkok', name: 'DATA/TIMEZONES.Bangkok', offset: -420, DSTOffset: -420 }],
  IS: [{ id: 'Atlantic/Reykjavik', name: 'DATA/TIMEZONES.Reykjavik', offset: 0, DSTOffset: 0 }],
  MT: [{ id: 'Europe/Malta', name: 'DATA/TIMEZONES.Valletta', offset: -60, DSTOffset: -120 }],
  LU: [{ id: 'Europe/Luxembourg', name: 'DATA/TIMEZONES/Luxembourg', offset: -60, DSTOffset: -120 }]
} as const;
