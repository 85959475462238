import { Component, Input } from '@angular/core';
import { Mask, FgDevice } from 'src/app/services/patient/types/device.type';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'mpp-my-equipment',
  templateUrl: './my-equipment.component.html',
  styleUrls: ['./my-equipment.component.scss'],
})
export class MyEquipmentComponent {
  @Input() device: FgDevice;
  @Input() mask: Mask;

  getDeviceImagePath(): string {
    return `${environment.endpoints.staticMyair}/${this.device.imagePath}`;
  }

  getMaskImagePath(): string {
    return `${environment.endpoints.staticMyair}${this.mask.imagePath}`;
  }
}
