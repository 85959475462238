import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, from, map, of, switchMap } from 'rxjs';
import { MetadataService } from '../metadata/metadata.service';
import { CountryService } from '../country/country.service';
import { LocalStorageService } from '../storage/storage.service';
import { CountryId } from 'src/app/types/country.type';

@Injectable({
  providedIn: 'root'
})
export class ApiMetadataGuard {
  constructor(private metadata: MetadataService,
    private countryService: CountryService,
    private router: Router,
    private localStorage: LocalStorageService
  ) {}

  canActivateChild(
    route: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(route, state);
  }
  canLoad(
    route: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(route, state);
  }
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | UrlTree {

    // Check if we're accessing policies
    const isPoliciesRoute = window.location.pathname.includes('policies');
    const urlParams = new URLSearchParams(window.location.search);
    const countryParam = urlParams.get('country') as CountryId;

    // If no country is selected but we have a country parameter and we're on policies route
    if (!this.countryService.getSelectedCountryId() && countryParam && isPoliciesRoute) {
      return from(this.validateAndSetCountry(countryParam)).pipe(
        switchMap(isValid => {
          if (isValid) {
            // Country is valid, proceed with metadata update
            return this.metadata.updateMetadataFromApi().pipe(
              map(() => true)
            );
          } else {
            // Invalid country, redirect to country selection
            this.router.navigate(['country-selection']);
            return of(false);
          }
        })
      );
    }

    if (!this.countryService.getSelectedCountryId()) {
      // Set the current path for redirection later if country isn't set
      const currentPath = window.location.pathname;
      this.localStorage.setItem(this.localStorage.redirectURL, currentPath);
      this.router.navigate(['country-selection']);
      return of(false);
    }
    return this.metadata.updateMetadataFromApi().pipe(
      map((_) => {
        return of(true);
      })
    );
  }

  private async validateAndSetCountry(countryId: CountryId): Promise<boolean> {
    try {
      const [appOnlyCountries, supportedCountries] = await Promise.all([
        this.countryService.loadAppOnlyCountries().toPromise(),
        this.countryService.loadSupportedCountries().toPromise()
      ]);

      if (appOnlyCountries.includes(countryId) || supportedCountries.includes(countryId)) {
        this.countryService.setSelectedCountryId(countryId);
        return true;
      }
      return false;
    } catch {
      return false;
    }
  }

}
