import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth/auth.service';
import { CountryService } from 'src/app/services/country/country.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';


@Component({
  selector: 'mpp-not-supported',
  templateUrl: './not-supported.component.html',
  styleUrl: './not-supported.component.scss'
})
export class NotSupportedComponent implements OnInit {

  showMobileAppsBanner: boolean = false;

  constructor(
    private authService: AuthService,
    public countryService: CountryService,
    private analyticsService: AnalyticsService,
    public languageService: LanguageService,
    private dialogRef: MatDialogRef<NotSupportedComponent>
  ) {
    this.dialogRef.disableClose = false;
  }

  ngOnInit() {
    this.countryService.initializeMobileStoreLinks();
    this.setAppBanner();
    this.dialogRef.backdropClick().subscribe(() => {
      this.closeModal();
    });
  }

  closeModal() {
    this.dialogRef.close();
    this.analyticsService.logLoggedOutEvent();
    this.authService.logout();
  }

  setAppBanner(): void {
    if (this.countryService.latAmNonRollout()) {
      this.showMobileAppsBanner = false;
    } else {
      this.showMobileAppsBanner = true;
    }
  }

  playStoreLinkClicked(): void {
    this.analyticsService.logPlayStoreRedirect(this.countryService.getSelectedCountryId(), this.analyticsService.redirectLocationVideoCarousel);
  }

  appStoreLinkClicked(): void {
    this.analyticsService.logAppStoreRedirect(this.countryService.getSelectedCountryId(), this.analyticsService.redirectLocationVideoCarousel);
  }



}
