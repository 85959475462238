<div class="flex orientation wrapper">
  @if (currentVideo === 'line' && lineSelected === 'climateline' && !imageSelected) {
    <img class="main-image" src='../../../assets/images/climateline.png'/>
  }
  @if (currentVideo === 'line' && lineSelected === 'slimline' && !imageSelected) {
    <img class="main-image" src='../../../assets/images/slimline.png'/>
  }
  @if (currentVideo === 'line' && imageSelected === 'airCurve') {
    <img class="main-image" src='../../../assets/images/aircurve11.png'/>
  }
  @if (currentVideo === 'line' && imageSelected === 'airSense') {
    <img class="main-image" src='../../../assets/images/airsense11.png'/>
  }
  @if (currentVideo !== 'line' && currentVideo !== null) {
    <video class="main-image" controls autoplay loop (play)="playAudio(currentAudio)">
      <source [src]="currentVideo" type="video/mp4">
    </video>
  }
  <div class="pta-stepper">
    <mat-horizontal-stepper #stepper (selectionChange)="onStepChange($event)">
      <ng-template matStepperIcon="edit">
        <svg class="c-svg c-svg--checkmark">
          <use xlink:href="#c-svg--checkmark"></use>
        </svg>
      </ng-template>
      @if (!!pta.machineAssets && !!pta.displayMachine) {
        <mat-step #machineStep>
          <ng-template matStepLabel>
            <div class="step-label">{{'PTA.PtaStepperMachineSetup' | translate}}</div>
          </ng-template>
          <div class="flex column space-between padding-top">
            <div>
              {{('PTA.' + currentText) | translate }}
            </div>
            @if (currentVideo === 'line') {
              <div>
                <mat-radio-group class="flex column" [(ngModel)]="lineSelected">
                  <mat-radio-button [value]="'climateline'">{{'PTA.PtaStepperClimateline' | translate}}</mat-radio-button>
                  <mat-radio-button [value]="'slimline'">{{'PTA.PtaStepperSlimline' | translate}}</mat-radio-button>
                </mat-radio-group>
              </div>
            }
            <div class="button-styling">
              @if (currentVideo !== 'line') {
                <button class="c-btn c-btn--tertiary" (click)="determineAudioVideoDescription(false)">{{'PTA.PtaStepperBack' | translate}}</button>
              }
              <button class="c-btn c-btn--primary" (click)="determineAudioVideoDescription(true)">{{'PTA.PtaStepperNext' | translate}}</button>
            </div>
          </div>
        </mat-step>
      }

      @if (!!pta.maskAssets && !!pta.displayMask) {
        <mat-step #maskStep>
          <ng-template matStepLabel>
            <div class="step-label">{{'PTA.PtaStepperMaskSetup' | translate}}</div>
          </ng-template>
          <div class="flex column space-between padding-top">
            <div>
              {{('PTA.' + currentText) | translate }}
            </div>
            <div class="button-styling">
              @if (!!(pta.displayMachine || currentStep !== 0)) {
                <button class="c-btn c-btn--tertiary" (click)="determineAudioVideoDescription(false);">{{'PTA.PtaStepperBack' | translate}}</button>
              }
              <button class="c-btn c-btn--primary" (click)="determineAudioVideoDescription(true)">{{'PTA.PtaStepperNext' | translate}}</button>
            </div>
          </div>
        </mat-step>
      }

      <mat-step #finish>
        <ng-template matStepLabel>
          <div class="step-label">{{'PTA.PtaStepperFinish' | translate}}</div>
        </ng-template>
        <div class="flex column space-between padding-top">
          <div class="congratulations-text">
            <span>{{'PTA.PtaStepperCongratulations' | translate }}</span>
            <br/>
            <br/>
            @if (!pta.doNotLaunchLibraryModal) {
              <span>{{'PTA.PtaStepperCongratulationsLibrary' | translate }}</span>
            }
          </div>
          <div class="button-styling">
            <button class="c-btn c-btn--tertiary" (click)="restartButton()">{{'PTA.PtaStepperRestart' | translate}}</button>
            <button class="c-btn c-btn--primary" (click)="closeModal();">{{'PTA.PtaStepperDone' | translate}}</button>
          </div>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
  <svg class="c-svg c-svg--close-24" (click)="closeModal()">
    <use xlink:href="#c-svg--close-24"></use>
  </svg>
</div>
