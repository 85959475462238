<section id="section1" class="o-section">
  <div class="o-wrapper">

    <div class="o-layout">
      <div class="o-layout__item  [ u-2/4@desktop u-push-1/4@desktop ]">

        <h1 class="c-heading-page">{{ 'ACCEPT-POLICIES.PolicyUpdate' | translate}}</h1>

        <div runat="server" id="divBothPoliciesUpdate">
          <p>{{ 'ACCEPT-POLICIES.UpdatedPolicies' | translate}}</p>
          <p [innerHTML]="'ACCEPT-POLICIES.EncourageToReview' | translate"></p>
        </div>

        <div class="c-form-buttons  [ o-layout o-layout--auto ]">
          <div class="o-layout__item">
            <button (click)="acceptPolicies()" class="c-btn c-btn--primary" data-cy="accept-policies-button">{{ 'ACCEPT-POLICIES.Continue' | translate}}</button>
          </div>
          <div class="o-layout__item">
            <button (click)="cancel()" class="c-btn c-btn--tertiary" data-cy="cancel-policies-button">{{ 'ACCEPT-POLICIES.Cancel' | translate}}</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>
