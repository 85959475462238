import { HttpErrorResponse } from '@angular/common/http';
import { Result, ErrorTypes } from 'src/app/services/showtime-api/response.structure';

export class RegisterApplicationError extends Error {
  message: string;
  errorCode: string;
  startTime: Date;
  endTime: Date;
  defaultMessage = 'myAir is currently under maintenance. Please try again later.';

  constructor(error: HttpErrorResponse) {
    super();
    if (error?.error?.result) {
      const res: Result = error.error.result;
      this.errorCode = res.errorCode;
      if (this.errorCode === ErrorTypes.serviceUnavailable) {
        this.message = res.localizedError || this.defaultMessage;
      }
    }
  }
}
