import { BaseComponent } from 'src/app/components/base/component/base-component';

export enum States {
  IsHidden = 'is-hidden',
  IsAnimating = 'is-animating',
  IsExpanded = 'is-expanded',
  AriaExpanded = 'true',
  AriaNotExpanded = 'false',
}

export abstract class ExpanderBase extends BaseComponent {
  dataState: string[] = [];
  ariaExpanded: string[] = [];
  constructor(dataState: string[], ariaExpanded: string[]) {
    super();
    this.dataState = [...dataState];
    this.ariaExpanded = [...ariaExpanded];
  }

  toggleExpander(index: number): void {
    this.dataState[index] =
      this.dataState[index] === States.IsHidden || this.dataState[index] === States.IsAnimating
        ? States.IsExpanded
        : States.IsAnimating;
    this.ariaExpanded[index] =
      this.ariaExpanded[index] === States.AriaNotExpanded ||
      this.dataState[index] === States.IsExpanded
        ? States.AriaExpanded
        : States.AriaNotExpanded;
  }

  transitionend(index: number, event: TransitionEvent): void {
    if (event.propertyName === 'opacity' && this.dataState[index] === States.IsAnimating) {
      this.dataState[index] = States.IsHidden;
      this.ariaExpanded[index] === States.AriaNotExpanded;
    }
  }
}
