import { Component } from '@angular/core';
import { MaskService } from '../mask.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'mpp-mask-setting-dialog',
  templateUrl: './mask-setting-dialog.component.html',
  styleUrls: ['./mask-setting-dialog.component.scss']
})
export class MaskSettingDialogComponent {
  constructor(
    public maskService: MaskService,
    public languageService: LanguageService
  ) {
      let current2LettersLanguage = this.languageService.getCurrent2LettersLanguage();
      this.maskService.maskMp4Url = `${environment.endpoints.staticMyair}/v1/mask_setup/airfit_f40/${current2LettersLanguage}/airfit_f40_mask_optimization.mp4`;
   }

  close(): void {
    this.maskService.maskSettingDialog.close();
  }
}
