import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin, from, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, filter, first, map, switchMap, take, tap } from 'rxjs/operators';
import { ShowTimeApiService } from '../showtime-api/showtime-api.service';
import { AlertService } from 'src/app/modules/alert/components/services/alert.service';
import { environment } from 'src/environments/environment';
import { CountryId } from 'src/app/types/country.type';
import { LocalStorageService } from '../storage/storage.service';
import { RootObject } from 'src/app/modules/my-account/components/my-account/my-equipment/my-account-mask/models/mask.model';
import { LanguageId, languages2Letters } from 'src/app/types/language.type';
import { SpinnerService } from '../spinner/spinner.service';
@Injectable({
  providedIn: 'root'
})
export class MetadataService extends ShowTimeApiService{
  private readonly CountryIdKey = 'CurrentMyAirCountryId';
  private readonly LanguageIdKey = 'CurrentMyAirLanguageId';
  private readonly consumingVariantKey = 'ConsumingVariantKey';
  public attemptedToLoadLocalMetadata: boolean = false; // Start off false as we haven't attempted to load it
  public attemptedToLoadApiMetadata: boolean = false; // Start off false as we haven't attempted to load it
  public attemptedToLoadMaskMetadata: boolean = false; // Start off false as we haven't attempted to load it
  private metadataLocalSubject: ReplaySubject<any> = new ReplaySubject(1);
  public metadataApiSubject: ReplaySubject<any> = new ReplaySubject(1);
  private maskMetadataSubject: ReplaySubject<any> = new ReplaySubject(1);
  private lastCountryMetadataQueried: CountryId = null;
  public instanceInfo: any = {};

  constructor(
    private http: HttpClient,
    protected alertService: AlertService,
    private httpClient: HttpClient,
    private localStorage: LocalStorageService,
    private spinner: SpinnerService
  ) {
      super(alertService);
      this.metadataApiSubject.next(null);
  }

  public getMetadataFromLocal(nestedKey: string): Observable<any> {  
    return this.metadataLocalSubject.pipe(
      take(1),
      map(data => data && data[nestedKey] ? data[nestedKey] : null),
      tap(data => {
        if (!data) {
          this.showError();
        }
      }),
    );
  }

  public getMetadataFromApi(): Observable<any> {  
    return this.metadataApiSubject.pipe(
      take(1),
      tap(data => {
        if (data) {
          this.instanceInfo = data.instanceInfo;
        }

        if (!data && data !== null) {
          this.showError();
        }
      }),
    );
  }

  public waitForMetadataToLoad(): Promise<any> {
    return this.metadataApiSubject.pipe(
      filter(data => data !== null),
      first()
    ).toPromise();
  }

  public getMaskMetadata(): Observable<any> {  
    return this.maskMetadataSubject.pipe(
      take(1),
      tap(data => {
        if (!data) {
          this.showError();
        }
      }),
    );
  }

  public checkIfMetadataApiLoadNecessary(): Observable<any> {
    if (!this.attemptedToLoadApiMetadata) {
      this.attemptedToLoadApiMetadata = true;  
      return this.updateMetadataFromApi();
    } else {
      return this.metadataApiSubject.asObservable();
    }
  }

  public checkIfMetadataLocalLoadNecessary(): Observable<any> {
    if (!this.attemptedToLoadLocalMetadata) {
      this.attemptedToLoadLocalMetadata = true;  
      this.updateMetadataFromLocal(environment.endpoints.localMetadataMyAir,'myAir_Web').subscribe();
      return of(true);
    } else {
      return of(false);
    }
  }

  public checkIfMaskMetadataLoadNecessary(): void {
    if (!this.attemptedToLoadMaskMetadata) {
      this.attemptedToLoadMaskMetadata = true;  
      this.updateMaskMetadata();
    }
  }

  private updateMetadataFromLocal(url: string, platform: string): Observable<any> {
    return this.http.get(url).pipe(
      tap(
        (data) => {
          this.metadataLocalSubject.next(data[platform]);
        }
      ),
    ) // Since updateMetadataFromLocal() is expected to complete or error out, no explicit unsubscription is strictly necessary here.
  }

  public updateMetadataFromApi(): Observable<any> {
    return this.metadataApiSubject.asObservable().pipe(
      switchMap(data => {
        if (!data) {
          return this.fetchAndUpdateMetadata();
        } else {
          return from(this.checkIfMetadataQuerySwapRequired()).pipe(
            switchMap(shouldSwap => {
              if (shouldSwap) {
                return this.fetchAndUpdateMetadata();
              } else {
                return of(data);
              }
            })
          );
        }
      })
    );
  }
  
  private fetchAndUpdateMetadata(): Observable<any> {
    // Added loading spinner to alleviate loading woes
    const spinner = this.spinner.show();
    return this.getMetadata().pipe(
      switchMap(newData => {
        return this.checkIfMetadataLocalLoadNecessary().pipe(
          map(() => {
            this.instanceInfo = newData.instanceInfo;
            this.metadataApiSubject.next(newData);
            this.localStorage.setItem(
              this.consumingVariantKey,
              !!newData?.instanceInfo?.deprecation?.consumingVariant ? newData?.instanceInfo?.deprecation?.consumingVariant : ''
            );
            spinner.hide();
            return newData;
          })
        );
      })
    );
  }
  

  private updateMaskMetadata(): void {
    this.getMasksList().subscribe(data => {
      this.maskMetadataSubject.next(data);
    }, 
    this.showError());
  }

  public getSelectedCountryId(): CountryId {
    return this.localStorage.getItem(this.CountryIdKey) as CountryId;
  }

  public async checkIfMetadataQuerySwapRequired(): Promise<boolean> {
    if (this.lastCountryMetadataQueried === this.getSelectedCountryId()) {
      return new Promise((resolve, reject) => { resolve(false); });
    }
    return forkJoin([
      this.loadUS_AU_NZCountries(),
      this.loadEUCountries(),
      this.loadAPACCountries(),
      this.loadLATAMCountries()
    ]).pipe(
      first(),
      switchMap(([US_AU_NZCountries, EU_COUNTRIES, APAC_COUNTRIES, LATAM_COUNTRIES]) => {
        if (US_AU_NZCountries.includes(this.getSelectedCountryId()) && US_AU_NZCountries.includes(this.lastCountryMetadataQueried)) {
          return of(false);
        } else if (LATAM_COUNTRIES.includes(this.getSelectedCountryId()) && LATAM_COUNTRIES.includes(this.lastCountryMetadataQueried)) {
          return of(false);
        } else if (this.getSelectedCountryId() === 'CA' && this.lastCountryMetadataQueried === 'CA') {
          return of(false);
        } else if (EU_COUNTRIES.includes(this.getSelectedCountryId()) && EU_COUNTRIES.includes(this.lastCountryMetadataQueried)) {
          return of(false);
        } else if (APAC_COUNTRIES.includes(this.getSelectedCountryId()) && APAC_COUNTRIES.includes(this.lastCountryMetadataQueried)) {
          return of(false);
        } else {
          return of(true);
        }
      })).toPromise();
  }

  private getMetadata(): Observable<any> {
    let queryString: string;

    // To prevent excessive calls, set the country being queried, to be checked later
    this.lastCountryMetadataQueried = this.getSelectedCountryId();

    return forkJoin([
      this.loadUS_AU_NZCountries(),
      this.loadEUCountries(),
      this.loadAPACCountries(),
      this.loadLATAMCountries()
    ]).pipe(
      first(),
      switchMap(([US_AU_NZCountries, EU_COUNTRIES, APAC_COUNTRIES, LATAM_COUNTRIES]) => {
        if (US_AU_NZCountries.includes(this.getSelectedCountryId())) {
          queryString = 'product=myAir';
        } else if (LATAM_COUNTRIES.includes(this.getSelectedCountryId())) {
          queryString = 'product=myAir%20LATAM';
        } else if (this.getSelectedCountryId() === 'CA') {
          queryString = 'product=myAir%20Canada';
        } else if (EU_COUNTRIES.includes(this.getSelectedCountryId())) {
          queryString = 'product=myAir%20EU';
        } else if (APAC_COUNTRIES.includes(this.getSelectedCountryId())) {
          queryString = 'product=myAir%20APAC';
        } else {
          throw new Error('Invalid Country');
        }
    
        return this.httpClient.get(
          `${environment.endpoints.staticMyair}/v2/metadata.json?platform=Web&locale=en&${queryString}`
        );
      }),
      this.showError()
    );
  }

  private loadUS_AU_NZCountries(): Observable<any> {
    return this.getMetadataFromLocal('US_AU_NZ_COUNTRIES');
  }

  private loadEUCountries(): Observable<any> {
    return this.getMetadataFromLocal('EU_COUNTRIES');
  }

  private loadAPACCountries(): Observable<any> {
    return this.getMetadataFromLocal('APAC_COUNTRIES');
  }

  private loadLATAMCountries(): Observable<any> {
    return this.getMetadataFromLocal('LATAM_COUNTRIES');
  }

  private getMasksList(): Observable<RootObject> {
    const params = new HttpParams()
      .set('countryId', this.getSelectedCountryId() || 'US')
      .set('locale', languages2Letters[this.localStorage.getItem(this.LanguageIdKey) as LanguageId]);
  
    return this.http.get<RootObject>(
      `${environment.endpoints.staticMyair}/v2/masks/mask_assets.json`,
      { params, responseType: 'json' }
    ).pipe(
      catchError(() => {
        this.showError();
        return of(null);
      })
    );
  }
  
}
