import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { PatientService } from 'src/app/services/patient/patient.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { BaseEditComponent } from '../../../base-view-edit-components/base-edit/base-edit.component';
import { ComponentState } from '../../../base-view-edit-components/component-state.enum';
import { MyPrivacySettings } from '../my-privacy.component';

@Component({
  selector: 'mpp-share-information-edit',
  templateUrl: './share-information-edit.component.html',
  styleUrls: ['./share-information-edit.component.scss'],
})
export class ShareInformationEditComponent extends BaseEditComponent implements OnInit {
  @Output() shareInformationChanged = new EventEmitter<boolean>();
  @Input() privacySettings: MyPrivacySettings;

  form = this.fb.group({
    shareDetailsWithProviderOptIn: [false],
  });
  constructor(
    private patientService: PatientService,
    private fb: UntypedFormBuilder,
    private spinner: SpinnerService,
    private analyticsService: AnalyticsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.form.controls.shareDetailsWithProviderOptIn.setValue(
      this.privacySettings.shareDetailsWithProviderOptIn,
    );
  }

  async save(): Promise<void> {
    const spinner = this.spinner.show();
    const shareDetailsWithProviderOptIn = this.form.get('shareDetailsWithProviderOptIn').value;

    await this.patientService.setPrivacySettings({ shareDetailsWithProviderOptIn }).toPromise();
    this.analyticsService.checkAndSetAnalytics();

    this.stateChanged.next(ComponentState.View);
    this.shareInformationChanged.next(shareDetailsWithProviderOptIn);
    spinner.hide();
  }
}
