<h2 class="c-heading-main">{{ 'HOME.GetStarted' | translate }}</h2>
<p class="c-text--intro" [innerHTML]="'HOME.EveryJourneyStartsWithFirstStep' | translate">
</p>

<div class="c-login-form">
  <button type="button" class="c-btn c-btn--secondary" tabindex="6" (click)="goToAccountRegistration()">
    {{ 'HOME.CreateAccount' | translate }}
  </button>
</div>
@if (isPreTherapyDiagnosticCountry) {
  <div>
      <div class="text-container">
        {{ 'REGISTRATION-FORM.onboarding_nightowl_not_supported' | translate }}
      </div>
    <div class="c-app-badges [ o-layout o-layout--auto o-layout--small ]">
      <div class="o-layout__item">
        <a (click)="appStoreLinkClicked()" 
          id="lnkAppStoreLinkApple" class="c-apple-badge"
          [style.background-image]="languageService.selectedAppStoreImage"
          [href]="countryService.appStoreLink$ | async"
          title="{{'HOME/VIDEO-CAROUSEL.DownloadAppStore' | translate}}"
          target="_blank">{{'HOME/VIDEO-CAROUSEL.DownloadAppStore' | translate}}</a>
      </div>
      <div class="o-layout__item">
        <a (click)="playStoreLinkClicked()" 
          id="lnkAppStoreLinkAndroid" class="c-android-badge"
          [style.background-image]="languageService.selectedPlayStoreImage"
          [href]="countryService.playStoreLink$ | async"
          title="{{'HOME/VIDEO-CAROUSEL.GetGooglePlay' | translate}}"
          target="_blank">{{'HOME/VIDEO-CAROUSEL.GetGooglePlay' | translate}}</a>
      </div>
    </div>
  </div>
}
