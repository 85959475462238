<div class="c-acc-panel c-acc-panel--header">
  <div class="c-acc-panel__item">
    <div class="c-acc-panel__heading">
      <h2 id="mynotifications" class="c-heading-acc">{{ 'MY-ACCOUNT/MY-NOTIFICATIONS.MyNotifications' | translate}}</h2>
    </div>
  </div>
</div>

<div class="c-acc-panel c-acc-panel--edit">
  <form [formGroup]="form" (ngSubmit)="save()">
    <div class="c-grid">
      <div class="c-grid__row c-grid__row--thead">
        <div class="c-grid__cell">&nbsp;</div>
        <div class="c-grid__cell  c-grid__cell--header">{{ 'MY-ACCOUNT/MY-NOTIFICATIONS.Email' | translate}}</div>
      </div>

      <div class="c-grid__row">
        <div class="c-grid__cell c-grid__cell--label">
          <span>{{ 'MY-ACCOUNT/MY-NOTIFICATIONS.CoachingTips' | translate}}</span>
        </div>
        <div class="c-grid__cell">
          <div class="c-input c-input--checkbox has-no-label">
            <input formControlName="coachingEmailEnabled" id="coachingEmailEnabled" name="coachingEmailEnabled"
              type="checkbox">
            <label for="coachingEmailEnabled">&nbsp;</label>
          </div>
        </div>
      </div>

      <div class="c-grid__row c-grid__row--mobile-header">
        <div class="c-grid__cell">{{ 'MY-ACCOUNT/MY-NOTIFICATIONS.Email' | translate}}</div>
      </div>

      <div class="c-grid__row">
        <div class="c-grid__cell c-grid__cell--label">
          <span>{{ 'MY-ACCOUNT/MY-NOTIFICATIONS.CleaningAndInspectionReminders' | translate}}</span>
        </div>
        <div class="c-grid__cell">
          <div class="c-input c-input--checkbox has-no-label">
            <input formControlName="cleaningEmailEnabled" type="checkbox" id="cleaningEmailEnabled"
              name="cleaningEmailEnabled">
            <label for="cleaningEmailEnabled">&nbsp;</label>
          </div>
        </div>
      </div>

      <div class="c-grid__row c-grid__row--mobile-header">
        <div class="c-grid__cell">{{ 'MY-ACCOUNT/MY-NOTIFICATIONS.Email' | translate}}</div>
      </div>
    </div>

    <div class="c-form-buttons  [ o-layout o-layout--auto ]">
      <div class="o-layout__item">
        <button type="submit"
          class="c-btn c-btn--primary">{{ 'MY-ACCOUNT/MY-NOTIFICATIONS.Save' | translate}}</button>
      </div>
      <div class="o-layout__item">
        <a class="c-btn c-btn--tertiary" (click)="cancel()">{{ 'MY-ACCOUNT/MY-NOTIFICATIONS.Cancel' | translate}}</a>
      </div>
    </div>
  </form>
</div>
