<div class="u-margin-bottom-large">
  <div class="c-acc-panel c-acc-panel--header no-btn">
    <div class="c-acc-panel__item">
      <div class="c-acc-panel__heading">
        <h2 id="accountdetails" class="c-heading-acc">{{ 'MY-ACCOUNT/ACCOUNT-DETAILS.AccountDetails' | translate}}</h2>
      </div>
    </div>
  </div>

  <div class="c-acc-panel--group">
    <div class="c-acc-panel--group__item has-all-attr">
      @if (emailStateValue === componentState.View) {
        <mpp-email-view [email]="email" (stateChanged)="emailStateValue = $event"
        ></mpp-email-view>
      }

      @if (emailStateValue === componentState.Edit) {
        <mpp-email-edit [email]="email" (emailChagned)="onEmailChange($event)"
          (stateChanged)="emailStateValue = $event">
        </mpp-email-edit>
      }

      @if (emailStateValue === componentState.ApproveChanges) {
        <mpp-email-changed [email]="email"
          (stateChanged)="emailStateValue = $event">
        </mpp-email-changed>
      }
    </div>
  </div>

  <!-- <div class="c-acc-panel--group">
  <div class="c-acc-panel--group__item has-all-attr">
    <mpp-password-view *ngIf="passwordStateValue === componentState.View" (stateChanged)="passwordStateValue = $event"></mpp-password-view>
    <mpp-password-edit *ngIf="passwordStateValue === componentState.Edit" [email]="email" (passwordChanged)="onPasswordChange()" (stateChanged)="passwordStateValue = $event"></mpp-password-edit>
  </div>
</div> -->
</div>
