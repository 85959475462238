export enum ErrorCodes {
  patientAlreadyExists = 'patientAlreadyExists',
  internalServerError = 'internalServerError',
  emailInUse = 'emailInUse',
  doesNotMeetPasswordRequirements = 'doesNotMeetPasswordRequirements',
  invalidAccountPassword = 'invalidAccountPassword',
  pendingAccountDeletion = 'pendingAccountDeletion',
  tooManyRequests = 'tooManyRequests'

}

export enum ErrorTypes {
  badRequest = 'badRequest',
  internalServerError = 'internalServerError',
  httpError = 'httpError',
  serviceUnavailable = 'serviceUnavailable',
  tooManyRequests = 'tooManyRequests'
}

export interface Location {
  line: number;
  column: number;
  sourceName?: unknown;
}

export interface Result {
  success: boolean;
  errorType: ErrorTypes;
  errorCode: ErrorCodes;
  additionalInfo?: { [key: string]: any };
  localizedError?: string;
}

export interface ResponseResult {
  result: Result;
  entity: ResponseResultEntity | ResponseResultPatientMessaging;
}

export interface ResponseResultEntity {
  content: string
}

export interface ResponseResultPatientMessaging {
  header: string,
  body: string,
  iconUrl: string,
  link: string
}
