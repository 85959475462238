import { Component, Input } from '@angular/core';
import { BaseViewComponent } from '../../../base-view-edit-components/base-view/base-view.component';
import { MyPrivacySettings } from '../my-privacy.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'mpp-share-information-view',
  templateUrl: './share-information-view.component.html',
  styleUrls: ['./share-information-view.component.scss'],
})
export class ShareInformationViewComponent extends BaseViewComponent {
  @Input() privacySettings: MyPrivacySettings;
  public isEU = environment.isEU;
}
