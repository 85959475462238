<form [formGroup]="form" class="c-form--has-validation">
  <div id="modal-InitiateDeleteAccount" class="c-modal-material c-modal--confirm">
    <div class="c-modal__header">
      <h2 class="c-modal__heading has-icon">
        <svg class="c-svg c-svg--warning-circle-outline-24">
          <use xlink:href="#c-svg--warning-circle-outline-24"></use>
        </svg>{{'MY-ACCOUNT/MY-PRIVACY.PermanentlyDelete.DeleteAccount' | translate}}
      </h2>
    </div>
    <div class="c-modal__body">
      <h3 class="c-heading-main">{{'MY-ACCOUNT/MY-PRIVACY.PermanentlyDelete.RequireYourPassword' | translate}}</h3>
      <div class="c-form-field">
        <label
        class="c-form-field__label" for="password">{{'MY-ACCOUNT/MY-PRIVACY.PermanentlyDelete.EnterYourPassword' | translate}}</label>
        <div
          class="c-form-field__input c-input--validation"
          [attr.data-form-field-status]="(form.get('password').getError('required') && form.get('password').touched) || (form.get('password').getError('invalid') && !form.get('password').touched) ? 'error' : ''">
          <input id="password" formControlName="password" type="password" class="c-input c-input--text js-get-password">
        </div>
        @if (form.get('password').getError('invalid') && !form.get('password').touched) {
          <div class="c-form-field__error" role="alert" aria-live="assertive">{{"MY-ACCOUNT/ACCOUNT-DETAILS.InvalidAccountPassword" | translate}}</div>
        }
        @if (form.get('password').getError('required') && form.get('password').touched) {
          <div class="c-form-field__error" role="alert" aria-live="assertive">{{"MY-ACCOUNT/ACCOUNT-DETAILS.InvalidAccountPassword" | translate}}</div>
        }
        <div class="c-form-field">
          <label
          class="c-form-field__label" for="feedback">{{'MY-ACCOUNT/MY-PRIVACY.PermanentlyDelete.ProvideFeedback' | translate}}</label>
          <input id="feedback" formControlName="feedback" type="text" class="c-input c-input--text js-get-feedback" maxlength="500">
        </div>
      </div>
    </div>
    <div class="c-modal__footer">
      <div class="o-layout o-layout--auto@tablet">
        <div class="o-layout__item">
          <button [disabled]="!form.valid" class="c-modal__warnConfirm-btn [ c-btn c-btn--primary ]"
          (click)="deleteAccount()">{{'MY-ACCOUNT/MY-PRIVACY.PermanentlyDelete.YesDeleteAccount' | translate}}</button>
        </div>
        <div class="o-layout__item">
          <button class="c-modal__warnCancel-btn [ c-btn c-btn--tertiary ]" (click)="cancel()">{{'MY-ACCOUNT/MY-PRIVACY.Cancel' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</form>
