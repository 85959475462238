import { Component, EventEmitter, Output } from '@angular/core';
import { ComponentState } from '../../../base-view-edit-components/component-state.enum';

@Component({
  selector: 'mpp-notifications-error',
  templateUrl: './notifications-error.component.html',
  styleUrls: ['./notifications-error.component.scss'],
})
export class NotificationsErrorComponent {
  @Output() stateChanged = new EventEmitter<ComponentState>();

  cancel(): void {
    this.stateChanged.next(ComponentState.View);
  }
}
