import { Component, Input } from '@angular/core';
import { ComponentState } from 'src/app/modules/my-account/components/base-view-edit-components/component-state.enum';
import { NotificationPreferences } from './types/notification-preferences.type';

@Component({
  selector: 'mpp-my-notifications',
  templateUrl: './my-notifications.component.html',
  styleUrls: ['./my-notifications.component.scss'],
})
export class MyNotificationsComponent {
  @Input() notificationPreferences: NotificationPreferences;

  state: ComponentState = ComponentState.View;
  componentState = ComponentState;
  notificationsChanged(preferences: NotificationPreferences): void {
    this.notificationPreferences = preferences;
  }
}
