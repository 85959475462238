import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MyPrivacyComponent } from './components/my-account/my-privacy/my-privacy.component';
import { AllowAnalyticsViewComponent } from './components/my-account/my-privacy/allow-analytics-view/allow-analytics-view.component';
import { AllowAnalyticsEditComponent } from './components/my-account/my-privacy/allow-analytics-edit/allow-analytics-edit.component';
import { MyHealthProfileComponent } from './components/my-account/my-health-profile/my-health-profile.component';
import { HealthProfileEditComponent } from './components/my-account/my-health-profile/health-profile-edit/health-profile-edit.component';
import { AddHealthProfileComponent } from './components/my-account/my-health-profile/health-profile-edit/add-health-profile/add-health-profile.component';
import { MyAccountComponent } from './components/my-account/my-account.component';
import { MyEquipmentComponent } from './components/my-account/my-equipment/my-equipment.component';
import { MyAccountDeviceComponent } from './components/my-account/my-equipment/my-account-device/my-account-device.component';
import { MyAccountMaskComponent } from './components/my-account/my-equipment/my-account-mask/my-account-mask.component';
import { AddMaskComponent } from './components/my-account/my-equipment/my-account-mask/add-mask/add-mask.component';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { MyAccountRoutingModule } from './my-account-routing.module';
import { AccountDetailsComponent } from './components/my-account/account-details/account-details.component';
import { EmailViewComponent } from './components/my-account/account-details/email-view/email-view.component';
import { EmailEditComponent } from './components/my-account/account-details/email-edit/email-edit.component';
import { EmailChangedComponent } from './components/my-account/account-details/email-changed/email-changed.component';
import { DeleteAccountComponent } from './components/my-account/my-privacy/delete-account/delete-account.component';
import { ConfirmDeletionComponent } from './components/my-account/my-privacy/delete-account/dialogs/confirm-deletion/confirm-deletion.component';
import { PermanentlyDeleteComponent } from './components/my-account/my-privacy/delete-account/dialogs/permanently-delete/permanently-delete.component';
import { AboutMeComponent } from './components/my-account/about-me/about-me.component';
import { AboutMeViewComponent } from './components/my-account/about-me/about-me-view/about-me-view.component';
import { AboutMeEditComponent } from './components/my-account/about-me/about-me-edit/about-me-edit.component';
import { AddDateOfBirthComponent } from './components/my-account/about-me/about-me-edit/add-date-of-birth/add-date-of-birth.component';
import { MyNotificationsComponent } from './components/my-account/my-notifications/my-notifications.component';
import { NotificationsViewComponent } from './components/my-account/my-notifications/notifications-view/notifications-view.component';
import { NotificationsEditComponent } from './components/my-account/my-notifications/notifications-edit/notifications-edit.component';
import { NotificationsErrorComponent } from './components/my-account/my-notifications/notifications-error/notifications-error.component';
import { FullDatePipe } from './components/my-account/about-me/pipes/full-date.pipe';
import { SerialNumberHintComponent } from './components/my-account/my-equipment/my-account-device/add-device/serial-number-hint/serial-number-hint.component';
import { DeviceNumberHintComponent } from './components/my-account/my-equipment/my-account-device/add-device/device-number-hint/device-number-hint.component';
import { ShareInformationViewComponent } from './components/my-account/my-privacy/share-information-view/share-information-view.component';
import { ShareInformationEditComponent } from './components/my-account/my-privacy/share-information-edit/share-information-edit.component';
import { PasswordViewComponent } from './components/my-account/account-details/password-view/password-view.component';
import { PasswordEditComponent } from './components/my-account/account-details/password-edit/password-edit.component';
import { DownloadMyDataViewComponent } from './components/my-account/my-privacy/download-my-data-view/download-my-data-view.component';
import { DownloadMyDataEditComponent } from './components/my-account/my-privacy/download-my-data-edit/download-my-data-edit.component';
import { ReceiveAdditionalInformationViewComponent } from './components/my-account/my-privacy/receive-additional-information-view/receive-additional-information-view.component';
import { ReceiveAdditionalInformationEditComponent } from './components/my-account/my-privacy/receive-additional-information-edit/receive-additional-information-edit.component';
import { DataEmailSentComponent } from './components/my-account/my-privacy/download-my-data-edit/dialogs/data-email-sent/data-email-sent.component';
import { AllowPersonalizedCoachingViewComponent } from './components/my-account/my-privacy/allow-personalized-coaching-view/allow-personalized-coaching-view.component';
import { AllowPersonalizedCoachingEditComponent } from './components/my-account/my-privacy/allow-personalized-coaching-edit/allow-personalized-coaching-edit.component';
import { MaskSettingDialogComponent } from './components/my-account/my-equipment/my-account-mask/mask-setting-dialog/mask-setting-dialog.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

@NgModule({
  declarations: [
    MyPrivacyComponent,
    AllowAnalyticsViewComponent,
    AllowAnalyticsEditComponent,
    MyHealthProfileComponent,
    HealthProfileEditComponent,
    AddHealthProfileComponent,
    AddMaskComponent,
    MyAccountComponent,
    MyEquipmentComponent,
    MyAccountDeviceComponent,
    OnlyNumbersDirective,
    MyAccountMaskComponent,
    AccountDetailsComponent,
    EmailViewComponent,
    EmailEditComponent,
    EmailChangedComponent,
    DeleteAccountComponent,
    ConfirmDeletionComponent,
    PermanentlyDeleteComponent,
    AboutMeComponent,
    AboutMeViewComponent,
    AboutMeEditComponent,
    AddDateOfBirthComponent,
    MyNotificationsComponent,
    NotificationsViewComponent,
    NotificationsEditComponent,
    NotificationsErrorComponent,
    FullDatePipe,
    SerialNumberHintComponent,
    DeviceNumberHintComponent,
    ShareInformationViewComponent,
    ShareInformationEditComponent,
    PasswordViewComponent,
    PasswordEditComponent,
    DownloadMyDataViewComponent,
    DownloadMyDataEditComponent,
    ReceiveAdditionalInformationViewComponent,
    ReceiveAdditionalInformationEditComponent,
    DataEmailSentComponent,
    AllowPersonalizedCoachingViewComponent,
    AllowPersonalizedCoachingEditComponent,
    MaskSettingDialogComponent,
  ],
  imports: [CommonModule, SharedModule, ReactiveFormsModule, MatDialogModule, FormsModule, MyAccountRoutingModule],
  exports: [
    AddHealthProfileComponent,
    AddMaskComponent,
    AddDateOfBirthComponent,
  ],
})
export class MyAccountModule {}
