export const startTherapyGroupIds = [
  'justStarting',
  'threeToTwelveMonthsAgo',
  'moreThanOneYearAgo',
  'moreThanFiveYearsAgo',
];

export const startTherapyGroupNames = [
  'DATA/START-THERAPY-GROUP.JustStarting',
  'DATA/START-THERAPY-GROUP.ThreeToTwelveMonths',
  'DATA/START-THERAPY-GROUP.MoreOneYear',
  'DATA/START-THERAPY-GROUP.MoreFiveYears',
];

export type StartTherapyGroupId = typeof startTherapyGroupIds[number];
export type StartTherapyGroupName = typeof startTherapyGroupNames[number];

export const startTherapyGroups: Record<StartTherapyGroupId, StartTherapyGroupName> = {
  justStarting: 'DATA/START-THERAPY-GROUP.JustStarting',
  threeToTwelveMonthsAgo: 'DATA/START-THERAPY-GROUP.ThreeToTwelveMonths',
  moreThanOneYearAgo: 'DATA/START-THERAPY-GROUP.MoreOneYear',
  moreThanFiveYearsAgo: 'DATA/START-THERAPY-GROUP.MoreFiveYears',
};
