import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AlertService } from 'src/app/modules/alert/components/services/alert.service';
import { ShowTimeApiService } from 'src/app/services/showtime-api/showtime-api.service';
import { ResponseResult } from 'src/app/services/showtime-api/response.structure';
import { PatientInfoV2, PatientInfoWithConsent } from './models/patient-info.model';
import { MetadataService } from 'src/app/services/metadata/metadata.service';
import { UpdatePatientInput } from 'src/graphql/types/api.types';
import { ApolloService } from 'src/app/services/apollo/apollo.service';
import * as mutations from 'src/graphql/mutations';
import { gql } from 'apollo-angular';
import { GraphQLError } from 'src/app/services/apollo/graphql-payload.type';

@Injectable({
  providedIn: 'root',
})
export class UserRegistrationService extends ShowTimeApiService {
  // Use this service to temporarily store device and serial numbers across screens
  public deviceNumber: string;
  public serialNumber: string;

  constructor(protected http: HttpClient, protected messageService: AlertService, private metadata: MetadataService, private apolloService: ApolloService) {
    super(messageService);
  }

  registerPatientV2(
    patientInfoV2: PatientInfoV2,
  ): Observable<ResponseResult> {
    return this.http
      .post<ResponseResult>(`${this.metadata.instanceInfo.restUrl}/V2/Patient/PatientRegistration`,
      patientInfoV2)
      .pipe(this.showError());
  }

  registerPatientWithConsent(
    patientInfo: PatientInfoWithConsent,
  ): Observable<[GraphQLError, UpdatePatientInput]> {
      // Convert to GraphQL format
      return this.apolloService.mutate<UpdatePatientInput>({
        mutation: gql(mutations.updatePatient),
        variables: {
          input: {
            ...patientInfo
          }
        },
      });
  }

  validateDevice(
    serialNumber: string,
    deviceNumber: string,
  ): Observable<ResponseResult> {
    return this.http
      .post<ResponseResult>(`${this.metadata.instanceInfo.restUrl}/V1/Patient/validateFgDeviceNoUser`, {
          serialNumber: serialNumber,
          deviceNumber: deviceNumber
      })
      .pipe(this.showError());
  }

  validateDeviceAuthenticated(
    serialNumber: string,
    deviceNumber: string,
  ): Observable<ResponseResult> {
    return this.http
      .post<ResponseResult>(`${this.metadata.instanceInfo.restUrl}/V2/Patient/validateFgDevice`, {
          serialNumber: serialNumber,
          deviceNumber: deviceNumber
      })
      .pipe(this.showError());
  }
}
