import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CountryService } from 'src/app/services/country/country.service';
@Component({
  selector: 'mpp-legal-disclaimer',
  templateUrl: './legal-disclaimer.component.html',
})
export class LegalDisclaimerComponent implements OnInit {
  countryTranslationMap = { 'AT': 'FOOTER.LegalDisclaimerAustria','DE': 'FOOTER.LegalDisclaimerGermany','CH': 'FOOTER.LegalDisclaimerSwitzerland'};
  countryId = '';
  constructor(
    private dialogRef: MatDialogRef<LegalDisclaimerComponent>,
    private countryService: CountryService
  ) {}
  ngOnInit(): void {
    this.countryId = this.countryService.getSelectedCountryId();
  }

  close(): void {
    this.dialogRef.close();
  }
}
