<section class="o-section o-section@mobile">
  <div class="o-wrapper">
    <div class="o-layout">
      <div class="o-layout__item u-1/3@tablet">
        <div class="c-features">
          <div class="c-features__item">
            <div class="c-features__img">
              <img src="/assets/images/demo-sleepScore@2x-min.png"
                [alt]="'HOME/FEATURE-DESCRIPTION.myAirScore' | translate"/>
              <div class="c-features__demo-dial">
                <h5 class="demo__myair">{{ 'HOME/FEATURE-DESCRIPTION.myAirScoreImgDescription' | translate }}</h5>
                <h4 class="demo__points">
                  <span>{{ 'HOME/FEATURE-DESCRIPTION.100Points' | translate: {points: maxPoints} }}</span>
                </h4>
              </div>
            </div>
            <h2 class="c-heading-main">{{ 'HOME/FEATURE-DESCRIPTION.myAirScore' | translate }}</h2>
            <p class="c-features-bar__text">
              {{ 'HOME/FEATURE-DESCRIPTION.HowWellDidYouSleep' | translate }}
            </p>
          </div>
        </div>
      </div>

      <div class="o-layout__item u-1/3@tablet">
        <div class="c-features">
          <div class="c-features__item">
            <div class="c-features__img">
              <img src="/assets/images/demo-sleepMetrics@2x.min.png"
                [alt]="'HOME/FEATURE-DESCRIPTION.SleepData' | translate" />
            </div>
            <h2 class="c-heading-main">{{ 'HOME/FEATURE-DESCRIPTION.SleepData' | translate }}</h2>
            <p class="c-features__text">
              {{ 'HOME/FEATURE-DESCRIPTION.WantMoreDetailsAboutYourTherapy' | translate }}
            </p>
          </div>
        </div>
      </div>

      <div class="o-layout__item u-1/3@tablet">
        <div class="c-features">
          <div class="c-features__item">
            <div class="c-features__img">
              <img src="/assets/images/demo-coaching@2x.min.png"
                [alt]="'HOME/FEATURE-DESCRIPTION.CoachingTips' | translate" />
            </div>
            <h2 class="c-heading-main">{{ 'HOME/FEATURE-DESCRIPTION.CoachingTips' | translate }}</h2>
            <p class="c-features__text">
              {{ 'HOME/FEATURE-DESCRIPTION.WantToIncreaseYourComfort' | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!--
      Not needed for US only - will be needed later for rest of world.
    <div class="u-margin-top-large u-text-align-center">
      <a href="https://www.resmed.com/myair" class="c-btn c-btn--primary"
        target="_blank">{{ 'HOME/FEATURE-DESCRIPTION.WantToKnowMore' | translate }}</a>
    </div>
    -->

    <div class="myair-usage-disclaimer--non-us">
      <p>
        <sup>&#42;</sup>{{ 'HOME/FEATURE-DESCRIPTION.myAirCanBeUserdWithAirSense10' | translate }}
        {{ 'HOME/FEATURE-DESCRIPTION.myAirIsNotIntendedToReplace' | translate }}
      </p>
    </div>
  </div>
</section>

@if (showMobileAppsBanner) {
  <section class="c-promo c-promo--app">

    <div class="o-wrapper">

      <div class="c-promo__img"></div>

      <div class="c-promo__body">
        <h3 class="c-promo__heading" innerHTML="{{'HOME/FEATURE-DESCRIPTION.TrackTherapyWithmyAirForAir10' | translate}}"></h3>
      </div>

      <div class="c-promo--app_download [ o-layout o-layout--auto o-layout--small ]">
        <div class="o-layout__item">
          <a (click)="appStoreLinkClicked()"
          [href]="countryService.appStoreLink$ | async"
            class="c-apple-badge"
            [style.background-image]="languageService.selectedAppStoreImage"
            [title]="'HOME/FEATURE-DESCRIPTION.DownloadOnTheAppStore' | translate"
            target="_blank">{{ 'HOME/FEATURE-DESCRIPTION.DownloadOnTheAppStore' | translate }}</a>
        </div>
        <div class="o-layout__item">
          <a (click)="playStoreLinkClicked()" 
            [href]="countryService.playStoreLink$ | async" class="c-android-badge"
            [style.background-image]="languageService.selectedPlayStoreImage"
            [title]="'HOME/FEATURE-DESCRIPTION.GetItOnGooglePlay' | translate"
            target="_blank">{{ 'HOME/FEATURE-DESCRIPTION.GetItOnGooglePlay' | translate }}</a>
        </div>
      </div>

    </div>

  </section>
}