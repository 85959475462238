import { Directive, OnInit, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
@Directive({
  selector: '[mppOnlyNumbers]',
})
export class OnlyNumbersDirective implements OnInit {
  @Input('mppOnlyNumbers') type;
  constructor(private ngControl: NgControl) {}
  regex = {
    integer: /[^0-9]*/g,
    float: /[^0-9.]*/g,
  };
  ngOnInit(): void {
    this.ngControl.valueChanges.subscribe((value: string) => {
      // replace any user input that's not a 0-9 (integer) or 0-9 . (float) with an empty string
      this.ngControl.control.setValue(value.replace(this.regex[this.type], ''), {
        emitEvent: false,
      });
    });
  }
}
