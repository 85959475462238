<form [formGroup]="form" (ngSubmit)="save()">
  <div class="c-acc-panel c-acc-panel--edit">
    <div class="c-form-field">
      <label class="c-form-field__label">
        <span>{{ 'MY-ACCOUNT/MY-PRIVACY.AllowMyAirAnalytics' | translate}}</span>
      </label>
      <div class="c-form-field__input">
        <div class="c-input c-input--checkbox">
          <input formControlName="allowAnalytics" type="checkbox" id="allowAnalytics">
          <label class="c-form-field__label" for="allowAnalytics">{{ 'MY-ACCOUNT/MY-PRIVACY.AllowMyAirAnalytics' | translate}}</label>
        </div>
      </div>
    </div>

    <div class="c-form-buttons  [ o-layout o-layout--auto ]">
      <div class="o-layout__item">
        <button type="submit" class="c-btn c-btn--primary">{{ 'MY-ACCOUNT/MY-PRIVACY.Save' | translate}}</button>
      </div>
      <div class="o-layout__item">
        <a class="c-btn c-btn--tertiary" (click)="cancel()">{{ 'MY-ACCOUNT/MY-PRIVACY.Cancel' | translate}}</a>
      </div>
    </div>
  </div>
</form>
