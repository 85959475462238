<div class="u-margin-bottom-large">

  <div class="js-acc-panel is-active" style="display:block;">

    <div class="c-acc-panel c-acc-panel--header">
      <div class="c-acc-panel__item">
        <div class="c-acc-panel__heading">
          <h2 id="aboutme" class="c-heading-acc">{{ 'MY-ACCOUNT/ABOUT-ME.AboutMe' | translate}}</h2>
        </div>
        @if (aboutMeStateValue === aboutMeState.View) {
          <div class="c-acc-panel__btn">
            <a class="c-link-ui" (click)="aboutMeStateValue = aboutMeState.Edit">{{ 'MY-ACCOUNT.Change' | translate}}</a>
          </div>
        }
      </div>
    </div>

    @if (aboutMeStateValue === aboutMeState.View) {
      <mpp-about-me-view
        [timeZone]="currentTimezone"
        [timeZoneId]="currentTimezoneId"
        [aboutMeDetails]="aboutMeDetails"
        [isJapan]="isJapan"
      (stateChanged)="aboutMeStateValue = $event"></mpp-about-me-view>
    }
    @if (aboutMeStateValue === aboutMeState.Edit) {
      <mpp-about-me-edit
        [allTimezones]="allTimezones"
        [aboutMeDetails]="aboutMeDetails"
        [isJapan]="isJapan"
        (stateChanged)="aboutMeStateValue = $event"
      (aboutMeChanged)="onAboutMeChange($event)"></mpp-about-me-edit>
    }
  </div>

</div>
