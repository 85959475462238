import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatStep } from '@angular/material/stepper';
import { PtaService } from 'src/app/services/pta.service';
import { environment } from 'src/environments/environment';

class Step {
  audioPath: string;
  videoPath: string;
  description: string;
}

@Component({
  selector: 'mpp-pta-stepper',
  templateUrl: './pta-stepper.component.html',
  styleUrls: ['./pta-stepper.component.scss']
})
export class PtaStepperComponent implements OnInit, OnDestroy {
  @ViewChild('machineStep') machineStep!: MatStep;
  @ViewChild('maskStep') maskStep!: MatStep;
  @ViewChild('finish') finish!: MatStep;
  @ViewChild('stepper') stepper!: any;
  public currentText: string;
  public currentVideo: string;
  public currentAudio: string;
  public imageSelected: string = null;
  public lineSelected: string = 'climateline';
  private audioRef: HTMLAudioElement = null;
  public currentStep: number = 0;
  private currentSteps: Step[] = [];
  public selectedIndex: number = 0;
  private stepZeroRestarts: boolean = true;

  constructor(private renderer: Renderer2, private el: ElementRef, public pta: PtaService, private dialogRef: MatLegacyDialogRef<PtaStepperComponent>) {}
  ngOnDestroy(): void {
    // Avoid lingering audio
    if (this.audioRef) {
      this.audioRef.pause();
    }
  }

  ngOnInit(): void {
    // Start every modal initiation at the beginning
    this.restart();
  }

  restart(): void {
    // Code to bring us back to the beginning
    if (this.pta.displayMachine) {
      this.currentText = this.pta.machineAssets.description;
      this.currentAudio = `${environment.endpoints.staticMyair}${this.pta.machineAssets.audioPath}`;
      this.currentVideo = 'line';
      this.imageSelected = null;
      this.selectedIndex = 0;
      this.playAudio(this.currentAudio);
    } else {
      this.stepZeroRestarts = false;
      this.currentStep = 0;
      this.selectedIndex = 0;
      this.currentSteps = this.pta.maskAssets;
      if (this.audioRef) {
        this.audioRef.pause();
      }
      this.audioRef = new Audio(this.currentAudio);
      this.onStepChange({selectedIndex: this.selectedIndex} as StepperSelectionEvent);
      this.setAudioVideoTextByStep();
    }
  }

  restartButton(): void {
    // Finish step button code
    if (this.pta.displayMachine) {
      this.machineStep.completed = false;
    }
    if (this.maskStep) {
      this.maskStep.completed = false;
    }
    if (this.finish) {
      this.finish.completed = false;
    }
    this.stepper.reset();
    this.setClassToStepHeaders();
    this.imageSelected = null;
    this.restart();
  }

  playAudio(audio: string): void {
    // Insure audio stops and starts appropriately when content loads
    this.stepZeroRestarts = true;
    if (this.audioRef) {
      this.audioRef.pause();
    }
    this.audioRef = new Audio(audio);
    this.audioRef.play();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  determineAudioVideoDescription(goingForward: boolean): void {
    if (this.currentVideo === 'line') {
      this.currentStep = 0;
      this.setAppropriateLine();
      this.setAudioVideoTextByStep()
    } else if (goingForward) {
      // Going forward on the stepper
      // Machine tab
      if (this.selectedIndex === 0) {
        // Navigating through steps
        if (this.currentStep < (this.currentSteps.length - 1)) {
          this.currentStep = this.currentStep + 1;
          this.setAudioVideoTextByStep();
        } else if (this.maskStep && this.pta.displayMachine) {
          // Moving to the next index
          this.currentStep = 0;
          this.selectedIndex = this.selectedIndex + 1;
          this.currentSteps = this.pta.maskAssets
          this.onStepChange({selectedIndex: this.selectedIndex} as StepperSelectionEvent);
          this.stepper.next()
          this.setAudioVideoTextByStep();
        } else {
          this.setFinalStep();
        }
        // Mask tab
      } else if (this.selectedIndex === 1 && this.maskStep) {
        // Navigating through steps
        if (this.currentStep < (this.currentSteps.length - 1)) {
          this.currentStep = this.currentStep + 1;
          this.setAudioVideoTextByStep();
        } else {
          this.setFinalStep();
        }
        // End tab
      } else {
        // Close the modal and dismiss the notification
      }
    } else {
      // Going backward on the stepper
      // Machine tab
      if (this.selectedIndex === 0) {
        // Navigating through steps
        if (this.currentStep > 0) {
          this.currentStep = this.currentStep - 1;
          this.setAudioVideoTextByStep();
        } else {
          this.restart();
        }
        // Mask tab
      } else if (this.selectedIndex === 1 && this.maskStep) {
        // Navigating through steps
        if (this.currentStep > 0) {
          this.currentStep = this.currentStep - 1;
          this.setAudioVideoTextByStep();
        } else {
          this.selectedIndex = 0;
          this.stepZeroRestarts = false;
          this.onStepChange({selectedIndex: this.selectedIndex} as StepperSelectionEvent);
          this.stepper.previous();
          this.stepZeroRestarts = true;
          this.currentStep = this.getMachineLength() - 1;
          this.setAppropriateLine();
          this.setAudioVideoTextByStep();
       }
        // End tab
      } else {
        // Nothing happens
      }
    }
  }

  setFinalStep(): void {
    // Moving to the next index
    this.onStepChange({selectedIndex: this.selectedIndex} as StepperSelectionEvent);
    this.stepper.next();
  }

  setAppropriateLine(): void {
    // Set the appropriate machine content based on climateline or slimline selection
    if (this.lineSelected === 'climateline') {
      this.currentSteps = this.pta.machineAssets.content[0].steps;
    } else {
      this.currentSteps = this.pta.machineAssets.content[1].steps;
    }
  }

  getMachineLength(): number {
    // Obtain the current length of the machine step
    if (this.lineSelected === 'climateline') {
      return this.currentSteps = this.pta.machineAssets.content[0].steps.length;
    } else {
      return this.currentSteps = this.pta.machineAssets.content[1].steps.length;
    }
  }

  setAudioVideoTextByStep(): void {
    // Utilize the current step to reset the video, audio, and descripton
    this.currentVideo = null;
    this.currentAudio = `${environment.endpoints.staticMyair}${this.currentSteps[this.currentStep].audioPath}`;
    this.currentText = this.currentSteps[this.currentStep].description;
    setTimeout(() => {
      this.currentVideo = `${environment.endpoints.staticMyair}${this.currentSteps[this.currentStep].videoPath}`;
    }, 0);

  }

  onStepChange(event: StepperSelectionEvent): void {
    // Bump up if we don't have a matching mask
    if (!this.maskStep && event.selectedIndex === 1) {
      event.selectedIndex = event.selectedIndex + 1;
    }
    if (event.selectedIndex === 0) {
      this.selectedIndex = 0;
      if (this.stepZeroRestarts) {
        this.restart();
        this.currentStep = 0;
      }
      if (this.machineStep) {
        this.machineStep.completed = false;
      }
      if (this.maskStep) {
        this.maskStep.completed = false;
      }
      if (this.finish) {
        this.finish.completed = false;
      }
      this.setClassToStepHeaders();
    } else if (event.selectedIndex === 1 && !!this.pta.displayMachine) {
      this.selectedIndex = 1;
      this.currentSteps = this.pta.maskAssets;
      this.currentStep = 0;
      this.setAudioVideoTextByStep();
      if (this.machineStep) {
        this.machineStep.completed = true;
      }
      if (this.maskStep) {
        this.maskStep.completed = true;
      }
      if (this.finish) {
        this.finish.completed = false;
      }
      this.setClassToStepHeaders();
    } else if (event.selectedIndex === 2 || (!this.pta.displayMachine && event.selectedIndex === 1)) {
      this.selectedIndex = 2;
      this.currentStep = 0;
      this.imageSelected = this.pta.machineSelected;
      this.audioRef.pause();
      if (event.selectedIndex === 1 || (this.pta.displayMachine && !this.pta.displayMask)) {
        this.currentText = "Congratulations! You’re all set up. Press the button on the top of your machine when you’re ready to start therapy at bedtime.";
      } else if (event.selectedIndex === 2) {
        this.currentText = "Congratulations! You’re all set up. Press the button on the top of your machine when you’re ready to start therapy at bedtime.\n\nAt any time, you can access the Library to get information about your therapy. We’ll show you where the Library is now so it’s easier to find in the future.";
      }
      this.currentVideo = 'line';
      if (this.machineStep) {
        this.machineStep.completed = true;
      }
      if (this.maskStep) {
        this.maskStep.completed = true;
      }
      if (this.finish) {
        this.finish.completed = false;
      }
      this.setClassToStepHeaders();
    }
  }

  setClassToStepHeaders(): void {
    const stepHeaders = this.el.nativeElement.querySelectorAll('.pta-stepper .mat-step-header');
    stepHeaders.forEach((header, count) => {
      if (count === 0 && this.machineStep?.completed || this.maskStep?.completed) {
        this.renderer.addClass(header, 'mat-step-header-completed');
      } else if (count === 0) {
        this.renderer.removeClass(header, 'mat-step-header-completed');
      }

      if (count === 1 && this.maskStep?.completed || this.finish.completed) {
        this.renderer.addClass(header, 'mat-step-header-completed');
      } else if (count === 1) {
        this.renderer.removeClass(header, 'mat-step-header-completed');
      }

      if (count === 2 && this.finish.completed) {
        this.renderer.addClass(header, 'mat-step-header-completed');
      } else if (count === 2) {
        this.renderer.removeClass(header, 'mat-step-header-completed');
      }
    });
  }
}
