export const maskTypeIds = ['Full face', 'Nasal', 'Nasal pillows'] as const;
export const maskTypesTranslations = [
  'MY-ACCOUNT/MY-EQUIPMENT/ADD-MASK.FullFace',
  'MY-ACCOUNT/MY-EQUIPMENT/ADD-MASK.Nasal',
  'MY-ACCOUNT/MY-EQUIPMENT/ADD-MASK.NasalPillows',
] as const;
export const maskTypeImgSources = [
  '/assets/images/scaled/masks-web/fullface@1x.jpg',
  '/assets/images/scaled/masks-web/nasal@1x.jpg',
  '/assets/images/scaled/masks-web/pillows@1x.jpg',
] as const;
export const maskTypeSelectedImgSources = [
  '/assets/images/scaled/masks-web/fullface_selected@1x.jpg',
  '/assets/images/scaled/masks-web/nasal_selected@1x.jpg',
  '/assets/images/scaled/masks-web/pillows_selected@1x.jpg',
];
export type MaskTypeId = typeof maskTypeIds[number];
export type MaskTypeTranslation = typeof maskTypesTranslations[number];
export type MaskTypeImgSource = typeof maskTypeImgSources[number];
export type MaskTypeSelectedImgSource = typeof maskTypeSelectedImgSources[number];

export const maskTypes: Record<MaskTypeId, MaskTypeImgSource> = {
  'Full face': '/assets/images/scaled/masks-web/fullface@1x.jpg',
  Nasal: '/assets/images/scaled/masks-web/nasal@1x.jpg',
  'Nasal pillows': '/assets/images/scaled/masks-web/pillows@1x.jpg',
} as const;

export const maskTypesSelected: Record<MaskTypeId, MaskTypeSelectedImgSource> = {
  'Full face': '/assets/images/scaled/masks-web/fullface_selected@1x.jpg',
  Nasal: '/assets/images/scaled/masks-web/nasal_selected@1x.jpg',
  'Nasal pillows': '/assets/images/scaled/masks-web/pillows_selected@1x.jpg',
} as const;

export const maskTypesTranslated: Record<MaskTypeId, MaskTypeTranslation> = {
  'Full face': 'MY-ACCOUNT/MY-EQUIPMENT/ADD-MASK.FullFace',
  Nasal: 'MY-ACCOUNT/MY-EQUIPMENT/ADD-MASK.Nasal',
  'Nasal pillows': 'MY-ACCOUNT/MY-EQUIPMENT/ADD-MASK.NasalPillows',
};
