import { Component, Output, EventEmitter } from '@angular/core';
import { BaseFormComponent } from 'src/app/components/base/component/base-form-component';
import { ComponentState } from '../component-state.enum';

@Component({
  selector: 'mpp-base-edit',
  template: '',
})
export abstract class BaseEditComponent extends BaseFormComponent {
  @Output() stateChanged = new EventEmitter<ComponentState>();

  cancel(): void {
    this.stateChanged.next(ComponentState.View);
  }

  abstract save(): Promise<void> | void;
}
