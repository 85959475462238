import { Injectable } from '@angular/core';
import { UrlTree, Router, ActivatedRouteSnapshot } from '@angular/router';
import { CountryService } from 'src/app/services/country/country.service';
import { CountryId, CountryData } from 'src/app/types/country.type';
import { MetadataService } from '../metadata/metadata.service';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentGuard  {
   public isEU: boolean = environment.isEU;
   private readonly CountryIdKey = 'CurrentMyAirCountryId';

  constructor(
    private countryService: CountryService,
    private router: Router,
    private metadataService: MetadataService,
    private localStorageService: LocalStorageService
  ) {}

  canActivateChild(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    return this.canActivate(route);
  }

  canLoad(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    return this.canActivate(route);
  }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    // Wait for API Metadata to load
    await this.metadataService.waitForMetadataToLoad();

    const countryId = this.localStorageService.getItem(this.CountryIdKey) as CountryId;
    let countries = this.countryService.countries$.getValue();

    // if countries don't exist yet, load them
    if (Object.keys(countries).length === 0) {
      await this.countryService.loadCountries().toPromise();
      countries = this.countryService.countries$.getValue(); // Re-fetch after loading
    }
    // Convert the countries object into an array of its values
    const countriesArray: CountryData[] = Object.values(countries);
    const rowCountries = countriesArray.filter(country => country.region === 'ROW');
    const emeaCountries = countriesArray.filter(country => country.region === 'EU');

    // Ensures cross-regional data integrity is maintained in secondary scenarios
    if (rowCountries && emeaCountries && countryId) {
      if(rowCountries.some(country => country.id === countryId) && this.isEU) {
        // ROW country selected but user is in EU - redirect to country selection page
        return this.router.createUrlTree(['country-selection']);
      } else if (emeaCountries.some(country => country.id === countryId) && !this.isEU) {
        // EU country selected but user is in ROW - redirect to country selection page
        return this.router.createUrlTree(['country-selection']);
      }
    }

    // environment matches the country selected, or no country is selected
    return true;
  }
}