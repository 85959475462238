<section id="myAccountMask" class="o-section">
  <div class="o-wrapper">

    <div class="o-layout">
      <div class="o-layout__item  [ u-10/12@desktop u-push-1/12@desktop ]">

        <h1 class="c-heading-page"><span>{{'MY-ACCOUNT/MY-EQUIPMENT/ADD-MASK.YourMask' | translate }}</span></h1>

        @if (currentMask) {
          <div>
            <mpp-add-mask [maskFormGroup]="maskFormGroup"></mpp-add-mask>
          </div>
        }

        <div class="c-form-buttons  [ o-layout o-layout--auto ]">
          <div class="o-layout__item">
            <button class="c-btn c-btn--primary"
            data-title="Save Mask" validationgroup="MaskValidation" [disabled]="maskFormGroup.invalid" (click)="updatePatientMask()">{{'MY-ACCOUNT/MY-EQUIPMENT.Save' | translate }}</button>
          </div>
          <div class="o-layout__item">
            <a class="c-btn c-btn--tertiary" (click)="cancelMaskChanges()">{{'MY-ACCOUNT/MY-EQUIPMENT.Cancel' | translate }}</a>
          </div>
        </div>

      </div>

    </div>
  </div>
</section>
