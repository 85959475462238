import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { RegistrationValidators } from 'src/app/modules/registration/validators/registration.validators';
import { CredentialsService } from 'src/app/services/credentials/credentials.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { AlertService } from 'src/app/modules/alert/components/services/alert.service';
import { ErrorTypes } from 'src/app/services/showtime-api/response.structure';
import { LokalizeOutageService } from 'src/app/services/lokalize-outage/lokalize-outage.service';

@Component({
  selector: 'mpp-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  form = this.fb.group({
    email: ['', [Validators.required, RegistrationValidators.isEmailCorrect]],
  });
  constructor(
    private fb: UntypedFormBuilder,
    private credentialsService: CredentialsService,
    private spinnerService: SpinnerService,
    private alertService: AlertService,
    private lokalizeOutageService: LokalizeOutageService,
  ) {}
  requestSendSuccessfully = false;

  forgotPassword(): void {
    const spinner = this.spinnerService.show();
    this.credentialsService.forgotPassword(this.form.get('email').value).subscribe((response) => {
      spinner.hide();

      if (!response.result.success) {
        if (response.result.errorType === ErrorTypes.serviceUnavailable) {
          this.alertService.info(
            this.lokalizeOutageService.errorMessage(response.result.additionalInfo.serverOutage),
          );
        } else {
          this.form.get('email').setErrors({ badRequest: true });
        }
        return;
      }
      this.requestSendSuccessfully = true;
    });
  }
}
