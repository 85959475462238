import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';

@Component({
  selector: 'mpp-data-email-sent',
  templateUrl: './data-email-sent.component.html',
  styleUrls: ['./data-email-sent.component.scss']
})
export class DataEmailSentComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DataEmailSentComponent>,
    public analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
  }

  close(): void {
    this.analyticsService.logDownloadDataSuccessScreenOkEvent();
    this.dialogRef.close();
  }

}
