import { Component, Input } from '@angular/core';
import { BaseViewComponent } from 'src/app/modules/my-account/components/base-view-edit-components/base-view/base-view.component';
import { MyPrivacySettings } from '../my-privacy.component';

@Component({
  selector: 'mpp-allow-analytics-view',
  templateUrl: './allow-analytics-view.component.html',
  styleUrls: ['./allow-analytics-view.component.scss'],
})
export class AllowAnalyticsViewComponent extends BaseViewComponent {
  @Input() privacySettings: MyPrivacySettings;
}
