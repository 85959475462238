import { Component, OnInit, Input } from '@angular/core';
import { CountryService } from 'src/app/services/country/country.service';
import { environment } from 'src/environments/environment';

export enum MyPrivacyState {
  View,
  Edit,
}

export interface MyPrivacySettings {
  allowIdentifiedAnalytics: boolean;
  shareDetailsWithProviderOptIn: boolean;
  marketingOptIn: boolean;
  allowPersonalizedCoaching: boolean;
}

@Component({
  selector: 'mpp-my-privacy',
  templateUrl: './my-privacy.component.html',
  styleUrls: ['./my-privacy.component.scss'],
})
export class MyPrivacyComponent implements OnInit {
  downloadMyDataStateValue = MyPrivacyState.View;
  allowPersonalizedStateValue = MyPrivacyState.View;
  myPrivacyStateValue = MyPrivacyState.View;
  shareInformationStateValue = MyPrivacyState.View;
  myPrivacyState = MyPrivacyState;
  receiveAdditionalInfoState = MyPrivacyState.View;

  @Input() privacySettings: MyPrivacySettings = {
    allowIdentifiedAnalytics: false,
    shareDetailsWithProviderOptIn: false,
    marketingOptIn: false,
    allowPersonalizedCoaching: false
  };
  @Input() showSmartCoachingOptOut: boolean = false;
  @Input() email: string;
  // boolean to determine whether or not the My Account page should have a 'My Privacy' section
  showMyPrivacy: boolean = false;
  // boolean to determine whether or not the My Account page's 'My Privacy' section should include an 'Analytics' section
  allowAnalytics: boolean = false;
  // boolean to determine whether or not the My Account page's 'My Privacy' section should include an 'Additional Information' section
  allowAdditionalInformation: boolean = false;
  constructor(private countryService: CountryService) {}

  async ngOnInit(): Promise<void> {
    // USA, Canada or EU
    this.showMyPrivacy =
      ['US', 'CA'].includes(this.countryService.getSelectedCountryId()) || environment.isEU;

    // USA or EU
    if(this.showMyPrivacy && this.countryService.getSelectedCountryId() !== 'CA') {
      this.allowAnalytics = true;
    }

    // EU only
    if (this.allowAnalytics && this.countryService.getSelectedCountryId() !== 'US') {
      this.allowAdditionalInformation = true;
    }
  }

  onAnalyticsChange(state: boolean): void {
    this.privacySettings.allowIdentifiedAnalytics = state;
  }

  onShareInformationChange(state: boolean): void {
    this.privacySettings.shareDetailsWithProviderOptIn = state;
  }

  onAdditionalInformationChange(state: boolean): void {
    this.privacySettings.marketingOptIn = state;
  }

  onPersonalizedCoachingChange(state: boolean): void {
    this.privacySettings.allowPersonalizedCoaching = state;
  }
}
