import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PatientService } from 'src/app/services/patient/patient.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { BaseEditComponent } from 'src/app/modules/my-account/components/base-view-edit-components/base-edit/base-edit.component';
import { ComponentState } from 'src/app/modules/my-account/components/base-view-edit-components/component-state.enum';
import { NotificationPreferences } from 'src/app/modules/my-account/components/my-account/my-notifications/types/notification-preferences.type';

@Component({
  selector: 'mpp-notifications-edit',
  templateUrl: './notifications-edit.component.html',
  styleUrls: ['./notifications-edit.component.scss'],
})
export class NotificationsEditComponent extends BaseEditComponent implements OnInit {
  @Input() notificationPreferences: NotificationPreferences;
  @Output() notificationsChanged = new EventEmitter<NotificationPreferences>();
  constructor(
    private patientService: PatientService,
    private fb: UntypedFormBuilder,
    private spinner: SpinnerService,
  ) {
    super();
  }
  form: UntypedFormGroup;
  ngOnInit(): void {
    this.form = this.fb.group({
      coachingEmailEnabled: [this.notificationPreferences.coachingEmailEnabled],
      cleaningEmailEnabled: [this.notificationPreferences.cleaningEmailEnabled],
    });
  }

  async save(): Promise<void> {
    const spinner = this.spinner.show();
    const preferences = {
      coachingEmailEnabled: this.form.get('coachingEmailEnabled').value,
      cleaningEmailEnabled: this.form.get('cleaningEmailEnabled').value,
    };
    const [error] = await this.patientService.setNotificationPreferences(preferences).toPromise();
    spinner.hide();
    if (error) {
      this.stateChanged.next(ComponentState.Error);
      return;
    }
    this.notificationsChanged.next(preferences);
    this.stateChanged.next(ComponentState.View);
  }
}
