<div class="js-acc-panel is-active">
  <div class="c-acc-panel  c-acc-panel--read">
    <div class="c-acc-panel__item">
      <div class="c-acc-panel__label">
        <span>{{ 'MY-ACCOUNT/ACCOUNT-DETAILS.Password' | translate}}</span>
      </div>
      <div class="c-acc-panel__value">
        <span class="is-email">********</span>
      </div>
      <div class="c-acc-panel__btn">
        <a class="c-link-ui" (click)="edit()">{{ 'MY-ACCOUNT.Change' | translate}}</a>
      </div>
    </div>
  </div>
</div>
