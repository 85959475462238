<div class="o-content">
  <mpp-video-carousel></mpp-video-carousel>

  <!-- Login and Get Started section -->
  <section class="o-section o-section--column-divider u-fill--neutral-ulight">
    <div class="o-wrapper">
      <div class="o-layout o-layout--login">
        <div class="o-layout__item [ u-1/2@tablet u-hide-below-tablet ]">
          <mpp-get-started></mpp-get-started>
        </div>
        <div class="o-layout__item [ u-1/2@tablet ]">
          <mpp-sign-in></mpp-sign-in>
        </div>
      </div>
    </div>
  </section>

  <section class="o-section o-section@mobile  u-show-below-tablet">
    <div class="o-wrapper">
      <mpp-get-started></mpp-get-started>
    </div>
  </section>

  <mpp-feature-description></mpp-feature-description>
</div>