export class Alert {
  type: AlertType;
  message: string;
  links: Link[];
  isDismissable: boolean = true;
  isPatientMessaging: boolean = false;
  iconUrl: string = null;
  isDismissed?: boolean;
  onClose: (alert: Alert) => void;
  constructor(init?: Partial<Alert>) {
    Object.assign(this, init);
  }
}

export enum AlertType {
  Success,
  Error,
  Info,
  Warning,
}

export interface Link {
  title: string;
  callback: (alert?: Alert) => void;
  textAfterLink?: string;
}
