<div class="u-margin-bottom-large">

  <div class="c-acc-panel c-acc-panel--header">
    <div class="c-acc-panel__item">
      <div class="c-acc-panel__heading">
        <h2 class="c-heading-acc">{{ 'MY-ACCOUNT/MY-PRIVACY.MyPrivacy' | translate}}</h2>
      </div>
    </div>
  </div>

  <p [innerHTML]="'MY-ACCOUNT/MY-PRIVACY.PrivacyNoticeText' | translate"></p>

  @if (showMyPrivacy) {
    <div class="c-acc-panel--group">
      <div class="c-acc-panel--group__item has-all-attr">
        @if (shareInformationStateValue === myPrivacyState.View) {
          <mpp-share-information-view [privacySettings]="privacySettings" (stateChanged)="shareInformationStateValue = $event"></mpp-share-information-view>
        }
        @if (shareInformationStateValue === myPrivacyState.Edit) {
          <mpp-share-information-edit [privacySettings]="privacySettings" (shareInformationChanged)="onShareInformationChange($event)" (stateChanged)="shareInformationStateValue = $event"></mpp-share-information-edit>
        }
      </div>
      @if (allowAnalytics) {
        <div class="c-acc-panel--group__item has-all-attr item-has-top-border">
          @if (myPrivacyStateValue === myPrivacyState.View) {
            <mpp-allow-analytics-view [privacySettings]="privacySettings" (stateChanged)="myPrivacyStateValue = $event"></mpp-allow-analytics-view>
          }
          @if (myPrivacyStateValue === myPrivacyState.Edit) {
            <mpp-allow-analytics-edit [privacySettings]="privacySettings" (analyticsChanged)="onAnalyticsChange($event)"
              (stateChanged)="myPrivacyStateValue = $event">
            </mpp-allow-analytics-edit>
          }
        </div>
        @if (allowAdditionalInformation) {
          <div class="c-acc-panel--group__item has-all-attr item-has-top-border">
            @if (receiveAdditionalInfoState === myPrivacyState.View) {
              <mpp-receive-additional-information-view [privacySettings]="privacySettings" (stateChanged)="receiveAdditionalInfoState = $event"></mpp-receive-additional-information-view>
            }
            @if (receiveAdditionalInfoState === myPrivacyState.Edit) {
              <mpp-receive-additional-information-edit [privacySettings]="privacySettings" (stateChanged)="receiveAdditionalInfoState = $event" (additionalInfoChanged)="onAdditionalInformationChange($event)"></mpp-receive-additional-information-edit>
            }
          </div>
        }
      }
      @if (showSmartCoachingOptOut) {
        <div class="c-acc-panel--group__item has-all-attr item-has-top-border">
          @if (allowPersonalizedStateValue === myPrivacyState.View) {
            <mpp-allow-personalized-coaching-view [privacySettings]="privacySettings" (stateChanged)="allowPersonalizedStateValue = $event"></mpp-allow-personalized-coaching-view>
          }
          @if (allowPersonalizedStateValue === myPrivacyState.Edit) {
            <mpp-allow-personalized-coaching-edit (smartCoachingChanged)="onPersonalizedCoachingChange($event)" [privacySettings]="privacySettings" (stateChanged)="allowPersonalizedStateValue = $event"></mpp-allow-personalized-coaching-edit>
          }
        </div>
      }
    </div>
  }
  @if (downloadMyDataStateValue === myPrivacyState.View) {
    <mpp-download-my-data-view [email]="email" (stateChanged)="downloadMyDataStateValue = $event"></mpp-download-my-data-view>
  }
  @if (downloadMyDataStateValue === myPrivacyState.Edit) {
    <mpp-download-my-data-edit [email]="email" (stateChanged)="downloadMyDataStateValue = $event"></mpp-download-my-data-edit>
  }

</div>
