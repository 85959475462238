import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../services/auth-guard/auth.guard';
import { MyAccountComponent } from './components/my-account/my-account.component';
import { MyAccountDeviceComponent } from './components/my-account/my-equipment/my-account-device/my-account-device.component';
import { MyAccountMaskComponent } from './components/my-account/my-equipment/my-account-mask/my-account-mask.component';
import { CountrySelectedGuard } from '../../services/country-selected-guard/country-selected.guard';
import { DeviceRegisteredGuard } from '../../services/device-registered-guard/device-registered.guard';
import { HealthProfileEditComponent } from './components/my-account/my-health-profile/health-profile-edit/health-profile-edit.component';

const routes: Routes = [
  { path: '', component: MyAccountComponent },
  {
    path: 'my-account-device',
    component: MyAccountDeviceComponent,
    canActivate: [CountrySelectedGuard, AuthGuard, DeviceRegisteredGuard],
  },
  {
    path: 'my-account-mask',
    component: MyAccountMaskComponent,
    canActivate: [CountrySelectedGuard, AuthGuard, DeviceRegisteredGuard],
  },
  {
    path: 'my-health-profile',
    component: HealthProfileEditComponent,
    canActivate: [CountrySelectedGuard, AuthGuard, DeviceRegisteredGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MyAccountRoutingModule {}
