import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HealthProfile } from '../components/my-account/my-health-profile/types/health-profile.type';

@Injectable({
  providedIn: 'root',
})
export class MyAccountDataService {
  public dataIsDownloaded = new BehaviorSubject(false);
  public healthProfileData = new BehaviorSubject<HealthProfile>(null);
}
