<section class="o-section">
  <div class="o-wrapper">
    <div class="o-layout">
      <div class="o-layout__item [ u-3/4@desktop u-push-1/8@desktop ] [ u-8/10@wide u-push-1/10@wide ] [ u-10/12@wide u-push-1/12@wide ]">
        <div [innerHTML]="data"></div>
      </div>
    </div>

    <a class="c-icon-btn-top-link" [ngClass]="{'show': windowScrolled, 'hide': !windowScrolled}"
    id="js-top" (click)="scrollToTop()">
      <svg class="c-svg c-svg--top-circle-outline-24"><use xlink:href="#c-svg--top-circle-outline-24"></use></svg>
      <span class="u-screen-reader-text">Back to top</span>
    </a>

  </div>
</section>
