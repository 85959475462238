<div class="modal-wrapper">
    <div class="title">{{'PTA.PtaSetupHelp' | translate}}</div>
    <div class="text">{{'PTA.PtaSetupHelpDescription' | translate}}</div>
    <div class="button-wrapper">
        <button class="c-btn c-btn--primary" (click)="openPTA()">{{'PTA.PtaSetupHelpBeginSetup' | translate}}</button>
        <button class="c-btn c-btn--tertiary" (click)="closeModal()">{{'PTA.PtaSetupHelpNoThanks' | translate}}</button>
    </div>
    <svg class="c-svg c-svg--close-24" (click)="closeModal()">
        <use xlink:href="#c-svg--close-24"></use>
    </svg>
</div>