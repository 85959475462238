import { Component, OnInit, Renderer2, OnDestroy, Inject } from '@angular/core';
import { CountryService } from 'src/app/services/country/country.service';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/services/language/language.service';
import { WINDOW_OBJECT } from 'src/app/services/window/window.service';
import { ExpanderBase, States } from '../base/expander-base/expander-base';
import { AlertService } from 'src/app/modules/alert/components/services/alert.service';
import { MetadataService } from 'src/app/services/metadata/metadata.service';
import { takeUntil } from 'rxjs/operators';
import { CountryId } from 'src/app/types/country.type';
@Component({
  selector: 'mpp-country-selection',
  templateUrl: './country-selection.component.html',
  styleUrls: ['./country-selection.component.scss'],
})
export class CountrySelectionComponent extends ExpanderBase implements OnInit, OnDestroy {
  public countries$: any;
  redirectCountryId: string;

  constructor(
    private countryService: CountryService,
    private router: Router,
    private renderer: Renderer2,
    private languageService: LanguageService,
    private alertService: AlertService,
    private metadataService: MetadataService,
    @Inject(WINDOW_OBJECT) public window: Window
  ) {
    super([States.IsHidden, States.IsHidden, States.IsHidden], [States.AriaNotExpanded]);
  }

  ngOnInit(): void {
    // Consider a loading spinner once we move to serverside metadata fetching
    this.metadataService.getMetadataFromLocal('countries').pipe(takeUntil(this.unsubscribe$)).subscribe(
      data => this.countries$ = data
    );

    this.renderer.addClass(document.body, 'o-page--countrySelection');
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'o-page--countrySelection');
  }

  async selectCountry(countryId: CountryId): Promise<void> {
      const from = 'countrySelectionComponent';
      const data = await this.countryService.loadAppOnlyCountries().pipe(takeUntil(this.unsubscribe$)).toPromise();
      const appOnlyCountries = data;
  
      if (appOnlyCountries.includes(countryId)) {
        this.countryService.changeCountry(countryId, '/');
        // add EMEA countries as needed for more app-only countries
        if (countryId === 'LU') {
          this.redirectToAppOnlyCountry('EU');
        } else {
          this.redirectToAppOnlyCountry('APAC');
        }
      } else {
        this.metadataService.attemptedToLoadMaskMetadata = false;
        this.alertService.removePatientMessagingAlert();
        this.languageService.setLanguageInPreferredOrder(countryId, from);
        this.countryService.changeCountry(countryId, '/');
        const metadataQuerySwapRequired = await this.metadataService.checkIfMetadataQuerySwapRequired();
        if (metadataQuerySwapRequired) {
          this.metadataService.attemptedToLoadApiMetadata = false;
        }
      }
    }

  redirectToAppOnlyCountry(region?): void {
    if (region === 'APAC') {
      this.router.navigate(['/myair-asia']);
    } else if (region === 'EU') {
      this.router.navigate(['/myair-emea']);
    }
  }

}