import { Pipe, PipeTransform } from '@angular/core';
import { DayJSService } from 'src/app/services/dayJS/dayJS.service';
import { LanguageService } from 'src/app/services/language/language.service';

@Pipe({
  name: 'fullDate',
})
export class FullDatePipe implements PipeTransform {
  constructor(private languageService: LanguageService, private dayJsService: DayJSService) {}
  transform(value: string): string {
    if (value) {
      const dateJs = this.dayJsService.dayJS(value);
      const date = new Date(dateJs.year(), dateJs.month(), dateJs.date());
      if (this.languageService.isJapaneseLanguage()) {
        return this.getJapaneseFormattedDate(date);
      }
      return this.getFormattedDate(date);
    }
    return '';
  }

  getJapaneseFormattedDate(date: Date): string {
    return date.toLocaleString(this.languageService.getCurrent2LettersLanguage(), {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  }

  getFormattedDate(date: Date): string {
    const formattedDate = date.toLocaleString(this.languageService.getCurrent2LettersLanguage(), {
      month: 'long',
      day: 'numeric',
    });
    const year = date.getFullYear();
    return `${formattedDate} ${year}`;
  }
}
