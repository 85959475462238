<mpp-welcome-video ID="WelcomeVideo"></mpp-welcome-video>
<section id="hero" class="o-section o-section--flush">
  <ngx-slick-carousel class="o-carousel o-carousel--hero" [config]="slickOptions">
    @if (showVideoBanner) {
      <div ngxSlickItem class="o-carousel__item c-hero c-hero--carousel slide-1">

        <div class="o-wrapper">

          <div class="c-hero__wrapper">
            <div class="">
              <div class="o-box u-2/5@tablet">
                <h2 class="c-hero__heading">{{'HOME/VIDEO-CAROUSEL.Welcome' | translate}}</h2>
                <p innerHTML="{{'HOME/VIDEO-CAROUSEL.KnowMore' | translate}}"></p>
                <p><a (click)="playVideo()" class="c-hero__text-btn" data-toggle="videoOverlay"
                    data-playback="playVideo"><strong>{{'HOME/VIDEO-CAROUSEL.WatchVideo' | translate}}</strong></a>
                </p>
              </div>
              <div (click)="playVideo()" class="c-icon-btn c-icon-btn--play" data-toggle="videoOverlay" data-playback="playVideo">
                <svg class="c-svg c-svg--play-circle-outline-24">
                  <use xlink:href="#c-svg--play-circle-outline-24"></use>
                </svg>
              </div>
            </div>
          </div>

        </div>

      </div>
    }
    @if (showMobileAppsBanner) {
      <div ngxSlickItem class="o-carousel__item c-hero c-hero--carousel slide-2">

        <div class="o-wrapper">

          <div class="c-hero__wrapper">
            <div class="o-box u-2/3@desktop">
              <h2 class="c-hero__heading" innerHTML="{{'HOME/VIDEO-CAROUSEL.TrackSleepProgress' | translate}}"><sup>&#8224;</sup></h2>

              <div class="c-app-badges [ o-layout o-layout--auto o-layout--small ]">
                <div class="o-layout__item">
                  <a (click)="appStoreLinkClicked()" 
                    id="lnkAppStoreLinkApple" class="c-apple-badge"
                    [style.background-image]="languageService.selectedAppStoreImage"
                    [href]="countryService.appStoreLink$ | async"
                    title="{{'HOME/VIDEO-CAROUSEL.DownloadAppStore' | translate}}"
                    target="_blank">{{'HOME/VIDEO-CAROUSEL.DownloadAppStore' | translate}}</a>
                </div>
                <div class="o-layout__item">
                  <a (click)="playStoreLinkClicked()" 
                    id="lnkAppStoreLinkAndroid" class="c-android-badge"
                    [style.background-image]="languageService.selectedPlayStoreImage"
                    [href]="countryService.playStoreLink$ | async"
                    title="{{'HOME/VIDEO-CAROUSEL.GetGooglePlay' | translate}}"
                    target="_blank">{{'HOME/VIDEO-CAROUSEL.GetGooglePlay' | translate}}</a>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    }
    @if (showRegistrationBanner) {
      <div ngxSlickItem class="o-carousel__item c-hero c-hero--carousel slide-2">

        <div class="o-wrapper">

          <div class="c-hero__wrapper">
            <div class="o-box u-2/3@desktop">
              <h2 class="c-hero__heading" innerHTML="{{'HOME/VIDEO-CAROUSEL.TrackSleepProgressEurope' | translate}}"><sup>&#8224;</sup></h2>

              <div class="c-hero__cta">
                <button class="c-btn c-btn--primary c-btn--inverse" (click)="goToAccountRegistration()">
                  {{ 'HOME.CreateAccount' | translate }}
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    }
  </ngx-slick-carousel>

</section>