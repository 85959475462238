/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPatientWrapper = /* GraphQL */ `
  query GetPatientWrapper {
    getPatientWrapper {
      patient {
        id
        email
        countryId
        firstName
        lastName
        dateOfBirth
        gender
        sleepTestType
        startTherapyGroup
        userEnteredAhi
        phoneNumber
        aboutMeProgress
        myEquipmentProgress
        myHealthProgress
        overallProgress
        achievementsPushEnabled
        coachingPushEnabled
        coachingSmsEnabled
        coachingEmailEnabled
        cleaningPushEnabled
        cleaningSmsEnabled
        cleaningEmailEnabled
        smartCoachingEnabled
        stepCountSharingEnabled
        healthSharingEnabled
        healthSharingLastPromptedVersion
        shareDetailsWithProviderOptIn
        analyticsMode
        analyticsId
        pushToken
        timezoneId
        furiganaFamilyName
        furiganaGivenName
        marketingOptIn
        achievements {
          id
          name
          description
          imageUrl
          earnedDate
        }
        journeys
        firstTherapySessionDate
        lifetimeUsageHours
        processHealthDataOptIn
      }
      fgDevices {
        serialNumber
        deviceType
        deviceSeries
        deviceFamily
        lastSleepDataReportTime
        localizedName
        imagePath
        imageUrl
        fgDeviceManufacturerName
        fgDevicePatientId
        deviceSubtype
      }
      masks {
        maskManufacturerName
        maskCode
        maskType
        localizedName
        imagePath
        imageUrl
        maskPatientId
      }
      coachingHistory {
        items {
          date
          sleepLibraryId
        }
        lastEvaluatedKey
      }
      sleepLibrary {
        title
        thumbnail
        description
        videoOrHtmlUrl
        contentId
        language
        sleepLibraryId
        section
        type
        sleepLibraryPatientId
        analyticsScreenName
        videoId
      }
      sleepRecords {
        items {
          startDate
          totalUsage
          sleepScore
          usageScore
          ahiScore
          maskScore
          leakScore
          ahi
          maskPairCount
          leakPercentile
          sleepRecordPatientId
        }
        lastEvaluatedKey
      }
      notifications {
        id
        action
        title
        description
        positiveButtonTitle
        negativeButtonTitle
        url
        negativeActionId
        negativeActionContent {
          title
          description
          positiveButtonTitle
          negativeButtonTitle
        }
        achievement {
          id
          name
          description
          imageUrl
          earnedDate
        }
      }
      dashboard {
        sectionsV1 {
          sectionType
        }
        sectionsV2 {
          sectionType
        }
        sectionsV3 {
          sectionType
        }
        sectionsV4 {
          sectionType
        }
      }
      healthTrends {
        usage {
          previousAverage
          currentAverage
          trend
        }
        stepCount {
          previousAverage
          currentAverage
          trend
        }
        weightKilograms {
          previousAverage
          currentAverage
          trend
        }
        activeCaloriesBurned {
          previousAverage
          currentAverage
          trend
        }
        bloodOxygenPercent {
          previousAverage
          currentAverage
          trend
        }
        bodyMassIndex {
          previousAverage
          currentAverage
          trend
        }
        heartRateVariabilityMilliseconds {
          previousAverage
          currentAverage
          trend
        }
        restingHeartRate {
          previousAverage
          currentAverage
          trend
        }
        timeInBedMinutes {
          previousAverage
          currentAverage
          trend
        }
        totalTimeAsleepMinutes {
          previousAverage
          currentAverage
          trend
        }
        exerciseMinutes {
          previousAverage
          currentAverage
          trend
        }
        maskSeal {
          previousAverage
          currentAverage
          trend
        }
        ahi {
          previousAverage
          currentAverage
          trend
        }
        maskOnOffCount {
          previousAverage
          currentAverage
          trend
        }
        sleepScore {
          previousAverage
          currentAverage
          trend
        }
      }
      achievements {
        id
        name
        description
        imageUrl
        earnedDate
      }
      goalTrackingDates {
        goalPeriodStartDate
        goalPeriodEndDate
      }
      sleepTests {
        id
        isActive
      }
      cciTherapyResponse {
        cciSurveys {
          dateTime
        }
      }
      sleepSummary {
        date
        totalTimeAsleepMinutes
        totalTimeInBedMinutes
        totalUsageHours
        sleepSessions {
          startTime
          endTime
          timeAsleepSeconds
          durationSeconds
        }
      }
      consents {
        consentType
        consentStatus
        consentCreationTime
        consentExpirationTime
        consentUrl
      }
    }
  }
`;
export const getMaskQuestions = /* GraphQL */ `
  query GetMaskQuestions($maskCode: String!, $locale: String!) {
    getMaskQuestions(maskCode: $maskCode, locale: $locale) {
      questionId
      localizedQuestion
      answers {
        answerId
        localizedAnswer
      }
    }
  }
`;
export const getCountryMetadata = /* GraphQL */ `
  query GetCountryMetadata($countryId: String!) {
    getCountryMetadata(countryId: $countryId) {
      countryFeatures {
        allowedAnalyticsModes
        isSmartCoachingSupported
        marketingConsentDefault
        minimumAgeInYears
        optInAnalyticsMode
        optOutAnalyticsMode
        requireAnalyticsMode
        requireProcessHealthDataOptIn
        showAdaLabelInSupport
        supportedPatientJourneys
        supportsAchievements
      }
      supportedFgDevices {
        air10 {
          imagePath
        }
        air11 {
          imagePath
        }
      }
    }
  }
`;
