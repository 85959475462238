import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertService } from 'src/app/modules/alert/components/services/alert.service';
import { ResponseResult } from '../showtime-api/response.structure';
import { ShowTimeApiService } from '../showtime-api/showtime-api.service';
import { MetadataService } from '../metadata/metadata.service';

@Injectable({
  providedIn: 'root',
})
export class SleepReportService extends ShowTimeApiService {
  constructor(protected http: HttpClient, protected messageService: AlertService, private metadata: MetadataService) {
    super(messageService);
  }

  getTherapyReport(): Observable<ResponseResult> {
    return this.http
      .get(`${this.metadata.instanceInfo.restUrl}/V1/Patient/SummaryReport`)
      .pipe(this.showError({ disableAlert: true }));
  }
}
