import { GenderId } from '../modules/registration/data/gender.type';
import { CountryId } from '../types/country.type';
import { Device } from './device.model';
import { MyAirUserClaims } from './myair-user-claims';

/**
 * Class that describes user instance
 */
export class User {
  public Email: string;
  public FirstName: string;
  public LastName: string;
  public FullName: string;
  public BirthDay: Date;
  public Gender: GenderId;
  public Device: Device;
  public RememberMeOptIn?: boolean;
  public CountryId?: CountryId;

  public static fromUserClaims(userClaims: MyAirUserClaims): User {
    return {
      Email: userClaims.email,
      FirstName: userClaims.given_name,
      LastName: userClaims.family_name,
      FullName: userClaims.name,
      CountryId: userClaims.myAirCountryId,
    } as User;
  }
}
