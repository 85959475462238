import { Component, Input } from '@angular/core';
import { ComponentState } from '../../base-view-edit-components/component-state.enum';

@Component({
  selector: 'mpp-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss'],
})
export class AccountDetailsComponent {
  emailStateValue = ComponentState.View;
  componentState = ComponentState;
  passwordStateValue = ComponentState.View;
  @Input() email: string;

  onEmailChange(email: string): void {
    this.email = email;
  }

  onPasswordChange(): void {}
}
