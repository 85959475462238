import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'mpp-mask-magnets',
  templateUrl: './mask-magnets.component.html',
  styleUrls: ['./mask-magnets.component.scss']
})
export class MaskMagnetsComponent {
  public isEU = environment.isEU;
  
  constructor(private dialogRef: MatDialogRef<MaskMagnetsComponent>) {}

  close(): void {
    this.dialogRef.close();
  }
}
